import { Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";

import AppLogo from "@/assets/Pull_Icon_2.png";
import { CustomerContext } from "@/contexts/CustomerProvider";

import { SourceContainer, SourceImage } from "./CustomerSource.styled";

type CustomerSourceProps = {
  customLogoUri?: string;
  customName?: string;
};

const CustomerSource = ({ customLogoUri, customName }: CustomerSourceProps) => {
  const labelRef = useRef<HTMLSpanElement | null>(null);
  const [labelOverflowing, setLabelOverflowing] = useState(false);

  const customer = useContext(CustomerContext);
  const logoSrc = customLogoUri ?? customer.currentCustomer?.logoUri ?? AppLogo;
  const altValue = customName ?? customer.currentCustomer?.name ?? "Pull Systems";

  const sourceForTooltip = <CustomerSource customLogoUri={logoSrc} customName={altValue} />;

  const checkOverflow = () => {
    const isLabelOverflowing = (labelRef.current?.scrollWidth ?? 0) > (labelRef.current?.clientWidth ?? 0);
    if (isLabelOverflowing !== labelOverflowing) setLabelOverflowing(isLabelOverflowing);
  };

  useEffect(() => {
    checkOverflow();

    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const labelElement = labelOverflowing ? (
    <Tooltip title={sourceForTooltip}>
      <span className="text-overflowing" ref={labelRef}>
        {altValue}
      </span>
    </Tooltip>
  ) : (
    <span ref={labelRef} className="text-overflowing">
      {altValue}
    </span>
  );

  return (
    <SourceContainer>
      <SourceImage src={logoSrc} height={16} alt={altValue} />
      {labelElement}
    </SourceContainer>
  );
};

export default CustomerSource;
