import { withAuthenticationRequired } from "@auth0/auth0-react";
import isExternalAuthProvider from "@lib/src/auth/isExternalAuthProvider";
import { useLocation, useSearch } from "@tanstack/react-router";
import { Result } from "antd";
import React, { ComponentType, useMemo } from "react";

import Loading from "@/components/loading";
import { usePostHogIdentify } from "@/hooks/usePostHogIdentify";
import { useSessionId } from "@/hooks/useSessionId";

export default function RequireAuth<Props extends object>(
  props: { component: ComponentType<Props>; title?: string; path?: string } & Props
) {
  const pathname = useLocation({
    select: (location) => location.pathname,
  });
  const searchParams = useSearch({ strict: false }) satisfies { error?: string; error_description?: string };
  const ProtectedComponent = useMemo(() => {
    return withAuthenticationRequired<Props>(props.component, {
      onRedirecting: () => <Loading fullHeight />,
    });
  }, [props.component]);

  usePostHogIdentify();
  useSessionId();

  // For frontend tests: do not require Auth0
  if (isExternalAuthProvider()) {
    return React.createElement(props.component, props);
  }

  // Special case here for an authentication error
  if (pathname == "/auth" && searchParams.error) {
    return <Result status="error" title="Sorry, something went wrong." subTitle={searchParams.error_description} />;
  }
  return <ProtectedComponent {...props} />;
}
