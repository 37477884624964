import styled from "styled-components";

import { defaultTheme } from "@/styles/Global.styled";

export const ExpandableRow = styled.div`
  padding: 16px 24px;
  background-color: inherit;
`;

export const DetailsPanel = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  &:not(:first-child) {
    padding-top: 24px;
    border-top: 1px solid ${defaultTheme.colors.lightGray};
  }
`;

export const DetailGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailLabel = styled.div`
  font-size: 12px;
  color: ${defaultTheme.colors.blueGray};
  margin-bottom: 4px;
`;

export const DetailValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
