import { HDMHistoryChartLegendContainer, HDMHistoryChartLegendsWrapper } from "./HDM.styled";
import { DataMatrixHistoryLegend } from "./HDMHistoryChartUtils";

type HistoryChartLegendSectionProps = {
  legends: DataMatrixHistoryLegend[];
};

const HistoryChartLegendSection = ({ legends }: HistoryChartLegendSectionProps) => {
  return (
    <HDMHistoryChartLegendsWrapper>
      {legends.map((l) => {
        const iconClassName = `legend-icon${l.iconClassName ? ` ${l.iconClassName}` : ""}`;
        return (
          <HDMHistoryChartLegendContainer key={l.label}>
            <div className={iconClassName}></div>
            <p className="body-small">{l.label}</p>
          </HDMHistoryChartLegendContainer>
        );
      })}
    </HDMHistoryChartLegendsWrapper>
  );
};

export default HistoryChartLegendSection;
