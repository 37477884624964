import React from "react";

import { Cluster, ExpressionEvent } from "@/api";
import { EventFilter } from "@/api/customerApi";

import { BasicWidget } from "../widget/BasicWidget";
import { EventHeaderContainer } from "./EventHeader.styled";
import { EventHeaderExpression } from "./eventHeader/EventHeaderExpression";
import { EventHeaderFault } from "./eventHeader/EventHeaderFault";
import { EventHeaderRepair } from "./eventHeader/EventHeaderRepair";

const EventHeader: React.FC<{
  eventFilter: EventFilter;
  cluster?: Cluster | undefined;
  clusterLoading?: boolean;
  expression?: ExpressionEvent | undefined;
}> = ({ eventFilter, cluster, clusterLoading, expression }) => {
  const { repairEventType, expressionEventId } = eventFilter;

  const headerContent = (
    <EventHeaderContainer>
      {repairEventType && <EventHeaderRepair eventFilter={eventFilter} />}
      {expressionEventId && <EventHeaderExpression expression={expression} />}
      {eventFilter.fault && (
        <EventHeaderFault eventFilter={eventFilter} cluster={cluster} clusterLoading={clusterLoading ?? false} />
      )}
    </EventHeaderContainer>
  );

  return <BasicWidget title={headerContent} bordered={false} noHeaderStyling />;
};

export default React.memo(EventHeader);
