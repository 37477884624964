import CarIcon from "@amayaIcons/Car.svg?react";
import ClustersIcon from "@amayaIcons/Clusters.svg?react";
import { Segmented } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { HdmComparisonOptions } from "@/api/customerApi";
import { ComparisonDefinition } from "@/utils/historicDataMatrix/hdmUtils";

import HDMClusterComparisonForm from "./HDMClusterComparisonForm";
import { ComparisonFormWrapper } from "./HDMComparisonForm.styled";
import HDMCustomComparisonForm from "./HDMCustomComparisonForm";

// These types should come from API
export type HDMCompareBy = "FLEET" | "CLUSTER";

type HDMComparisonFormProps = {
  onChange: (settings: ComparisonDefinition) => void;
  comparisonOptions?: HdmComparisonOptions;
  defaultValues?: HdmComparisonOptions;
  clusters?: { name: string; id: string }[];
  setClusterNameSearch: Dispatch<SetStateAction<string | undefined>>;
  clusterNameSearch: string | undefined;
  clustersLoading: boolean;
  comparisonDefinition: ComparisonDefinition;
};

const HDMComparisonForm = ({
  onChange,
  comparisonOptions,
  defaultValues,
  clusters,
  setClusterNameSearch,
  clusterNameSearch,
  clustersLoading,
  comparisonDefinition,
}: HDMComparisonFormProps) => {
  const [comparisonType, setComparisonType] = useState<HDMCompareBy>("FLEET");

  const onSubmit: SubmitHandler<ComparisonDefinition> = (settings) => {
    onChange(settings);
  };

  return (
    <ComparisonFormWrapper>
      <Segmented
        className="comparison-by-selector body-medium"
        block
        options={[
          { value: "FLEET", label: "Fleet Comparison", icon: <CarIcon /> },
          { value: "CLUSTER", label: "Cluster Comparison", icon: <ClustersIcon /> },
        ]}
        defaultValue="FLEET"
        onChange={(val) => setComparisonType(val as HDMCompareBy)}
      />
      {comparisonType === "FLEET" && (
        <HDMCustomComparisonForm
          comparisonOptions={comparisonOptions}
          defaultValues={defaultValues}
          onChange={onSubmit}
          comparisonDefintion={comparisonDefinition.customComparison}
        />
      )}
      {comparisonType === "CLUSTER" && (
        <HDMClusterComparisonForm
          onChange={onSubmit}
          clusters={clusters}
          comparisonOptions={comparisonOptions}
          setClusterNameSearch={setClusterNameSearch}
          clusterNameSearch={clusterNameSearch}
          clustersLoading={clustersLoading}
          //For SCV use default values. Cluster view will provide battery size
          // from a dropdown on the history component
          comparisonDefinition={comparisonDefinition}
        />
      )}
    </ComparisonFormWrapper>
  );
};

export default HDMComparisonForm;
