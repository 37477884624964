import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { SortOrder, UserEventsSortField } from "@/api";
import { TableState } from "@/components/tables/StatefulTable.types";
import { EventAnalysis } from "@/features/eventAnalysis/EventAnalysis";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: UserEventsSortField.LastModified, order: SortOrder.Descend },
};

export type EventsHomeSearch = {
  patternEventsTable?: TableState;
};

export type EventAnalysisSearch = {
  xEventAnalysisChart?: string;
  eventOccurrencesTable?: TableState;
  relatedEventsTable?: TableState;
};

export const Route = createFileRoute("/event-analysis")({
  component: () => (
    <AppMainLayout>
      <EventAnalysis />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Events"),
  validateSearch: (search: Record<string, unknown>): EventsHomeSearch => {
    return {
      patternEventsTable: (search.patternEventsTable as TableState) || defaultTableState,
    };
  },
});
