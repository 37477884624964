import { Link } from "@tanstack/react-router";
import { Button } from "antd";
import { Dispatch, SetStateAction } from "react";

import {
  FilterType,
  GroupFilterCriteria,
  RepairEvent,
  RepairEventClusterMatch,
  RepairEventsClusterMatchesQuery,
  RepairEventsColumns,
} from "@/api/customerApi";
import Loading from "@/components/loading";
import { ColumnTypeWithFiltering } from "@/components/tables/StatefulTable";
import { FilteringFieldOption, TFilteringFieldName } from "@/components/tables/useRemoteFiltering";
import i18next from "@/i18n";
import { parseDateToStr } from "@/utils/dateUtils";
import { CommonDataDictionary } from "@/utils/types/commonDataDictionary";
import {
  consolidateModelStyleOptions,
  spreadModelStyleOptions,
  toModelDescription,
  translateInputToModelCode,
} from "@/utils/vehicleModel";

type getColumnArgs = {
  isFetchingFilteringOptions: boolean;
  getFilteringOptions: (fieldName: RepairEventsColumns) => {
    label: string;
    value: string | undefined;
  }[];
  handleFilterInputChange: (
    field: TFilteringFieldName,
    typing: FilteringFieldOption["label"],
    mappedResults?: string[]
  ) => void;
  areVinsEnabled: boolean;
  customerIdentifier: CommonDataDictionary;
  isClusterMatchesLoading: boolean;
  clusterMatches: RepairEventsClusterMatchesQuery | undefined;
  setSelectedClusterMatches: Dispatch<SetStateAction<RepairEventClusterMatch[] | undefined>>;
};
export const getColumns = (args: getColumnArgs) => {
  const {
    isFetchingFilteringOptions,
    getFilteringOptions,
    handleFilterInputChange,
    areVinsEnabled,
    customerIdentifier,
    clusterMatches,
    isClusterMatchesLoading,
    setSelectedClusterMatches,
  } = args;

  const columns: ColumnTypeWithFiltering<RepairEvent>[] = [
    {
      key: "id",
      title: i18next.t("columnTitle.REPAIR_EVENT_ID"),
      dataIndex: "id",
      sorter: true,
      onCell: () => ({
        style: {
          maxWidth: "min-content",
          minWidth: "min-content",
        },
      }),
      width: 350,
      render: (_v: string, row) => row.id || "-",
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: i18next.t("columnTitle.REPAIR_EVENT_ID"),
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.Id,
        options: getFilteringOptions(RepairEventsColumns.Id),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.Id, value),
      },
    },
    areVinsEnabled
      ? {
          key: "vin",
          title: "VIN",
          dataIndex: "vin",
          sorter: true,
          render: (_v, record) => (
            <Link to="/vehicle/$pvin" params={{ pvin: record.pvin ?? "" }}>
              {record.vin || "-"}
            </Link>
          ),
          filterProps: {
            includeNulls: true,
            criteria: GroupFilterCriteria.Incl,
            type: FilterType.Group,
            label: "VIN",
            isLoading: isFetchingFilteringOptions,
            columnKey: RepairEventsColumns.Vin,
            options: getFilteringOptions(RepairEventsColumns.Vin),
            onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.Vin, value),
          },
        }
      : {
          key: "pvin",
          title: "PVIN",
          dataIndex: "pvin",
          sorter: true,
          render: (_v, record) => (
            <Link to="/vehicle/$pvin" params={{ pvin: record.pvin ?? record.vin ?? "" }}>
              {record.pvin}
            </Link>
          ),
          filterProps: {
            includeNulls: true,
            criteria: GroupFilterCriteria.Incl,
            type: FilterType.Group,
            label: "PVIN",
            isLoading: isFetchingFilteringOptions,
            columnKey: RepairEventsColumns.Pvin,
            options: getFilteringOptions(RepairEventsColumns.Pvin),
            onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.Pvin, value),
          },
        },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
      sorter: true,
      render: (_v: string, row) => row.modelYear || "-",
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Model Year",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.ModelYear,
        options: getFilteringOptions(RepairEventsColumns.ModelYear),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.ModelYear, value),
      },
    },
    {
      key: "modelStyle",
      title: "Model",
      dataIndex: "modelStyle",
      sorter: true,
      render: (_v: string, row) => {
        const md = toModelDescription({ modelStyle: row.model }, customerIdentifier.models);
        return md !== "undefined" ? md : "-";
      },
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Model",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.ModelStyle,
        options: consolidateModelStyleOptions(
          getFilteringOptions(RepairEventsColumns.ModelStyle),
          customerIdentifier.models
        ),
        onSearchChange: (value) => {
          handleFilterInputChange(
            RepairEventsColumns.ModelStyle,
            "",
            translateInputToModelCode(value, customerIdentifier)
          );
        },
        labelRenderer: (value) => {
          const values = spreadModelStyleOptions([value]);
          const md = toModelDescription({ modelStyle: values[0] ?? "" }, customerIdentifier.models);
          return md !== undefined ? md : "-";
        },
      },
    },
    {
      key: "diagnosticCheckId",
      title: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"),
      dataIndex: "diagnosticCheckId",
      sorter: true,
      render: (_v, row) => {
        return row.diagnosticCheckId ? row.diagnosticCheckId : "-";
      },
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"),
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.DiagnosticCheckId,
        options: getFilteringOptions(RepairEventsColumns.DiagnosticCheckId),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.DiagnosticCheckId, value),
      },
    },
    {
      key: "startDate",
      title: "Repair Start Date",
      dataIndex: "repairStartDate",
      sorter: true,
      render: (_v, row) => (row.repairStartDate ? parseDateToStr(new Date(row.repairStartDate)) : "-"),
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Date,
        label: "Repair Start Date",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.MaintenanceStart,
        options: getFilteringOptions(RepairEventsColumns.MaintenanceStart),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.MaintenanceStart, value),
      },
    },
    {
      key: "endDate",
      title: "Repair End Date",
      dataIndex: "repairEndDate",
      sorter: true,
      render: (_v, row) => (row.repairEndDate ? parseDateToStr(new Date(row.repairEndDate)) : "-"),
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Date,
        label: "Repair End Date",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.MaintenanceEnd,
        options: getFilteringOptions(RepairEventsColumns.MaintenanceEnd),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.MaintenanceEnd, value),
      },
    },
    {
      key: "componentLocationId",
      title: `${i18next.t("columnTitle.COMPONENT_LOCATION_ID")}`,
      dataIndex: "componentLocationId",
      sorter: true,
      render: (_value, row) => row.componentLocationId || "-",
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Failure Location",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.ComponentLocationId,
        options: getFilteringOptions(RepairEventsColumns.ComponentLocationId),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.ComponentLocationId, value),
      },
    },
    {
      key: "clusterId",
      title: "Cluster ID",
      dataIndex: "clusterId",
      sorter: true,
      render: (_value, row) => row.clusterId || "-",
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Cluster Id",
        isLoading: isFetchingFilteringOptions,
        columnKey: RepairEventsColumns.ClusterId,
        options: getFilteringOptions(RepairEventsColumns.ClusterId),
        onSearchChange: (value) => handleFilterInputChange(RepairEventsColumns.ClusterId, value),
      },
    },
    {
      key: "clusterMatches",
      title: "Cluster Matches",
      render: (_, row) => {
        if (isClusterMatchesLoading)
          return <Loading size={"small"} height={16} label="Finding Matches" logoPosition="left" aiLoading={true} />;
        const clusters =
          clusterMatches?.filteredQuery.RepairEventsClustertMatches.filter(
            (m) => m?.repairEventId === row.id && m.componentLocationId === row.componentLocationId
          ) ?? [];
        if (clusters.length < 1) return "No Matches";
        return (
          <Button
            className="table-link"
            type="link"
            onClick={(e) => {
              e.preventDefault();
              setSelectedClusterMatches(clusters);
            }}
            onDrag={(e) => {
              e.preventDefault();
            }}
          >
            {clusters.length === 1 ? "1 Match" : `${clusters.length} Matches`}
          </Button>
        );
      },
    },
  ];

  return columns;
};
