import { DownOutlined, UpOutlined } from "@ant-design/icons";
import type { ExpandableConfig } from "antd/es/table/interface";
import React, { useState } from "react";

import { BasicTable } from "./BasicTable";
import { ExpandableRow } from "./ExpandableTable.styles";
import StatefulTable, { StatefulTableProps } from "./StatefulTable";
import { TableStateSearchKey } from "./StatefulTable.types";

type ExpandedRowRender<T> = NonNullable<ExpandableConfig<T>["expandedRowRender"]>;

export type ExpandableTableProps<T extends object> = Omit<StatefulTableProps<T>, "expandable" | "searchKey"> & {
  /**
   * Function to render the expanded content for a row
   */
  expandedRowRender: ExpandedRowRender<T>;

  /**
   * Function to determine if a row can be expanded
   */
  rowExpandable?: (record: T) => boolean;

  /**
   * Initially expanded row keys
   */
  defaultExpandedRowKeys?: React.Key[];

  /**
   * Callback when row expansion changes
   */
  onExpandedRowsChange?: (expandedRows: React.Key[]) => void;

  /**
   * Callback when row is expanded or collapsed
   */
  onExpand?: (expanded: boolean, record: T) => void;

  /**
   * Search key for the table
   */
  searchKey?: TableStateSearchKey;
};

// TODO: Need to figure out the styling for the expanded rows so that the row color and expanded row color are consistent
// This is due to a known behavior of ant design tables; however it messes with our alternating row colors
function ExpandableTable<T extends object>({
  columns = [],
  dataSource = [],
  rowKey = "key",
  expandedRowRender,
  rowExpandable,
  defaultExpandedRowKeys = [],
  onExpandedRowsChange,
  onExpand,
  searchKey,
  ...restProps
}: ExpandableTableProps<T>) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>(defaultExpandedRowKeys);

  const handleExpand = (expanded: boolean, record: T) => {
    const key = typeof rowKey === "function" ? rowKey(record) : record[rowKey as keyof T];
    const recordKey = key as React.Key;

    let newExpandedRowKeys: React.Key[];

    if (expanded) {
      newExpandedRowKeys = [...expandedRowKeys, recordKey];
    } else {
      newExpandedRowKeys = expandedRowKeys.filter((k) => k !== recordKey);
    }

    setExpandedRowKeys(newExpandedRowKeys);
    onExpandedRowsChange?.(newExpandedRowKeys);
    onExpand?.(expanded, record);
  };

  const getRowClassName = (record: T, _index: number) => {
    const key = typeof rowKey === "function" ? rowKey(record) : record[rowKey as keyof T];
    return expandedRowKeys.includes(key as React.Key) ? "expanded-row" : "";
  };

  // Simple wrapper to add consistent styling to expanded rows
  const renderWrapper: ExpandedRowRender<T> = (record, index, indent, expanded) => {
    return <ExpandableRow>{expandedRowRender(record, index, indent, expanded)}</ExpandableRow>;
  };

  // Handle row click to expand/collapse
  const onRow = (record: T) => {
    const key = typeof rowKey === "function" ? rowKey(record) : record[rowKey as keyof T];
    const recordKey = key as React.Key;
    const isExpandable = rowExpandable ? rowExpandable(record) : true;

    if (!isExpandable) return {};

    return {
      onClick: () => {
        const isExpanded = expandedRowKeys.includes(recordKey);
        handleExpand(!isExpanded, record);
      },
      style: { cursor: "pointer" },
    };
  };

  const defaultExpandIcon = ({ expanded, record }: any) => {
    const isExpandable = rowExpandable ? rowExpandable(record) : true;
    if (!isExpandable) return null;

    return (
      <span
        onClick={(e) => {
          e.stopPropagation(); // Prevent row click handler from firing
          handleExpand(!expanded, record);
        }}
        style={{ cursor: "pointer" }}
      >
        {expanded ? <UpOutlined /> : <DownOutlined />}
      </span>
    );
  };

  const expandableConfig = {
    expandedRowRender: renderWrapper,
    rowExpandable: rowExpandable || (() => true),
    expandedRowKeys,
    onExpand: handleExpand,
    expandIcon: defaultExpandIcon,
  };

  const tableProps = {
    ...restProps,
    columns,
    dataSource: dataSource as T[],
    rowKey,
    rowClassName: getRowClassName,
    onRow,
    expandable: expandableConfig,
  };

  // Use appropriate table component based on whether searchKey is provided
  return searchKey ? <StatefulTable<T> {...tableProps} searchKey={searchKey} /> : <BasicTable<T> {...tableProps} />;
}

export default ExpandableTable;
