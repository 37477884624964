// FILE FOR EVERY TYPE NEEDED ON THE COMMON DATA DICTIONARY

export interface FaultCodeDescription {
  faultCode?: string;
  troubleCode?: string;
  ecu?: string;
  name?: string;
}

export interface FaultCodeSourceDescription {
  ecu: string;
  name?: string;
}

export interface FaultField {
  id: string;
  name?: string;
  unit?: string;
}

export enum DataMatrixAggregations {
  SUM = "sum",
  MEAN = "mean",
}

export enum DataMatrixVisualizations {
  BAR_CHART = "bar_chart",
  HEAT_MAP = "heat_map",
}

export enum DataMatrixNormalizations {
  DIFFERENCE_FROM_MEAN = "difference_from_mean",
  SCALED_BY_CONSTANT = "scaled_by_constant",
  RAW = "raw",
}

export interface DataMatrixDefinition {
  table_id: string;
  name: string;
  description?: string;
  default_table_aggregation: DataMatrixAggregations;
  visualization: DataMatrixVisualizations;
  x_axis_label: string;
  x_labels?: string[];
  y_axis_label: string;
  y_labels?: string[];
  groups?: string[];
  cell_based: boolean;
  value_unit: string;
  normalizations: DataMatrixNormalizations[];
  attributes: string[];
}

interface BatteryConfiguration {
  modules: number[];
  cells: number;
}
export interface BatteryStyle {
  model: Record<string, BatteryConfiguration>;
  default: BatteryConfiguration;
}

export interface CommonDataDictionary {
  models?: VehicleModels;
  vehicleImages?: VehicleImages;
  faultCodes?: FaultCodeDescription[];
  faultCodeSources?: FaultCodeSourceDescription[];
  faultFields?: FaultField[];
  dataMatrixTables?: DataMatrixDefinition[];
  batteryModules?: BatteryStyle;
}

export interface VehicleStyles {
  style: Record<string, string>;
  default?: string;
}

export interface VehicleModels {
  model: Record<string, VehicleStyles>;
  default?: VehicleStyles;
}

export interface VehicleImages {
  model: Record<string, string>;
  default: string;
}
