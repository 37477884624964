import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { FilterType, GroupFilterCriteria, SortOrder } from "@/api";
import { TableState } from "@/components/tables/StatefulTable.types";
import { ClusterView } from "@/features/cluster/clusterView/ClusterView";
import { DataMatrixState } from "@/features/historicDataMatrix/useDataMatrixState";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type ClusterEntitySearch = {
  clusterDiagnosticChecksTable: TableState;
  clusterFaultEventsTable: TableState;
  dataMatrix?: DataMatrixState;
};

export const defaultFaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: "faultCodeAppearances", order: SortOrder.Descend },
  filters: [
    {
      key: "trouble_code",
      type: FilterType.Group,
      group: [],
      groupCriteria: GroupFilterCriteria.Incl,
      includeNulls: true,
    },
  ],
};

export const defaultDiagnosticTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
};

export const Route = createFileRoute("/cluster/$clusterId")({
  component: () => (
    <AppMainLayout>
      <ClusterView />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Cluster"),
  validateSearch: (search: Record<string, unknown>): ClusterEntitySearch => {
    return {
      clusterDiagnosticChecksTable: (search.clusterDiagnosticChecksTable as TableState) || defaultDiagnosticTableState,
      clusterFaultEventsTable: (search.clusterFaultEventsTable as TableState) || defaultFaultTableState,
      dataMatrix: (search.dataMatrix as DataMatrixState) || undefined,
    };
  },
});
