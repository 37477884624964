import { isFinite, isNull } from "lodash";
import React from "react";

import { HdmNormalizations } from "@/api/customerApi";
import AmayaBarChart from "@/components/charts/AmayaBarChart";
import { AmayaBarChartProps } from "@/components/charts/ChartConfigHelper";
import { EmptyComponent } from "@/components/ui/EmptyComponent";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { toArray } from "@/utils/arrayUtils";
import { formatCompactNumber, formatNumber } from "@/utils/numberUtils";
import { displayPositiveSign } from "@/utils/stringUtils";
import { DataMatrixDefinition, DataMatrixVisualizations } from "@/utils/types/commonDataDictionary";
import { EchartsTooltipFormatterFn, EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

import { HDMComparisonContainer } from "./HDMComparison.styled";
import HDMHeatMap from "./HDMHeatMap";
import { HDMHeatMapAndTitleContainer } from "./HDMHeatMap.styled";

const dataMatrixToBarChartSeries = (data: number[], categorySize: number, groupSize: number): number[][] => {
  return Array.from({ length: groupSize }, (_, i) => data.slice(i * categorySize, (i + 1) * categorySize));
};

const getOnTooltip = (comparisonMode?: boolean): EchartsTooltipFormatterFn => {
  return (params) => {
    const seriesParams = toArray(params) as EchartsTooltipFormatterParams[];
    const yAxisLabel = seriesParams[0].axisValueLabel;
    const headerRow = `<p class="body-small header-row">${yAxisLabel}</p>`;

    const barSeriesElements = seriesParams.map((param) => {
      const seriesValue = typeof param.value === "number" ? param.value : 0;
      const valueLabel = comparisonMode
        ? `${displayPositiveSign(seriesValue)}${formatNumber(seriesValue)}`
        : formatNumber(seriesValue);
      return `<div class="series-container body-small compact no-border">
        <div class="series-marker">${param.marker}</div>
        <div class="series-name long-name text-overflowing">${param.seriesName}</div>
        <div class="series-value pull-right">
          <span class="value-data capitalize">${valueLabel}</span>
        </div>
      </div>`;
    });

    return `<div class="tooltip-body">${[headerRow, ...barSeriesElements].join("")}</div>`;
  };
};

type DataMatrixChartVisualizationsProps = {
  tableDefinition?: DataMatrixDefinition;
  comparisonMode: boolean;
  tableValues: (number | null)[];
  comparisonValues: (number | null)[];
  tableIsLoading: boolean;
  comparisonTableIsLoading: boolean;
  currentNormalization: HdmNormalizations;
  currentBatterySize?: number;
  comparisonBatterySize?: number;
};

const DataMatrixChartVisualizations = ({
  tableDefinition,
  comparisonMode,
  tableValues,
  comparisonValues,
  tableIsLoading,
  comparisonTableIsLoading,
  currentNormalization,
  currentBatterySize,
  comparisonBatterySize,
}: DataMatrixChartVisualizationsProps) => {
  const { customerIdentifier } = useCustomerIdentifier();

  const filteredTableValues = tableValues.filter((v): v is number => typeof v === "number" && isFinite(v));
  const filteredComparisonValues = comparisonValues.filter((v): v is number => typeof v === "number" && isFinite(v));

  const heatMapMax =
    filteredTableValues.length || filteredComparisonValues.length
      ? Math.max(...filteredTableValues, ...filteredComparisonValues)
      : 0; // Default value when both are empty;

  const heatMapMin =
    filteredTableValues.length || filteredComparisonValues.length
      ? Math.min(...filteredTableValues, ...filteredComparisonValues)
      : 0; // Default value when both are empty;

  const valuesArePercentages = currentNormalization === HdmNormalizations.Percentage;

  const categorySize = tableDefinition?.x_labels?.length ?? 0;
  const groupSize = tableDefinition?.groups?.length ?? 1;

  let tableDataChart: React.JSX.Element;
  let comparisonTableDataChart: React.JSX.Element;

  const currentVisualization = tableDefinition?.visualization;

  const maxCellCount = customerIdentifier.batteryModules?.default.cells ?? 0;
  const maxModuleCount = currentBatterySize ?? Math.max(...(customerIdentifier.batteryModules?.default?.modules ?? 0));
  const maxComparisonModuleCount = comparisonBatterySize ?? 0;

  // If customer config has no x_labels we get them from customerIdentifier cells
  const xAxisElements = tableDefinition?.x_labels ?? Array.from({ length: maxCellCount }, (_, i) => (i + 1).toString());
  // If customer config has no x_labels we get them from customerIdentifier modules
  const yAxisElements =
    tableDefinition?.y_labels ?? Array.from({ length: maxModuleCount }, (_, i) => (i + 1).toString());

  const comparisonYAxisElements =
    tableDefinition?.y_labels ?? Array.from({ length: maxComparisonModuleCount }, (_, i) => (i + 1).toString());

  const xAxisLabel = tableDefinition?.x_axis_label ?? "x";
  const yAxisLabel = tableDefinition?.y_axis_label ?? "y";

  const getTableDisplayValues = (index: number, compact: boolean, raw?: boolean) => {
    const comparisonValue = comparisonValues[index] ?? 0;
    const possibleNullTableValue = tableValues[index];
    if (!isFinite(possibleNullTableValue) || isNull(possibleNullTableValue)) return "";
    const finiteTableValue = possibleNullTableValue;
    if (!comparisonMode) return compact ? formatCompactNumber(finiteTableValue) : formatNumber(finiteTableValue);
    if (!isFinite(finiteTableValue) || !isFinite(comparisonValue)) return "";

    const value = comparisonMode ? finiteTableValue - comparisonValue : finiteTableValue;

    if (raw) {
      return value;
    }

    const shouldCheckForPositiveValue =
      currentNormalization === HdmNormalizations.DifferenceFromMean || !!comparisonValue;
    return `${displayPositiveSign(value, shouldCheckForPositiveValue)}${
      compact ? formatCompactNumber(value) : formatNumber(value)
    }`;
  };

  const getComparisonDisplayValues = (index: number, compact: boolean) => {
    const value = comparisonValues[index];
    if (!isFinite(value) || isNull(value)) return "";
    return `${displayPositiveSign(value, currentNormalization === HdmNormalizations.DifferenceFromMean)}${
      compact ? formatCompactNumber(value) : formatNumber(value)
    }`;
  };

  if (currentVisualization === DataMatrixVisualizations.BAR_CHART) {
    const resultTableValues = comparisonMode
      ? filteredComparisonValues.map((e, i) => filteredTableValues[i] - e)
      : filteredTableValues;

    const tableChartConfig = {
      bucketLabels: tableDefinition?.x_labels ?? [],
      seriesData: dataMatrixToBarChartSeries(resultTableValues, categorySize, groupSize),
      seriesLabels: tableDefinition?.groups ?? [],
      valueLabel: tableDefinition?.value_unit,
      isLoading: tableIsLoading,
      showLegend: true,
      onTooltip: getOnTooltip(comparisonMode),
    } satisfies AmayaBarChartProps;

    const comparisonChartConfig = {
      bucketLabels: tableDefinition?.x_labels ?? [],
      seriesData: dataMatrixToBarChartSeries(filteredComparisonValues, categorySize, groupSize),
      seriesLabels: tableDefinition?.groups ?? [],
      valueLabel: tableDefinition?.value_unit,
      isLoading: comparisonTableIsLoading,
      showLegend: true,
      onTooltip: getOnTooltip(),
    } satisfies AmayaBarChartProps;

    tableDataChart = <AmayaBarChart {...tableChartConfig} />;
    comparisonTableDataChart = <AmayaBarChart {...comparisonChartConfig} />;
  } else if (currentVisualization === DataMatrixVisualizations.HEAT_MAP) {
    tableDataChart = (
      <HDMHeatMap
        values={tableValues}
        isLoading={tableIsLoading}
        max={heatMapMax}
        min={heatMapMin}
        xAxisElements={xAxisElements}
        yAxisElements={yAxisElements}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
        getDisplayValues={getTableDisplayValues}
        valuesArePercentages={valuesArePercentages}
        comparisonMode={comparisonMode}
      />
    );
    comparisonTableDataChart = (
      <HDMHeatMap
        values={comparisonValues}
        isLoading={comparisonTableIsLoading}
        max={heatMapMax}
        min={heatMapMin}
        xAxisElements={xAxisElements}
        yAxisElements={comparisonYAxisElements}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
        getDisplayValues={getComparisonDisplayValues}
        valuesArePercentages={valuesArePercentages}
      />
    );
  } else {
    const unknownVisualizationElement = (
      <EmptyComponent description={`Unknown visualization: ${tableDefinition?.visualization}`} />
    );
    tableDataChart = unknownVisualizationElement;
    comparisonTableDataChart = unknownVisualizationElement;
  }

  return (
    <HDMComparisonContainer>
      <HDMHeatMapAndTitleContainer>{tableDataChart}</HDMHeatMapAndTitleContainer>
      <HDMHeatMapAndTitleContainer>{comparisonTableDataChart}</HDMHeatMapAndTitleContainer>
    </HDMComparisonContainer>
  );
};
export default DataMatrixChartVisualizations;
