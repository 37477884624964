import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import { ComparisonDefinition, getHDMTableDefinitions, SelectedDataMatrix } from "@/utils/historicDataMatrix/hdmUtils";

import useDataMatrixRouteParams from "./useDataMatrixRouteParams";

export type DataMatrixState = {
  tableId?: string;
  selectedMatrix?: SelectedDataMatrix;
  comparisonDefinition?: ComparisonDefinition;
};

const useDataMatrixState = () => {
  useDataMatrixRouteParams();
  const navigate = useNavigate({ from: "/" });
  const dms = useSearch({ strict: false, select: (search) => search.dataMatrix });

  const { getFeatureConfig } = useFeatureFlags();
  const { customerIdentifier } = useCustomerIdentifier();
  const availableCustomerTables = getHDMTableDefinitions(customerIdentifier, getFeatureConfig);

  const selectedTableDefinition = availableCustomerTables?.find((t) => t.table_id === dms?.tableId);

  const setSelectedTableId = (tableId: string, replace?: boolean) => {
    navigate({
      search: (prev) => ({ ...prev, dataMatrix: { ...dms, tableId } }),
      replace,
    });
  };

  const setSelectedMatrix = (selectedMatrix: SelectedDataMatrix, replace?: boolean) => {
    navigate({
      search: (prev) => ({ ...prev, dataMatrix: { ...dms, selectedMatrix } }),
      replace,
    });
  };

  const setComparisonDefinition = (comparisonDefinition: ComparisonDefinition, replace?: boolean) => {
    navigate({
      search: (prev) => ({ ...prev, dataMatrix: { ...dms, comparisonDefinition } }),
      replace,
    });
  };

  useEffect(() => {
    if (availableCustomerTables && !dms?.tableId) {
      setSelectedTableId(availableCustomerTables[0].table_id, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dms,
    availableCustomerTables,
    selectedTableDefinition,
    setSelectedTableId,
    setSelectedMatrix,
    setComparisonDefinition,
  };
};

export default useDataMatrixState;
