import { TimeSeriesIndependentVar } from "@/api";
import { AnomalySampleVehicle, VehicleAnomalyState } from "@/api/customerApi";
import { xAxisLabelFormatter } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";
import { EchartsTooltipFormatterFn } from "@/utils/types/EChartsDefinitions";

import { ANOMALY_CAUTION_COLOR, ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "../singleCar/common/anomalyColors";

export type VehicleDetectionPoint = {
  pvin: string;
  anomalyState?: VehicleAnomalyState;
  multiAxis?: boolean;
};

export type ScatterPoint = [number, number, unknown];

export type ScatterParam = { value: ScatterPoint };

export const unknownDatapointConverter = (e: unknown): VehicleDetectionPoint | undefined => {
  if (e && typeof e === "object" && "pvin" in e) {
    return e as VehicleDetectionPoint;
  }
};

export const anomalyStateToColor = (anomalyState?: VehicleAnomalyState): string => {
  switch (anomalyState) {
    case VehicleAnomalyState.Warning:
      return ANOMALY_WARNING_COLOR;
    case VehicleAnomalyState.Caution:
      return ANOMALY_CAUTION_COLOR;
    default:
      return ANOMALY_SAFE_COLOR;
  }
};

export const buildScatterPoints = (indVar: TimeSeriesIndependentVar, data: AnomalySampleVehicle[]): ScatterPoint[] => {
  if (!data.length) return [];
  return data.flatMap((v) => {
    const detectionPoint = {
      pvin: v.pvin,
      anomalyState: v.anomalyState,
    } satisfies VehicleDetectionPoint;
    const xPoint =
      indVar === TimeSeriesIndependentVar.Mileage
        ? v.odometer
        : v.lastDetected
        ? new Date(v.lastDetected).getTime()
        : 0;
    if (!xPoint || v.score === undefined || v.score === null) return [];
    return [[xPoint, v.score, detectionPoint]];
  });
};

type buildScatterChartConfigProps = {
  seriesData: ScatterPoint[];
  indVar: TimeSeriesIndependentVar;
  onTooltip?: EchartsTooltipFormatterFn;
  tooltipSelector?: string;
};

export const buildScatterChartConfig = ({
  seriesData,
  indVar,
  onTooltip,
  tooltipSelector,
}: buildScatterChartConfigProps) => {
  const xData = seriesData.map((e) => e[0]);
  const minTimestamp = Math.min(...xData);
  const maxTimestamp = Math.max(...xData);

  return {
    option: {
      grid: { left: 50, top: 30, right: 25, bottom: 30 },
      textStyle: {
        color: "#5F5F5F",
        fontFamily: "Hubot Sans",
      },
      xAxis: {
        type: indVar === TimeSeriesIndependentVar.Time ? "time" : "value",
        min: indVar === TimeSeriesIndependentVar.Time ? minTimestamp : undefined,
        max: indVar === TimeSeriesIndependentVar.Time ? maxTimestamp : undefined,
        offset: 0,
        nameGap: 30,
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 14,
          align: "right",
          verticalAlign: "top",
          padding: [20, 50, 0, 0],
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (value: any) => xAxisLabelFormatter(value, indVar, undefined),
          interval: indVar === TimeSeriesIndependentVar.Mileage ? 20 : 30,
          hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        name: "Score",
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 14,
          padding: [0, 70, 0, 0],
          align: "left",
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ebebeb",
          },
        },
        axisLine: {
          show: false,
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        enterable: onTooltip ? true : undefined,
        triggerOn: onTooltip ? "click" : undefined,
        padding: onTooltip ? 0 : undefined,
        className: tooltipSelector ? tooltipSelector.replaceAll(".", "") : "chart-library-tooltip-container",
        appendToBody: true,
        position: (
          point: [number, number],
          _params: any,
          _dom: any,
          _rect: any,
          size: { contentSize: [number, number]; viewSize: [number, number] }
        ) => {
          return constrainScatterToolTip(point, size);
        },
        formatter: onTooltip ? onTooltip : undefined,
      },
      series: [
        {
          symbolSize: 10,
          data: seriesData,
          type: "scatter",
          itemStyle: {
            opacity: 0.8,
            color: (param: ScatterParam) => {
              const [, , e] = param.value;
              const dataPoint = unknownDatapointConverter(e);
              if (dataPoint) {
                return anomalyStateToColor(dataPoint.anomalyState);
              }
              return "#4290E1";
            },
          },
          emphasis: {
            scale: 1.25,
          },
        },
      ],
    },
  };
};

export const constrainScatterToolTip = (
  point: [number, number],
  size: { contentSize: [number, number]; viewSize: [number, number] }
) => {
  const offsetX = 10;
  const offsetY = 10;

  // Since the tooltip has a react component ported into
  // then size.contentSize values are not correct
  const actualContentSize = [250, 300];

  let x = point[0] + offsetX;
  let y = point[1] + offsetY;

  if (x + actualContentSize[0] > size.viewSize[0]) {
    x = point[0] - actualContentSize[0] - offsetX;
  }

  return [x, y];
};
