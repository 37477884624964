import { ErrorBoundary } from "@sentry/react";
import { Card } from "antd";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import { useRef } from "react";

import { AnomalyDetectorType } from "@/api";
import { SingleVehicleAnomalyDetector, SingleVehicleAnomalyDetectorHeaders } from "@/api/customerApi";
import { ErrorFallback } from "@/components/error/ErrorFallBack";
import Loading from "@/components/loading/Loading";
import { useTelematicsProtectedSingleVehicleAnomalyDetectorQuery } from "@/hooks/telematicsProtectedHooks/useTelematicsProtectedSingleVehicleAnomalyDetectorQuery";

import { AnomalyCardHeadersTitle } from "../../common/AnomalyCardTitle";
import { buildAnomalyChartConfig } from "../../common/buildAnomalyChartConfig";
import { handleAnomalyChartMouseOut } from "../../common/handleAnomalyChartMouseOut";
import { handleAnomalyChartMouseOver } from "../../common/handleAnomalyChartMouseOver";
import { get90DayHistory } from "../expanded/summary/get90DayHistory";
import { getLast90DaysIndexFromMostRecentDate } from "../expanded/summary/getLast90DaysIndex";
import { AnomalyDetectorPreviewCardWrapper } from "./AnomalyDetectorPreviewCard.styled";

type AnomalyDetectorPreviewCardProps = {
  detector: SingleVehicleAnomalyDetectorHeaders;
  pvin: string;
  onClick: (detector: SingleVehicleAnomalyDetector) => any;
};

export const AnomalyDetectorPreviewCard = ({ pvin, detector, onClick }: AnomalyDetectorPreviewCardProps) => {
  return (
    <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
      <AnomalyDetectorPreviewCardContent {...{ pvin, detector, onClick }} />
    </ErrorBoundary>
  );
};

const AnomalyDetectorPreviewCardContent = ({ pvin, detector, onClick }: AnomalyDetectorPreviewCardProps) => {
  const anomalyChartRef = useRef<EChartsInstance>();

  const { data, isLoading } = useTelematicsProtectedSingleVehicleAnomalyDetectorQuery({
    pvin,
    type: detector.type,
    id: detector.id,
  });

  const fullyLoadedDetector = data?.singleVehicleAnomalyDetector as SingleVehicleAnomalyDetector;
  const noTelemetryAvailable =
    fullyLoadedDetector?.detector.type !== AnomalyDetectorType.CustomerProvided &&
    typeof fullyLoadedDetector?.vehicleData.score !== "number";

  const ninetyDayHistory = fullyLoadedDetector
    ? get90DayHistory(fullyLoadedDetector, getLast90DaysIndexFromMostRecentDate(fullyLoadedDetector))
    : undefined;

  const scoreHistoryChartOptions =
    fullyLoadedDetector && ninetyDayHistory
      ? buildAnomalyChartConfig(ninetyDayHistory, fullyLoadedDetector.detector, true)
      : undefined;
  return (
    <AnomalyDetectorPreviewCardWrapper
      onClick={() => {
        if (fullyLoadedDetector && !noTelemetryAvailable) onClick(fullyLoadedDetector);
      }}
      noTelemetryAvailable={noTelemetryAvailable}
    >
      <Card
        title={<AnomalyCardHeadersTitle anomaly={detector} disabled={noTelemetryAvailable && !isLoading} />}
        style={{
          border: "none",
        }}
        className="ad-preview-card"
      >
        {isLoading ? (
          <Loading />
        ) : (
          scoreHistoryChartOptions &&
          fullyLoadedDetector && (
            <ReactECharts
              {...scoreHistoryChartOptions}
              style={{ height: "60px" }}
              ref={anomalyChartRef}
              onChartReady={(c) => (anomalyChartRef.current = c)}
              onEvents={{
                mousemove: (e: any) => {
                  if (fullyLoadedDetector)
                    handleAnomalyChartMouseOver(e, ninetyDayHistory, fullyLoadedDetector, anomalyChartRef);
                },
                mouseout: () => {
                  if (fullyLoadedDetector) handleAnomalyChartMouseOut(anomalyChartRef.current);
                },
              }}
            />
          )
        )}
      </Card>
    </AnomalyDetectorPreviewCardWrapper>
  );
};
