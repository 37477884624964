import { ANOMALY_CAUTION_COLOR, ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "./anomalyColors";

export const anomalyScoreToColor = (
  score: number | undefined | null,
  warningThreshold: number | undefined | null,
  cautionThreshold?: number | null
) => {
  if (score === null || score === undefined) return undefined;
  if (!warningThreshold) return ANOMALY_SAFE_COLOR;
  if (!cautionThreshold) return score >= warningThreshold ? ANOMALY_WARNING_COLOR : ANOMALY_SAFE_COLOR;
  return score >= warningThreshold
    ? ANOMALY_WARNING_COLOR
    : score >= cautionThreshold
    ? ANOMALY_CAUTION_COLOR
    : ANOMALY_SAFE_COLOR;
};
