import EditIcon from "@amayaIcons/icon-edit.svg?react";
import TrashIcon from "@amayaIcons/icon-trash.svg?react";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm } from "antd";
import { useDispatch } from "react-redux";

import { FeatureId } from "@/api";
import { EmptyComponent } from "@/components/ui/EmptyComponent";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import { CustomChartSeries, FaultEventFilter, LibraryChart } from "@/contexts/ChartLibraryStore";
import { useFeatureFlags } from "@/utils/features";

import { chartSeriesSorter } from "./ChartLibraryBuildUtils";
import LibraryChartPreviewItem from "./LibraryChartPreviewItem";
import { LibraryChartPreviewHeaderContainer, LibraryChartPreviewListContainer } from "./LibraryChartPreviewList.styled";
import { ChartSerieslegend, ChartTelematicslessLegend } from "./LibraryChartToolbox";
import { LibraryFaultOnlyChartPreviewItem } from "./LibraryFaultOnlyChartPreviewItem";

interface ChartPreviewHeaderProps {
  id: string;
  series: CustomChartSeries[];
  onSetActiveChart: (chartId: string, openSettings?: boolean) => void;
  onDelete?: (chartId: string) => void;
}

const ChartPreviewHeader = ({ id, series, onDelete, onSetActiveChart }: ChartPreviewHeaderProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const activeChartSeries = [...(series || [])].sort(chartSeriesSorter);
  const mainSeries = activeChartSeries.filter((s) => !s.comparisonSeries);
  return (
    <LibraryChartPreviewHeaderContainer>
      {isFeatureEnabled(FeatureId.Telemetry) && <ChartSerieslegend plottedSeries={mainSeries} />}
      {!isFeatureEnabled(FeatureId.Telemetry) && <ChartTelematicslessLegend />}
      <div className="header-tools">
        {onDelete ? (
          <Popconfirm
            title="Are you sure you want to delete this Chart?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDelete(id)}
          >
            <Button type="text" icon={<TrashIcon />} size="small" />
          </Popconfirm>
        ) : undefined}
        <Button
          className="preview-chart-open-edit-cta"
          type="text"
          icon={<EditIcon />}
          size="small"
          onClick={() => onSetActiveChart(id, true)}
        />
        <Button
          className="preview-chart-open-cta"
          icon={<ArrowsAltOutlined />}
          type="text"
          size="small"
          onClick={() => onSetActiveChart(id)}
        />
      </div>
    </LibraryChartPreviewHeaderContainer>
  );
};

interface LibraryChartPreviewListProps {
  charts: LibraryChart[];
  faultsFitered: FaultEventFilter[];
}

const LibraryChartPreviewList = ({ charts, faultsFitered }: LibraryChartPreviewListProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const dispatch = useDispatch();

  const handleDeleteChart = (chartId: string) => {
    dispatch(ChartLibraryActions.removeChart({ chartId }));
  };

  const handleSetActiveChart = (chartId: string, openSettings?: boolean) => {
    dispatch(
      ChartLibraryActions.setActiveChart({
        chartId,
        openSettings,
        telematicsless: !isFeatureEnabled(FeatureId.Telemetry),
      })
    );
  };

  const listContainerClassname = `${charts.length === 1 ? "one-row" : ""}`;

  if (!charts.length) {
    return (
      <Flex className="preview-chart-container" align="center" justify="center" style={{ height: 256, width: "100%" }}>
        <EmptyComponent />
      </Flex>
    );
  }

  return (
    <LibraryChartPreviewListContainer className={listContainerClassname}>
      {/* Hack below to keep grid look and borders when a row has odd columns*/}
      {[
        ...charts.map((chart) => {
          return (
            <div className="preview-chart-container" key={chart.chartId}>
              <>
                <ChartPreviewHeader
                  id={chart.chartId}
                  series={chart.series}
                  onSetActiveChart={handleSetActiveChart}
                  onDelete={charts.length > 1 ? handleDeleteChart : undefined}
                />
                {isFeatureEnabled(FeatureId.Telemetry) && <LibraryChartPreviewItem chart={chart} />}
                {!isFeatureEnabled(FeatureId.Telemetry) && (
                  <LibraryFaultOnlyChartPreviewItem chart={chart} faultsFiltered={faultsFitered} />
                )}
              </>
            </div>
          );
        }),
        ...[
          charts.length > 1 && charts.length % 2 !== 0 ? (
            <div className="preview-chart-container" key="odd-column-filler" />
          ) : undefined,
        ],
      ]}
    </LibraryChartPreviewListContainer>
  );
};

export default LibraryChartPreviewList;
