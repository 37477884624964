import LeftBraceIcon from "@amayaIcons/operators/LeftBrace.svg?react";
import RightBraceIcon from "@amayaIcons/operators/RightBrace.svg?react";
import { Expression, IfExpression } from "@lib/src/expression/types";
import { isEmpty, isEqual } from "lodash";
import { KeyboardEvent, useContext, useState } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { nodeTypeHasWrapping } from "../TypeaheadUtils";
import NodeWrapper from "./NodeWrapper";
import { PatternFocusedNode } from "./PatternFocusedNode";
import PatternNode from "./PatternNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternIfNodeProps = {
  node: IfExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const PatternIfNode = ({ node, path, onChange, disabled }: PatternIfNodeProps) => {
  const { mostRecentPath } = useContext(PatternFocusContext);
  const [ifHover, setIfHover] = useState(false);
  const [elseHover, setElseHover] = useState(false);

  const ifSymbolClassname = `bracket-symbol${ifHover ? " highlighted" : ""}`;
  const elseSymbolClassname = `bracket-symbol${elseHover ? " highlighted" : ""}`;

  const handleDelete = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Backspace" && (e?.target as HTMLInputElement).value === "") {
      const remainingValues = node.values.filter((v) => Object.keys(v).length !== 0);
      if (remainingValues.length === 0) {
        onChange({}, path);
      } else if (remainingValues.length === 1) {
        onChange(remainingValues[0], path);
      }
    }
  };

  const areIfValuesInvalid = (valueIndex: number) => {
    const isMostRecentPathInFamily =
      isEqual(mostRecentPath, [...path, "values", "0"]) ||
      isEqual(mostRecentPath, [...path, "values", "1"]) ||
      isEqual(mostRecentPath, [...path, "values", "2"]) ||
      isEqual(mostRecentPath, path);
    if (isMostRecentPathInFamily) {
      return;
    } else {
      return isEmpty(node.values[valueIndex]) ? "error" : undefined;
    }
  };

  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <PatternNodeContainer className="conditional-statement">
        <div className="inline-node-section">
          <span className="fn-name-label">{"IF"}</span>
          {/* If */}
          <NodeWrapper hidden={nodeTypeHasWrapping(node.values[0].type)}>
            <PatternNode
              onNodeChange={onChange}
              path={[...path, "values", "0"]}
              node={node.values[0]}
              deleteFunction={handleDelete}
              disabled={disabled}
              status={areIfValuesInvalid(0)}
            />
          </NodeWrapper>
          <span
            className={ifSymbolClassname}
            onMouseEnter={() => setIfHover(true)}
            onMouseLeave={() => setIfHover(false)}
          >
            <LeftBraceIcon />
          </span>
        </div>
        <div className="indented-node-section">
          {/* Then */}
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "values", "1"]}
            node={node.values[1]}
            deleteFunction={handleDelete}
            disabled={disabled}
            status={areIfValuesInvalid(1)}
          />
        </div>
        <span
          className={ifSymbolClassname}
          onMouseEnter={() => setIfHover(true)}
          onMouseLeave={() => setIfHover(false)}
        >
          <RightBraceIcon />
        </span>
        <div className="centered-container">
          <span className="fn-name-label">{"ELSE"}</span>
          <span
            className={elseSymbolClassname}
            onMouseEnter={() => setElseHover(true)}
            onMouseLeave={() => setElseHover(false)}
          >
            <LeftBraceIcon />
          </span>
        </div>
        <div className="indented-node-section">
          {/* Else */}
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "values", "2"]}
            node={node.values[2]}
            deleteFunction={handleDelete}
            disabled={disabled}
            status={areIfValuesInvalid(2)}
          />
        </div>
        <span
          className={elseSymbolClassname}
          onMouseEnter={() => setElseHover(true)}
          onMouseLeave={() => setElseHover(false)}
        >
          <RightBraceIcon />
        </span>
      </PatternNodeContainer>
    </PatternFocusedNode>
  );
};

export default PatternIfNode;
