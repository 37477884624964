import { connect } from "echarts";
import EChartsReact, { EChartsInstance } from "echarts-for-react";
import { RefObject, useContext, useRef } from "react";
import { useSelector } from "react-redux";

import { useVehicleTimeSeriesQuery } from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { QUERY_SETTINGS } from "@/constants";
import { useChartColoringPool } from "@/contexts/ChartColoringPoolContext";
import {
  CustomChartSeries,
  LibraryChart,
  selectIndependentVariable,
  selectLibrarySpan,
  selectTimestampEpoch,
} from "@/contexts/ChartLibraryStore";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";
import { getNoTelemetryMessage } from "@/utils/singleCar";

import { EmptyComponentSCV } from "../vehicleDetails/EmptyComponentSCV";
import {
  buildChartThresholds,
  buildComparisonTimeSeries,
  buildLibraryDefaultChartConfig,
  buildTimeSeriesXAxisValues,
  chartSeriesSorter,
} from "./ChartLibraryBuildUtils";

export const GROUP_NAME = "chartLibrayTooltip";

interface LibraryChartPreviewItemProps {
  chart: LibraryChart;
}

const LibraryChartPreviewItem = ({ chart }: LibraryChartPreviewItemProps) => {
  const { pvin } = useContext(SCVContext);
  const echartRef = useRef<EChartsReact>();

  const coloringPoolCtx = useChartColoringPool();

  const { independentVariable: independentVar } = useSelector(selectIndependentVariable);
  const { timestampEpoch } = useSelector(selectTimestampEpoch);

  const librarySpan = useSelector(selectLibrarySpan);
  const { globalFilter } = useContext(GlobalFilterContext);
  const fields = chart.series.map((s) => s.TimeSeriesFieldInput);
  const comparisonFields: string[] = chart.series.filter((s) => s.comparisonType).map((s) => s.id);
  const withComparison = !!comparisonFields.length;
  const chartRangeInput = librarySpan.rangeInput ?? chart.rangeInput;
  const chartIntervalInput = librarySpan.intervalInput ?? chart.intervalInput;
  const { data, isLoading } = useVehicleTimeSeriesQuery(
    {
      id: pvin,
      fields: fields.map(({ id, aggregateType }) => ({
        id,
        aggregateType,
      })),
      independentVarInterval: chartIntervalInput,
      independentVar,
      filter: globalFilter,
      withComparison,
      comparisonFields: comparisonFields,
      independentVarRange: chartRangeInput,
      allTimeSeriesFieldIds: fields.map(({ id }) => id),
      timestampEpoch,
    },
    {
      staleTime: QUERY_SETTINGS.LONG_STALE_TIME,
      enabled: !!fields.length,
    }
  );

  // Connect chart with others via GROUP_NAME
  // WARNING: Must use the chart instance passed in from the function itself
  // a ref to the instance will work locally butdoes not work in DEV!
  const libraryChartReadyHandler = (c: EChartsInstance) => {
    if (c) {
      c.group = GROUP_NAME;
      connect(GROUP_NAME);
    }
  };

  let comparisonSeries: CustomChartSeries[] = buildComparisonTimeSeries(
    chart.series.filter((s) => s.comparisonType),
    data?.filteredQuery.vehicleCohortTimeSeries
  );

  const xAxisValues = buildTimeSeriesXAxisValues(
    data?.vehicleTimeSeries.x ?? [],
    data?.filteredQuery?.vehicleCohortTimeSeries?.x ?? []
  );

  const populatedChart: LibraryChart = {
    ...chart,
    axisData: {
      ...chart.axisData,
      data: xAxisValues,
      id: independentVar,
    },
    series: [
      ...chart.series.map((s, i) => {
        let unit = s.unit;

        const detectorForTimeSeries = data?.anomalyDetectorsForTimeSeriesFieldIds[i];

        return {
          ...s,
          data: data?.vehicleTimeSeries.y[i] || [],
          xValues: data?.vehicleTimeSeries.x ?? [],
          anomalyThreshold: detectorForTimeSeries
            ? buildChartThresholds({
                warningThreshold: detectorForTimeSeries.warningThreshold,
                cautionThreshold: detectorForTimeSeries.cautionThreshold ?? undefined,
              })
            : undefined,
          unit,
        };
      }),
      ...comparisonSeries,
    ] // Set comparison series next to main ones
      .sort(chartSeriesSorter),
  };

  const chartOptions = buildLibraryDefaultChartConfig({
    chart: populatedChart,
    faults: false,
    repairs: [],
    indVar: independentVar,
    indInterval: data?.vehicleTimeSeries.independentVarInterval,
    acquireColor: coloringPoolCtx === null ? (_: string) => "#000" : coloringPoolCtx.acquireColor,
    preview: true,
  });

  return (
    <BaseEChart
      {...chartOptions}
      customEmptyComponent={
        <EmptyComponentSCV description={getNoTelemetryMessage(!!chartRangeInput && !!chartIntervalInput)} />
      }
      className="library-preview-chart"
      ref={echartRef as RefObject<EChartsReact>}
      onChartReady={libraryChartReadyHandler}
      showLoading={isLoading && !!fields.length}
      notMerge={true}
    />
  );
};

export default LibraryChartPreviewItem;
