import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren } from "react";

export const PullPostHogProvider = ({ children }: PropsWithChildren<{}>) => {
  const posthogEnabled = !!import.meta.env.VITE_POSTHOG_ENABLED;
  if (posthogEnabled) {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY ?? "", {
      api_host: import.meta.env.VITE_POSTHOG_HOST ?? "",
      disable_session_recording: true,
      enable_heatmaps: false,
    });
  }
  return (
    <>
      {posthogEnabled && <PostHogProvider client={posthog}>{children}</PostHogProvider>}
      {!posthogEnabled && children}
    </>
  );
};
