import styled from "styled-components";

type CustomProps = {
  noTelemetryAvailable?: boolean;
};
export const AnomalyDetectorPreviewCardWrapper = styled.div<CustomProps>`
  cursor: ${({ noTelemetryAvailable }) => (noTelemetryAvailable ? "not-allowed" : "pointer")};
  height: 185px;
  border-radius: 0 12px 12px 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.oddRow};
  .ant-card-head {
    padding: 0px;
  }
  .ant-card-head-title {
    padding: 0px;
    border-bottom: 1px solid #dedede;
  }
  .ant-card-body {
    background: #f9fafe;
  }
  .anomaly-preview-tooltip-circle {
    height: 9px;
    width: 9px;
    background: blue;
  }
`;
