import { useContext } from "react";

import { FeatureId } from "@/api";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";
import { useTelematicsProtectedVehicleTemperatureStatsQuery } from "@/hooks/telematicsProtectedHooks/useTelematicsProtectedVehicleAmbientTemperatureBoxPlotQuery";
import { useFeatureFlags } from "@/utils/features";
import unitLabel from "@/utils/timeSeriesUnits";

import { BaseEChart } from "../ui/BaseEChart";
import { BasicWidget } from "../widget/BasicWidget";
import { createBoxPlotOptions } from "./BoxPlotHelpers";

export const VehicleAmbientTemperatureBoxPlot = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const telematicsDisabled = !isFeatureEnabled(FeatureId.Telemetry);

  const { globalFilter } = useContext(GlobalFilterContext);
  const { pvin } = useContext(SCVContext);
  const { data, isLoading } = useTelematicsProtectedVehicleTemperatureStatsQuery({
    vehicleId: pvin,
    filter: globalFilter,
  });

  return (
    <BasicWidget title="Ambient Temperature vs. Fleet" muted={telematicsDisabled}>
      <BaseEChart
        showLoading={isLoading}
        option={createBoxPlotOptions(data?.filteredQuery.vehicleTemperatureStats, isLoading, unitLabel("DEGC"))}
      />
    </BasicWidget>
  );
};
