import { toResourceLabelKey } from "@lib/src/resourceLabelKey";
import { useContext, useEffect, useId, useState } from "react";

import { CalculatedStatus, useCustomerMlDataSampleVehiclesQuery } from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import HelperTooltipWithIcon from "@/components/ui/HelperTooltipWithIcon";
import InlineLoadingSkeleton from "@/components/ui/InlineLoadingSkeleton";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { intitialDataAttributeName } from "@/hooks/usePortalTargetObserver";
import { toArray } from "@/utils/arrayUtils";
import { EchartsTooltipFormatterFn } from "@/utils/types/EChartsDefinitions";

import {
  AxisOption,
  AxisSelector,
  buildGenericScatterChartConfig,
  buildScatterSeries,
} from "./AnomalyMultiAxisVehicleAnalysisUtils";
import AnomalyTooltipVehicleDetail, { VehiclePointBaseData } from "./AnomalyTooltipVehicleDetail";
import { initialTooltipDataElementId } from "./AnomalyVehicleAnalysis";
import { AnalysisChartContainer, AnalysisTitleContainer } from "./AnomalyVehicleAnalysis.styled";
import { ScatterParam, unknownDatapointConverter } from "./ScatterChartUtils";

const tooltipSelector = ".multi-axis-scatter-tooltip-container";

type AnomalyMultiAxisVehicleAnalysisProps = {
  id: string;
};

const AnomalyMultiAxisVehicleAnalysis = ({ id }: AnomalyMultiAxisVehicleAnalysisProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const tooltipPortalTargetId = useId();

  const [xAxis, setXAxis] = useState("");
  const [yAxis, setYAxis] = useState("");

  // Sample 2500 vehicles for the scatterplot
  const { data, isLoading } = useCustomerMlDataSampleVehiclesQuery(
    {
      anomalyId: id,
      anomalyStatus: CalculatedStatus.Available,
      sampleSize: 2500,
      filter: globalFilter,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, keepPreviousData: false }
  );

  const vehicles = data?.filteredQuery.customerMlDataSampleVehicles.data ?? [];
  const columns = data?.filteredQuery.customerMlDataSampleVehicles.columns ?? [];
  const axisSelectorOptions = columns.map((c) => toResourceLabelKey(c) as AxisOption);
  const columnKeys = axisSelectorOptions.map((c) => c.key);

  useEffect(() => {
    // Syncs pre-selected axis if columnKeys has at least 1 element
    if (!isLoading && columnKeys.length && (!xAxis || !yAxis)) {
      setXAxis(columnKeys[0]);
      setYAxis(columnKeys[1] ?? columnKeys[0]);
    }
  }, [isLoading, xAxis, yAxis, columnKeys]);

  const onTooltip: EchartsTooltipFormatterFn = (params, _ticket, _callback) => {
    const renderParams = toArray(params);
    return renderParams
      .map((param) => {
        const currentParam = param as ScatterParam;
        const [x, y, maybeDataPoint] = currentParam.value;
        const dataPoint = unknownDatapointConverter(maybeDataPoint);

        if (!dataPoint) return "";

        const vehicleBaseData: VehiclePointBaseData = {
          x,
          y,
          dataPoint: { ...dataPoint, multiAxis: true },
        };

        return `<div class="tooltip-container">
          <div id="${initialTooltipDataElementId}" ${intitialDataAttributeName}=${JSON.stringify(
          vehicleBaseData
        )}></div>
          <div id=${tooltipPortalTargetId} class="portal-target"/>
        </div>
        `;
      })
      .join("\n");
  };

  const titleElement = (
    <AnalysisTitleContainer>
      <div className="widget-title">
        Multi-Axis Analysis
        <HelperTooltipWithIcon message="Use the filters to customize this scatter plot and compare individual anomaly cases." />
      </div>
      <div className="axis-selectors">
        {isLoading ? (
          <InlineLoadingSkeleton />
        ) : (
          <>
            <AxisSelector
              label="Y-Axis:"
              options={axisSelectorOptions}
              currentVariable={yAxis}
              onChange={(axis) => setYAxis(axis)}
            />
            <AxisSelector
              label="X-Axis:"
              options={axisSelectorOptions}
              currentVariable={xAxis}
              onChange={(axis) => setXAxis(axis)}
            />
          </>
        )}
      </div>
    </AnalysisTitleContainer>
  );

  const xAxisLabel = axisSelectorOptions.find((e) => e.key === xAxis)?.label;
  const yAxisLabel = axisSelectorOptions.find((e) => e.key === yAxis)?.label;

  const chartConfig = buildGenericScatterChartConfig({
    seriesData: buildScatterSeries(columnKeys.indexOf(xAxis), columnKeys.indexOf(yAxis), vehicles),
    xAxisLabel,
    yAxisLabel,
    onTooltip,
    tooltipSelector,
  });

  return (
    <BasicWidget title={titleElement}>
      <AnomalyTooltipVehicleDetail
        portalTargetId={tooltipPortalTargetId}
        tooltipSelector={tooltipSelector}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
      />
      <AnalysisChartContainer className="vehicle-detection-multiaxis-analysis-container">
        <BaseEChart {...chartConfig} showLoading={isLoading} />
      </AnalysisChartContainer>
    </BasicWidget>
  );
};

export default AnomalyMultiAxisVehicleAnalysis;
