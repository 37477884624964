import { useAuth0 } from "@auth0/auth0-react";
import posthog from "posthog-js";
import { useEffect } from "react";

/**
 * Hook to identify users in PostHog when they're authenticated
 * This should be used in components that require authentication
 *
 * Note: Device information is automatically captured by PostHog
 */
export const usePostHogIdentify = () => {
  const { user, isAuthenticated } = useAuth0();
  const posthogEnabled = import.meta.env.VITE_POSTHOG_ENABLED === "true";

  useEffect(() => {
    if (posthogEnabled && isAuthenticated && user) {
      // Identify the user in PostHog with their Auth0 ID and properties
      posthog.identify(user.sub, {
        email: user.email,
        name: user.name,
        firstName: user.given_name,
        lastName: user.family_name,
      });
    } else if (posthogEnabled && !isAuthenticated) {
      // Reset PostHog identity when user is not authenticated
      // This ensures proper tracking if users share a device
      posthog.reset();
    }
  }, [user, isAuthenticated, posthogEnabled]);
};
