import { DownOutlined } from "@ant-design/icons";
import { Button, Modal, Switch } from "antd";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";

import { AggregateType, useClustersForHdmComparisonQuery } from "@/api";
import {
  DiagnosticCheckType,
  HdmNormalizations,
  useDataMatrixTableHistoryQuery,
  useDefaultHdmComparisonOptionsQuery,
  useHdmComparisonOptionsQuery,
  useHdmComparisonTableQuery,
  useHdmTableQuery,
} from "@/api/customerApi";
import BlockingLoading from "@/components/ui/BlockLoading";
import DropdownWidget from "@/components/ui/DropdownWidget";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";
import {
  ComparisonDefinition,
  getHDMComparionsAttributesDisplayLabel,
  getHDMTableDefinitions,
  getHDMTableNormalizations,
  getNormalizationLabel,
} from "@/utils/historicDataMatrix/hdmUtils";
import { snakeCaseToTitleCase } from "@/utils/stringUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import DataMatrixChartVisualizations from "./DataMatrixChartVisualizations";
import { HDMChartHeader } from "./HDM.styled";
import { HDMComparisonContainer, HDMCoparisonWrapper } from "./HDMComparison.styled";
import HDMComparisonForm from "./HDMComparisonForm";
import { nonSpecificDefaultValues } from "./HDMCustomComparisonForm";
import { HDMHeatMapAndTitleContainer, HDMHeatMapTitleContainer } from "./HDMHeatMap.styled";
import { aggregateTypeFromDefaultAggregation, getHDMAllowedAggregateTypes } from "./HistoricDataMatrix";
import useDataMatrixRouteParams from "./useDataMatrixRouteParams";
import useDataMatrixState from "./useDataMatrixState";
import useHDMClusterSettings from "./useHDMClusterSettings";

type HDMComparisonProps = {
  dcType?: DiagnosticCheckType;
  batteryType?: number;
};

export const HDMComparison = ({ dcType, batteryType }: HDMComparisonProps) => {
  const { dms, selectedTableDefinition, setComparisonDefinition } = useDataMatrixState();
  const { getFeatureConfig } = useFeatureFlags();
  const { pvin, clusterId } = useDataMatrixRouteParams();
  const { customerIdentifier } = useCustomerIdentifier();
  const { setAggregateType, aggregateType } = useHDMClusterSettings();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [clusterNameSearch, setClusterNameSearch] = useState<string>();

  const [selectedNormalization, setSelectedNormalization] = useState<HdmNormalizations>(HdmNormalizations.Raw);
  const [comparisonMode, setComparisonMode] = useState(false);

  const availableNormalizations = getHDMTableNormalizations(selectedTableDefinition).map((an) => an.toString());
  const normalizationOptions = availableNormalizations.map((norm) => ({
    label: getNormalizationLabel(norm),
    key: norm,
  }));

  useEffect(() => {
    if (selectedNormalization !== HdmNormalizations.Raw && !availableNormalizations.includes(selectedNormalization)) {
      setSelectedNormalization(HdmNormalizations.Raw);
    }
  }, [availableNormalizations]);

  const { data: comparisonData, isLoading: comparisonLoading } = useHdmComparisonOptionsQuery(
    {},
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  const comparisonQueryEnabled = !!pvin || !!clusterId;

  const { data: defaultComparisonData, isLoading: isDefaultLoading } = useDefaultHdmComparisonOptionsQuery(
    {
      pvin,
      clusterId,
    },
    { enabled: comparisonQueryEnabled, staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const { data: clusters, isFetching: clustersLoading } = useClustersForHdmComparisonQuery(
    {
      //This argument is used in calclualting the number of vehicles in the cluster which is not needed here.
      filter: {},
      customerId: getCurrentCustomerId() ?? "",
      pagination: { pageSize: 100, currentPage: 1 },
      nameSearchText: clusterNameSearch,
    },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  const historyQueryEnabled = !!dms?.tableId && !!pvin && !clusterId && !!aggregateType;

  // This query prevents useHdmTableQuery from fetching with oudated ids when "remembering" last matrix selected
  const tableAggregateType = aggregateTypeFromDefaultAggregation(
    getHDMTableDefinitions(customerIdentifier, getFeatureConfig, dms?.tableId)?.[0].default_table_aggregation ?? ""
  );

  const {
    data,
    isLoading: matrixHistoryLoading,
    isFetching: matrixHistoryFetching,
  } = useDataMatrixTableHistoryQuery(
    {
      pvin: pvin,
      clusterId: clusterId,
      diagnosticCheckType: dcType,
      aggregation: tableAggregateType,
      tableId: dms?.tableId ?? "",
    },
    { enabled: historyQueryEnabled, staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const isLoading = matrixHistoryLoading || matrixHistoryFetching;

  const matrixSelectionReady = dms?.selectedMatrix?.id
    ? (data?.aggregateHDMTableValues.map((t) => t.id) ?? []).includes(dms.selectedMatrix.id)
    : false;

  const tableQueryEnabled = !!dms?.tableId && ((matrixSelectionReady && !isLoading) || !!clusterId);

  const toRequestDataMatrixId = clusterId ? undefined : dms?.selectedMatrix?.id;

  const { data: tableData, isLoading: tableIsLoading } = useHdmTableQuery(
    {
      // SCV will only have 1 data matrix table selected. If viewing in Cluster view this argument is ignored.
      id: toRequestDataMatrixId,
      clusterId: clusterId,
      diagnosticCheckType: dcType,
      batteryModuleCount: batteryType ?? undefined,
      tableId: dms?.tableId ?? "",
      normalization: selectedNormalization,
      aggregation: aggregateType,
    },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
      enabled: tableQueryEnabled,
    }
  );

  const {
    data: comparisonTableData,
    isFetching: comparisonTableIsFetching,
    isLoading: comparisonTableIsLoading,
  } = useHdmComparisonTableQuery(
    {
      ...dms?.comparisonDefinition,
      tableId: dms?.tableId ?? "",
      normalization: selectedNormalization,
      excludePvin: pvin,
      excludeClusterId: clusterId,
    },
    {
      enabled:
        !!dms?.tableId &&
        (!!dms?.comparisonDefinition?.clusterComparison || !!dms?.comparisonDefinition?.customComparison),
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
    }
  );

  useEffect(() => {
    if (!comparisonTableIsFetching && !comparisonTableData?.hdmComparisonTable?.values.length) {
      setComparisonMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonTableData]);

  useEffect(() => {
    if (
      defaultComparisonData?.defaultHDMComparisonOptions &&
      !dms?.comparisonDefinition?.customComparison &&
      !dms?.comparisonDefinition?.clusterComparison
    ) {
      const defaultVals = defaultComparisonData.defaultHDMComparisonOptions;
      setComparisonDefinition(
        {
          customComparison: {
            ...nonSpecificDefaultValues,
            batterySize: defaultVals.moduleCount[0],
            model: defaultVals.model[0],
            modelYear: defaultVals.modelYear[0],
            deliveryCountry: defaultVals.deliveryCountry[0],
          },
          clusterComparison: undefined,
        },
        true
      );
    }
  }, [defaultComparisonData]);

  useEffect(() => {
    const agg =
      dms?.comparisonDefinition?.clusterComparison?.aggregation ||
      dms?.comparisonDefinition?.customComparison?.aggregation;
    if (agg) {
      setAggregateType(agg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dms?.comparisonDefinition]);

  const handleComparisonSettingsChange = (settings: ComparisonDefinition) => {
    setComparisonDefinition({ ...settings });
    setSettingsOpen(false);
  };

  const handleAggregationChange = (agg: AggregateType) => {
    if (dms?.comparisonDefinition?.clusterComparison) {
      setComparisonDefinition({
        customComparison: undefined,
        clusterComparison: { ...dms?.comparisonDefinition?.clusterComparison, aggregation: agg },
      });
    } else if (dms?.comparisonDefinition?.customComparison) {
      setComparisonDefinition({
        clusterComparison: undefined,
        customComparison: { ...dms?.comparisonDefinition?.customComparison, aggregation: agg },
      });
    }
    setAggregateType(agg);
  };

  const getHDMComparisonAttributeValue = (key: string, value: string) => {
    if (key === "model") {
      return toModelDescription({ modelStyle: value as string }, customerIdentifier.models);
    } else if (key === "clusterId") {
      return clusters?.clusters?.clusters.data.find((c) => c.id === value)?.name ?? "";
    } else if (key === "aggregation") {
      return Object.entries(getHDMAllowedAggregateTypes()).find(([_, v]) => v === value)?.[0] || "";
    }
    return value;
  };

  const tableValues = tableData?.hdmTable?.values ?? [];
  const comparisonTableValues = comparisonTableData?.hdmComparisonTable?.values ?? [];

  // batteryType selection is not available for SCV
  const currentBatterySize = pvin ? defaultComparisonData?.defaultHDMComparisonOptions.moduleCount[0] : batteryType;
  // Get batterySize from custom comparison
  const comparisonBatterySize = dms?.comparisonDefinition?.clusterComparison
    ? dms.comparisonDefinition.clusterComparison.batterySize
    : dms?.comparisonDefinition?.customComparison?.batterySize;

  const heatmapTitle = selectedTableDefinition?.name;
  const heatmapSubtitle = clusterId
    ? `${dcType} FAP of Workshop Visit, Battery type: ${batteryType}`
    : dms?.selectedMatrix?.label ?? "";

  const comparisonVehicleCount = comparisonTableData?.hdmComparisonTable?.vehicles;
  const comparisonTableCount = comparisonTableData?.hdmComparisonTable?.tables;

  const comparisonTitleSuffix = comparisonTableIsLoading
    ? ""
    : ` (${comparisonVehicleCount} vehicles, ${comparisonTableCount} HDM tables)`;

  const comparisonTitle = (
    <>
      Comparison Set
      {comparisonTitleSuffix ? comparisonTitleSuffix : <BlockingLoading className="inline" />}
    </>
  );

  const titleElement = (
    <HDMChartHeader>
      <div>
        <div className="widget-title">HDM Table Comparison</div>
        <span className="sub-title">Visualize HDM Tables and compare to other vehicles{"'"} readings</span>
      </div>
      <div className="hdm-chart-header-buttons">
        {clusterId && (
          <DropdownWidget
            elements={Object.entries(getHDMAllowedAggregateTypes(pvin)).map(([label, value]) => ({
              label: label,
              key: value,
            }))}
            onElementSelected={(agg) => handleAggregationChange(agg as AggregateType)}
            label={`Aggregation: ${capitalize(aggregateType)}`}
            buttonSize="small"
            icon={<DownOutlined />}
            preselectedKey={aggregateType}
          />
        )}
        <DropdownWidget
          elements={normalizationOptions}
          onElementSelected={(element) => setSelectedNormalization(element as HdmNormalizations)}
          label={`Values: ${snakeCaseToTitleCase(selectedNormalization)}`}
          buttonSize="small"
          icon={<DownOutlined />}
          preselectedKey={selectedNormalization}
        />
        <Button
          type="primary"
          className="define-comparison-button"
          onClick={() => setSettingsOpen(true)}
          loading={comparisonLoading || isDefaultLoading}
          disabled={comparisonLoading || isDefaultLoading}
        >
          Define Comparison
        </Button>
      </div>
    </HDMChartHeader>
  );

  return (
    <BasicWidget title={titleElement} style={{ marginTop: "15px" }}>
      <HDMCoparisonWrapper>
        <HDMComparisonContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMapTitleContainer>
              <div className="selected-table-title-buttons">
                <h4>{heatmapTitle}</h4>
                <div>
                  <span className="sub-title">Comparison</span>
                  <Switch
                    value={comparisonMode}
                    onChange={() => setComparisonMode(!comparisonMode)}
                    loading={comparisonTableIsLoading}
                  />
                </div>
              </div>
              <span>{heatmapSubtitle}</span>
            </HDMHeatMapTitleContainer>
          </HDMHeatMapAndTitleContainer>
          <HDMHeatMapAndTitleContainer>
            <HDMHeatMapTitleContainer>
              <h4>{comparisonTitle}</h4>
              <span>
                {Object.entries(
                  dms?.comparisonDefinition?.customComparison ?? dms?.comparisonDefinition?.clusterComparison ?? {}
                )
                  .filter(([_k, v]) => !!v)
                  .map(
                    ([k, v]) =>
                      `${getHDMComparionsAttributesDisplayLabel(k)}:  ${getHDMComparisonAttributeValue(k, v as string)}`
                  )
                  .join(",  ")}
              </span>
            </HDMHeatMapTitleContainer>
          </HDMHeatMapAndTitleContainer>
        </HDMComparisonContainer>
        <DataMatrixChartVisualizations
          tableDefinition={selectedTableDefinition}
          comparisonMode={comparisonMode}
          tableValues={tableValues}
          comparisonValues={comparisonTableValues}
          tableIsLoading={tableIsLoading || isLoading}
          comparisonTableIsLoading={comparisonTableIsLoading || isDefaultLoading}
          currentNormalization={selectedNormalization}
          currentBatterySize={currentBatterySize}
          comparisonBatterySize={comparisonBatterySize}
        />
        {dms?.comparisonDefinition ? (
          <Modal open={settingsOpen} onCancel={() => setSettingsOpen(false)} footer={false}>
            <HDMComparisonForm
              comparisonOptions={comparisonData?.hdmComparisonOptions}
              onChange={handleComparisonSettingsChange}
              defaultValues={defaultComparisonData?.defaultHDMComparisonOptions}
              clusters={clusters?.clusters?.clusters.data}
              clusterNameSearch={clusterNameSearch}
              setClusterNameSearch={setClusterNameSearch}
              clustersLoading={clustersLoading}
              comparisonDefinition={dms.comparisonDefinition}
            />
          </Modal>
        ) : undefined}
      </HDMCoparisonWrapper>
    </BasicWidget>
  );
};
