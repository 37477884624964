/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as HealthCheckImport } from './routes/health-check'
import { Route as EventAnalysisImport } from './routes/event-analysis'
import { Route as ErrorImport } from './routes/error'
import { Route as CustomerApiImport } from './routes/customer-api'
import { Route as ApiImport } from './routes/api'
import { Route as AdminApiImport } from './routes/admin-api'
import { Route as IndexImport } from './routes/index'
import { Route as VehicleIndexImport } from './routes/vehicle.index'
import { Route as RepairEventsIndexImport } from './routes/repair-events.index'
import { Route as RepairConceptIndexImport } from './routes/repair-concept.index'
import { Route as PatternIndexImport } from './routes/pattern.index'
import { Route as ForecastIndexImport } from './routes/forecast.index'
import { Route as ClusterIndexImport } from './routes/cluster.index'
import { Route as AnomalyDetectorIndexImport } from './routes/anomaly-detector.index'
import { Route as VehiclePvinImport } from './routes/vehicle.$pvin'
import { Route as RepairPartialImport } from './routes/repair.partial'
import { Route as RepairFullImport } from './routes/repair.full'
import { Route as RepairConceptNewImport } from './routes/repair-concept.new'
import { Route as RepairConceptRepairConceptIdImport } from './routes/repair-concept.$repairConceptId'
import { Route as PatternNewImport } from './routes/pattern.new'
import { Route as PatternPatternIdImport } from './routes/pattern.$patternId'
import { Route as PatternEventPatternEventIdImport } from './routes/pattern-event.$patternEventId'
import { Route as ForecastNewImport } from './routes/forecast.new'
import { Route as ForecastForecastIdImport } from './routes/forecast.$forecastId'
import { Route as FaultFaultCodeIdImport } from './routes/fault.$faultCodeId'
import { Route as ClusterClusterIdImport } from './routes/cluster.$clusterId'
import { Route as AnomalyDetectorNewImport } from './routes/anomaly-detector.new'
import { Route as AnomalyDetectorDetectorIdImport } from './routes/anomaly-detector.$detectorId'
import { Route as AnomalyDetectorDetectorIdEditImport } from './routes/anomaly-detector_.$detectorId.edit'

// Create/Update Routes

const HealthCheckRoute = HealthCheckImport.update({
  id: '/health-check',
  path: '/health-check',
  getParentRoute: () => rootRoute,
} as any)

const EventAnalysisRoute = EventAnalysisImport.update({
  id: '/event-analysis',
  path: '/event-analysis',
  getParentRoute: () => rootRoute,
} as any)

const ErrorRoute = ErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => rootRoute,
} as any)

const CustomerApiRoute = CustomerApiImport.update({
  id: '/customer-api',
  path: '/customer-api',
  getParentRoute: () => rootRoute,
} as any)

const ApiRoute = ApiImport.update({
  id: '/api',
  path: '/api',
  getParentRoute: () => rootRoute,
} as any)

const AdminApiRoute = AdminApiImport.update({
  id: '/admin-api',
  path: '/admin-api',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const VehicleIndexRoute = VehicleIndexImport.update({
  id: '/vehicle/',
  path: '/vehicle/',
  getParentRoute: () => rootRoute,
} as any)

const RepairEventsIndexRoute = RepairEventsIndexImport.update({
  id: '/repair-events/',
  path: '/repair-events/',
  getParentRoute: () => rootRoute,
} as any)

const RepairConceptIndexRoute = RepairConceptIndexImport.update({
  id: '/repair-concept/',
  path: '/repair-concept/',
  getParentRoute: () => rootRoute,
} as any)

const PatternIndexRoute = PatternIndexImport.update({
  id: '/pattern/',
  path: '/pattern/',
  getParentRoute: () => rootRoute,
} as any)

const ForecastIndexRoute = ForecastIndexImport.update({
  id: '/forecast/',
  path: '/forecast/',
  getParentRoute: () => rootRoute,
} as any)

const ClusterIndexRoute = ClusterIndexImport.update({
  id: '/cluster/',
  path: '/cluster/',
  getParentRoute: () => rootRoute,
} as any)

const AnomalyDetectorIndexRoute = AnomalyDetectorIndexImport.update({
  id: '/anomaly-detector/',
  path: '/anomaly-detector/',
  getParentRoute: () => rootRoute,
} as any)

const VehiclePvinRoute = VehiclePvinImport.update({
  id: '/vehicle/$pvin',
  path: '/vehicle/$pvin',
  getParentRoute: () => rootRoute,
} as any)

const RepairPartialRoute = RepairPartialImport.update({
  id: '/repair/partial',
  path: '/repair/partial',
  getParentRoute: () => rootRoute,
} as any)

const RepairFullRoute = RepairFullImport.update({
  id: '/repair/full',
  path: '/repair/full',
  getParentRoute: () => rootRoute,
} as any)

const RepairConceptNewRoute = RepairConceptNewImport.update({
  id: '/repair-concept/new',
  path: '/repair-concept/new',
  getParentRoute: () => rootRoute,
} as any)

const RepairConceptRepairConceptIdRoute =
  RepairConceptRepairConceptIdImport.update({
    id: '/repair-concept/$repairConceptId',
    path: '/repair-concept/$repairConceptId',
    getParentRoute: () => rootRoute,
  } as any)

const PatternNewRoute = PatternNewImport.update({
  id: '/pattern/new',
  path: '/pattern/new',
  getParentRoute: () => rootRoute,
} as any)

const PatternPatternIdRoute = PatternPatternIdImport.update({
  id: '/pattern/$patternId',
  path: '/pattern/$patternId',
  getParentRoute: () => rootRoute,
} as any)

const PatternEventPatternEventIdRoute = PatternEventPatternEventIdImport.update(
  {
    id: '/pattern-event/$patternEventId',
    path: '/pattern-event/$patternEventId',
    getParentRoute: () => rootRoute,
  } as any,
)

const ForecastNewRoute = ForecastNewImport.update({
  id: '/forecast/new',
  path: '/forecast/new',
  getParentRoute: () => rootRoute,
} as any)

const ForecastForecastIdRoute = ForecastForecastIdImport.update({
  id: '/forecast/$forecastId',
  path: '/forecast/$forecastId',
  getParentRoute: () => rootRoute,
} as any)

const FaultFaultCodeIdRoute = FaultFaultCodeIdImport.update({
  id: '/fault/$faultCodeId',
  path: '/fault/$faultCodeId',
  getParentRoute: () => rootRoute,
} as any)

const ClusterClusterIdRoute = ClusterClusterIdImport.update({
  id: '/cluster/$clusterId',
  path: '/cluster/$clusterId',
  getParentRoute: () => rootRoute,
} as any)

const AnomalyDetectorNewRoute = AnomalyDetectorNewImport.update({
  id: '/anomaly-detector/new',
  path: '/anomaly-detector/new',
  getParentRoute: () => rootRoute,
} as any)

const AnomalyDetectorDetectorIdRoute = AnomalyDetectorDetectorIdImport.update({
  id: '/anomaly-detector/$detectorId',
  path: '/anomaly-detector/$detectorId',
  getParentRoute: () => rootRoute,
} as any)

const AnomalyDetectorDetectorIdEditRoute =
  AnomalyDetectorDetectorIdEditImport.update({
    id: '/anomaly-detector_/$detectorId/edit',
    path: '/anomaly-detector/$detectorId/edit',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/admin-api': {
      id: '/admin-api'
      path: '/admin-api'
      fullPath: '/admin-api'
      preLoaderRoute: typeof AdminApiImport
      parentRoute: typeof rootRoute
    }
    '/api': {
      id: '/api'
      path: '/api'
      fullPath: '/api'
      preLoaderRoute: typeof ApiImport
      parentRoute: typeof rootRoute
    }
    '/customer-api': {
      id: '/customer-api'
      path: '/customer-api'
      fullPath: '/customer-api'
      preLoaderRoute: typeof CustomerApiImport
      parentRoute: typeof rootRoute
    }
    '/error': {
      id: '/error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorImport
      parentRoute: typeof rootRoute
    }
    '/event-analysis': {
      id: '/event-analysis'
      path: '/event-analysis'
      fullPath: '/event-analysis'
      preLoaderRoute: typeof EventAnalysisImport
      parentRoute: typeof rootRoute
    }
    '/health-check': {
      id: '/health-check'
      path: '/health-check'
      fullPath: '/health-check'
      preLoaderRoute: typeof HealthCheckImport
      parentRoute: typeof rootRoute
    }
    '/anomaly-detector/$detectorId': {
      id: '/anomaly-detector/$detectorId'
      path: '/anomaly-detector/$detectorId'
      fullPath: '/anomaly-detector/$detectorId'
      preLoaderRoute: typeof AnomalyDetectorDetectorIdImport
      parentRoute: typeof rootRoute
    }
    '/anomaly-detector/new': {
      id: '/anomaly-detector/new'
      path: '/anomaly-detector/new'
      fullPath: '/anomaly-detector/new'
      preLoaderRoute: typeof AnomalyDetectorNewImport
      parentRoute: typeof rootRoute
    }
    '/cluster/$clusterId': {
      id: '/cluster/$clusterId'
      path: '/cluster/$clusterId'
      fullPath: '/cluster/$clusterId'
      preLoaderRoute: typeof ClusterClusterIdImport
      parentRoute: typeof rootRoute
    }
    '/fault/$faultCodeId': {
      id: '/fault/$faultCodeId'
      path: '/fault/$faultCodeId'
      fullPath: '/fault/$faultCodeId'
      preLoaderRoute: typeof FaultFaultCodeIdImport
      parentRoute: typeof rootRoute
    }
    '/forecast/$forecastId': {
      id: '/forecast/$forecastId'
      path: '/forecast/$forecastId'
      fullPath: '/forecast/$forecastId'
      preLoaderRoute: typeof ForecastForecastIdImport
      parentRoute: typeof rootRoute
    }
    '/forecast/new': {
      id: '/forecast/new'
      path: '/forecast/new'
      fullPath: '/forecast/new'
      preLoaderRoute: typeof ForecastNewImport
      parentRoute: typeof rootRoute
    }
    '/pattern-event/$patternEventId': {
      id: '/pattern-event/$patternEventId'
      path: '/pattern-event/$patternEventId'
      fullPath: '/pattern-event/$patternEventId'
      preLoaderRoute: typeof PatternEventPatternEventIdImport
      parentRoute: typeof rootRoute
    }
    '/pattern/$patternId': {
      id: '/pattern/$patternId'
      path: '/pattern/$patternId'
      fullPath: '/pattern/$patternId'
      preLoaderRoute: typeof PatternPatternIdImport
      parentRoute: typeof rootRoute
    }
    '/pattern/new': {
      id: '/pattern/new'
      path: '/pattern/new'
      fullPath: '/pattern/new'
      preLoaderRoute: typeof PatternNewImport
      parentRoute: typeof rootRoute
    }
    '/repair-concept/$repairConceptId': {
      id: '/repair-concept/$repairConceptId'
      path: '/repair-concept/$repairConceptId'
      fullPath: '/repair-concept/$repairConceptId'
      preLoaderRoute: typeof RepairConceptRepairConceptIdImport
      parentRoute: typeof rootRoute
    }
    '/repair-concept/new': {
      id: '/repair-concept/new'
      path: '/repair-concept/new'
      fullPath: '/repair-concept/new'
      preLoaderRoute: typeof RepairConceptNewImport
      parentRoute: typeof rootRoute
    }
    '/repair/full': {
      id: '/repair/full'
      path: '/repair/full'
      fullPath: '/repair/full'
      preLoaderRoute: typeof RepairFullImport
      parentRoute: typeof rootRoute
    }
    '/repair/partial': {
      id: '/repair/partial'
      path: '/repair/partial'
      fullPath: '/repair/partial'
      preLoaderRoute: typeof RepairPartialImport
      parentRoute: typeof rootRoute
    }
    '/vehicle/$pvin': {
      id: '/vehicle/$pvin'
      path: '/vehicle/$pvin'
      fullPath: '/vehicle/$pvin'
      preLoaderRoute: typeof VehiclePvinImport
      parentRoute: typeof rootRoute
    }
    '/anomaly-detector/': {
      id: '/anomaly-detector/'
      path: '/anomaly-detector'
      fullPath: '/anomaly-detector'
      preLoaderRoute: typeof AnomalyDetectorIndexImport
      parentRoute: typeof rootRoute
    }
    '/cluster/': {
      id: '/cluster/'
      path: '/cluster'
      fullPath: '/cluster'
      preLoaderRoute: typeof ClusterIndexImport
      parentRoute: typeof rootRoute
    }
    '/forecast/': {
      id: '/forecast/'
      path: '/forecast'
      fullPath: '/forecast'
      preLoaderRoute: typeof ForecastIndexImport
      parentRoute: typeof rootRoute
    }
    '/pattern/': {
      id: '/pattern/'
      path: '/pattern'
      fullPath: '/pattern'
      preLoaderRoute: typeof PatternIndexImport
      parentRoute: typeof rootRoute
    }
    '/repair-concept/': {
      id: '/repair-concept/'
      path: '/repair-concept'
      fullPath: '/repair-concept'
      preLoaderRoute: typeof RepairConceptIndexImport
      parentRoute: typeof rootRoute
    }
    '/repair-events/': {
      id: '/repair-events/'
      path: '/repair-events'
      fullPath: '/repair-events'
      preLoaderRoute: typeof RepairEventsIndexImport
      parentRoute: typeof rootRoute
    }
    '/vehicle/': {
      id: '/vehicle/'
      path: '/vehicle'
      fullPath: '/vehicle'
      preLoaderRoute: typeof VehicleIndexImport
      parentRoute: typeof rootRoute
    }
    '/anomaly-detector_/$detectorId/edit': {
      id: '/anomaly-detector_/$detectorId/edit'
      path: '/anomaly-detector/$detectorId/edit'
      fullPath: '/anomaly-detector/$detectorId/edit'
      preLoaderRoute: typeof AnomalyDetectorDetectorIdEditImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/admin-api': typeof AdminApiRoute
  '/api': typeof ApiRoute
  '/customer-api': typeof CustomerApiRoute
  '/error': typeof ErrorRoute
  '/event-analysis': typeof EventAnalysisRoute
  '/health-check': typeof HealthCheckRoute
  '/anomaly-detector/$detectorId': typeof AnomalyDetectorDetectorIdRoute
  '/anomaly-detector/new': typeof AnomalyDetectorNewRoute
  '/cluster/$clusterId': typeof ClusterClusterIdRoute
  '/fault/$faultCodeId': typeof FaultFaultCodeIdRoute
  '/forecast/$forecastId': typeof ForecastForecastIdRoute
  '/forecast/new': typeof ForecastNewRoute
  '/pattern-event/$patternEventId': typeof PatternEventPatternEventIdRoute
  '/pattern/$patternId': typeof PatternPatternIdRoute
  '/pattern/new': typeof PatternNewRoute
  '/repair-concept/$repairConceptId': typeof RepairConceptRepairConceptIdRoute
  '/repair-concept/new': typeof RepairConceptNewRoute
  '/repair/full': typeof RepairFullRoute
  '/repair/partial': typeof RepairPartialRoute
  '/vehicle/$pvin': typeof VehiclePvinRoute
  '/anomaly-detector': typeof AnomalyDetectorIndexRoute
  '/cluster': typeof ClusterIndexRoute
  '/forecast': typeof ForecastIndexRoute
  '/pattern': typeof PatternIndexRoute
  '/repair-concept': typeof RepairConceptIndexRoute
  '/repair-events': typeof RepairEventsIndexRoute
  '/vehicle': typeof VehicleIndexRoute
  '/anomaly-detector/$detectorId/edit': typeof AnomalyDetectorDetectorIdEditRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/admin-api': typeof AdminApiRoute
  '/api': typeof ApiRoute
  '/customer-api': typeof CustomerApiRoute
  '/error': typeof ErrorRoute
  '/event-analysis': typeof EventAnalysisRoute
  '/health-check': typeof HealthCheckRoute
  '/anomaly-detector/$detectorId': typeof AnomalyDetectorDetectorIdRoute
  '/anomaly-detector/new': typeof AnomalyDetectorNewRoute
  '/cluster/$clusterId': typeof ClusterClusterIdRoute
  '/fault/$faultCodeId': typeof FaultFaultCodeIdRoute
  '/forecast/$forecastId': typeof ForecastForecastIdRoute
  '/forecast/new': typeof ForecastNewRoute
  '/pattern-event/$patternEventId': typeof PatternEventPatternEventIdRoute
  '/pattern/$patternId': typeof PatternPatternIdRoute
  '/pattern/new': typeof PatternNewRoute
  '/repair-concept/$repairConceptId': typeof RepairConceptRepairConceptIdRoute
  '/repair-concept/new': typeof RepairConceptNewRoute
  '/repair/full': typeof RepairFullRoute
  '/repair/partial': typeof RepairPartialRoute
  '/vehicle/$pvin': typeof VehiclePvinRoute
  '/anomaly-detector': typeof AnomalyDetectorIndexRoute
  '/cluster': typeof ClusterIndexRoute
  '/forecast': typeof ForecastIndexRoute
  '/pattern': typeof PatternIndexRoute
  '/repair-concept': typeof RepairConceptIndexRoute
  '/repair-events': typeof RepairEventsIndexRoute
  '/vehicle': typeof VehicleIndexRoute
  '/anomaly-detector/$detectorId/edit': typeof AnomalyDetectorDetectorIdEditRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/admin-api': typeof AdminApiRoute
  '/api': typeof ApiRoute
  '/customer-api': typeof CustomerApiRoute
  '/error': typeof ErrorRoute
  '/event-analysis': typeof EventAnalysisRoute
  '/health-check': typeof HealthCheckRoute
  '/anomaly-detector/$detectorId': typeof AnomalyDetectorDetectorIdRoute
  '/anomaly-detector/new': typeof AnomalyDetectorNewRoute
  '/cluster/$clusterId': typeof ClusterClusterIdRoute
  '/fault/$faultCodeId': typeof FaultFaultCodeIdRoute
  '/forecast/$forecastId': typeof ForecastForecastIdRoute
  '/forecast/new': typeof ForecastNewRoute
  '/pattern-event/$patternEventId': typeof PatternEventPatternEventIdRoute
  '/pattern/$patternId': typeof PatternPatternIdRoute
  '/pattern/new': typeof PatternNewRoute
  '/repair-concept/$repairConceptId': typeof RepairConceptRepairConceptIdRoute
  '/repair-concept/new': typeof RepairConceptNewRoute
  '/repair/full': typeof RepairFullRoute
  '/repair/partial': typeof RepairPartialRoute
  '/vehicle/$pvin': typeof VehiclePvinRoute
  '/anomaly-detector/': typeof AnomalyDetectorIndexRoute
  '/cluster/': typeof ClusterIndexRoute
  '/forecast/': typeof ForecastIndexRoute
  '/pattern/': typeof PatternIndexRoute
  '/repair-concept/': typeof RepairConceptIndexRoute
  '/repair-events/': typeof RepairEventsIndexRoute
  '/vehicle/': typeof VehicleIndexRoute
  '/anomaly-detector_/$detectorId/edit': typeof AnomalyDetectorDetectorIdEditRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/admin-api'
    | '/api'
    | '/customer-api'
    | '/error'
    | '/event-analysis'
    | '/health-check'
    | '/anomaly-detector/$detectorId'
    | '/anomaly-detector/new'
    | '/cluster/$clusterId'
    | '/fault/$faultCodeId'
    | '/forecast/$forecastId'
    | '/forecast/new'
    | '/pattern-event/$patternEventId'
    | '/pattern/$patternId'
    | '/pattern/new'
    | '/repair-concept/$repairConceptId'
    | '/repair-concept/new'
    | '/repair/full'
    | '/repair/partial'
    | '/vehicle/$pvin'
    | '/anomaly-detector'
    | '/cluster'
    | '/forecast'
    | '/pattern'
    | '/repair-concept'
    | '/repair-events'
    | '/vehicle'
    | '/anomaly-detector/$detectorId/edit'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/admin-api'
    | '/api'
    | '/customer-api'
    | '/error'
    | '/event-analysis'
    | '/health-check'
    | '/anomaly-detector/$detectorId'
    | '/anomaly-detector/new'
    | '/cluster/$clusterId'
    | '/fault/$faultCodeId'
    | '/forecast/$forecastId'
    | '/forecast/new'
    | '/pattern-event/$patternEventId'
    | '/pattern/$patternId'
    | '/pattern/new'
    | '/repair-concept/$repairConceptId'
    | '/repair-concept/new'
    | '/repair/full'
    | '/repair/partial'
    | '/vehicle/$pvin'
    | '/anomaly-detector'
    | '/cluster'
    | '/forecast'
    | '/pattern'
    | '/repair-concept'
    | '/repair-events'
    | '/vehicle'
    | '/anomaly-detector/$detectorId/edit'
  id:
    | '__root__'
    | '/'
    | '/admin-api'
    | '/api'
    | '/customer-api'
    | '/error'
    | '/event-analysis'
    | '/health-check'
    | '/anomaly-detector/$detectorId'
    | '/anomaly-detector/new'
    | '/cluster/$clusterId'
    | '/fault/$faultCodeId'
    | '/forecast/$forecastId'
    | '/forecast/new'
    | '/pattern-event/$patternEventId'
    | '/pattern/$patternId'
    | '/pattern/new'
    | '/repair-concept/$repairConceptId'
    | '/repair-concept/new'
    | '/repair/full'
    | '/repair/partial'
    | '/vehicle/$pvin'
    | '/anomaly-detector/'
    | '/cluster/'
    | '/forecast/'
    | '/pattern/'
    | '/repair-concept/'
    | '/repair-events/'
    | '/vehicle/'
    | '/anomaly-detector_/$detectorId/edit'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AdminApiRoute: typeof AdminApiRoute
  ApiRoute: typeof ApiRoute
  CustomerApiRoute: typeof CustomerApiRoute
  ErrorRoute: typeof ErrorRoute
  EventAnalysisRoute: typeof EventAnalysisRoute
  HealthCheckRoute: typeof HealthCheckRoute
  AnomalyDetectorDetectorIdRoute: typeof AnomalyDetectorDetectorIdRoute
  AnomalyDetectorNewRoute: typeof AnomalyDetectorNewRoute
  ClusterClusterIdRoute: typeof ClusterClusterIdRoute
  FaultFaultCodeIdRoute: typeof FaultFaultCodeIdRoute
  ForecastForecastIdRoute: typeof ForecastForecastIdRoute
  ForecastNewRoute: typeof ForecastNewRoute
  PatternEventPatternEventIdRoute: typeof PatternEventPatternEventIdRoute
  PatternPatternIdRoute: typeof PatternPatternIdRoute
  PatternNewRoute: typeof PatternNewRoute
  RepairConceptRepairConceptIdRoute: typeof RepairConceptRepairConceptIdRoute
  RepairConceptNewRoute: typeof RepairConceptNewRoute
  RepairFullRoute: typeof RepairFullRoute
  RepairPartialRoute: typeof RepairPartialRoute
  VehiclePvinRoute: typeof VehiclePvinRoute
  AnomalyDetectorIndexRoute: typeof AnomalyDetectorIndexRoute
  ClusterIndexRoute: typeof ClusterIndexRoute
  ForecastIndexRoute: typeof ForecastIndexRoute
  PatternIndexRoute: typeof PatternIndexRoute
  RepairConceptIndexRoute: typeof RepairConceptIndexRoute
  RepairEventsIndexRoute: typeof RepairEventsIndexRoute
  VehicleIndexRoute: typeof VehicleIndexRoute
  AnomalyDetectorDetectorIdEditRoute: typeof AnomalyDetectorDetectorIdEditRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminApiRoute: AdminApiRoute,
  ApiRoute: ApiRoute,
  CustomerApiRoute: CustomerApiRoute,
  ErrorRoute: ErrorRoute,
  EventAnalysisRoute: EventAnalysisRoute,
  HealthCheckRoute: HealthCheckRoute,
  AnomalyDetectorDetectorIdRoute: AnomalyDetectorDetectorIdRoute,
  AnomalyDetectorNewRoute: AnomalyDetectorNewRoute,
  ClusterClusterIdRoute: ClusterClusterIdRoute,
  FaultFaultCodeIdRoute: FaultFaultCodeIdRoute,
  ForecastForecastIdRoute: ForecastForecastIdRoute,
  ForecastNewRoute: ForecastNewRoute,
  PatternEventPatternEventIdRoute: PatternEventPatternEventIdRoute,
  PatternPatternIdRoute: PatternPatternIdRoute,
  PatternNewRoute: PatternNewRoute,
  RepairConceptRepairConceptIdRoute: RepairConceptRepairConceptIdRoute,
  RepairConceptNewRoute: RepairConceptNewRoute,
  RepairFullRoute: RepairFullRoute,
  RepairPartialRoute: RepairPartialRoute,
  VehiclePvinRoute: VehiclePvinRoute,
  AnomalyDetectorIndexRoute: AnomalyDetectorIndexRoute,
  ClusterIndexRoute: ClusterIndexRoute,
  ForecastIndexRoute: ForecastIndexRoute,
  PatternIndexRoute: PatternIndexRoute,
  RepairConceptIndexRoute: RepairConceptIndexRoute,
  RepairEventsIndexRoute: RepairEventsIndexRoute,
  VehicleIndexRoute: VehicleIndexRoute,
  AnomalyDetectorDetectorIdEditRoute: AnomalyDetectorDetectorIdEditRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/admin-api",
        "/api",
        "/customer-api",
        "/error",
        "/event-analysis",
        "/health-check",
        "/anomaly-detector/$detectorId",
        "/anomaly-detector/new",
        "/cluster/$clusterId",
        "/fault/$faultCodeId",
        "/forecast/$forecastId",
        "/forecast/new",
        "/pattern-event/$patternEventId",
        "/pattern/$patternId",
        "/pattern/new",
        "/repair-concept/$repairConceptId",
        "/repair-concept/new",
        "/repair/full",
        "/repair/partial",
        "/vehicle/$pvin",
        "/anomaly-detector/",
        "/cluster/",
        "/forecast/",
        "/pattern/",
        "/repair-concept/",
        "/repair-events/",
        "/vehicle/",
        "/anomaly-detector_/$detectorId/edit"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/admin-api": {
      "filePath": "admin-api.tsx"
    },
    "/api": {
      "filePath": "api.tsx"
    },
    "/customer-api": {
      "filePath": "customer-api.tsx"
    },
    "/error": {
      "filePath": "error.tsx"
    },
    "/event-analysis": {
      "filePath": "event-analysis.tsx"
    },
    "/health-check": {
      "filePath": "health-check.tsx"
    },
    "/anomaly-detector/$detectorId": {
      "filePath": "anomaly-detector.$detectorId.tsx"
    },
    "/anomaly-detector/new": {
      "filePath": "anomaly-detector.new.tsx"
    },
    "/cluster/$clusterId": {
      "filePath": "cluster.$clusterId.tsx"
    },
    "/fault/$faultCodeId": {
      "filePath": "fault.$faultCodeId.tsx"
    },
    "/forecast/$forecastId": {
      "filePath": "forecast.$forecastId.tsx"
    },
    "/forecast/new": {
      "filePath": "forecast.new.tsx"
    },
    "/pattern-event/$patternEventId": {
      "filePath": "pattern-event.$patternEventId.tsx"
    },
    "/pattern/$patternId": {
      "filePath": "pattern.$patternId.tsx"
    },
    "/pattern/new": {
      "filePath": "pattern.new.tsx"
    },
    "/repair-concept/$repairConceptId": {
      "filePath": "repair-concept.$repairConceptId.tsx"
    },
    "/repair-concept/new": {
      "filePath": "repair-concept.new.tsx"
    },
    "/repair/full": {
      "filePath": "repair.full.tsx"
    },
    "/repair/partial": {
      "filePath": "repair.partial.tsx"
    },
    "/vehicle/$pvin": {
      "filePath": "vehicle.$pvin.tsx"
    },
    "/anomaly-detector/": {
      "filePath": "anomaly-detector.index.tsx"
    },
    "/cluster/": {
      "filePath": "cluster.index.tsx"
    },
    "/forecast/": {
      "filePath": "forecast.index.tsx"
    },
    "/pattern/": {
      "filePath": "pattern.index.tsx"
    },
    "/repair-concept/": {
      "filePath": "repair-concept.index.tsx"
    },
    "/repair-events/": {
      "filePath": "repair-events.index.tsx"
    },
    "/vehicle/": {
      "filePath": "vehicle.index.tsx"
    },
    "/anomaly-detector_/$detectorId/edit": {
      "filePath": "anomaly-detector_.$detectorId.edit.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
