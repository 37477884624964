export function getSignificantNumber(number: number | null, precision: number = 3): number | null {
  if (number === null || number === undefined) return null;
  if (Number.isInteger(number)) {
    // If the value is an integer, return it as-is
    return number;
  }
  if ((number > 0 && number < 1) || (number < 0 && number > -1)) {
    // If the value is a decimal between 0 and 1 or -1 and 0
    // then we need to grab the number of digits after the decimal
    // to preserve the leading 0
    // e.g. 0.0001234 -> 0.00123, -0.0001234 -> -0.00123, 0.1234 -> 0.123
    const numStr = number.toString();
    let index = numStr.indexOf(".") + 1;
    while (numStr[index] === "0") {
      index++;
    }
    // round the value instead of truncating it
    const dp_const = 10 ** (precision + index - (numStr.indexOf(".") + 1));
    return Math.round(number * dp_const) / dp_const;
  }
  // If the value is greater then 1 or -1 and is a decimal with more than 3 digits after the decimal
  // then we need to round it to 3 digits after the decimal
  // e.g. 123.456789 -> 123.457, -123.456789 -> -123.457
  // round the value instead of truncating it
  const dp_const = 10 ** precision;
  return Math.round(number * dp_const) / dp_const;
}
