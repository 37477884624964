import PercentageIcon from "@amayaIcons/operators/Percentage.svg?react";
import {
  AggregationExpression,
  AggregationType,
  Expression,
  PercentileAggregationExpression,
} from "@lib/src/expression/types";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { isEmpty, isEqual } from "lodash";
import { KeyboardEvent, MouseEvent, useContext, useEffect, useRef, useState } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { StyledBorderlessInput, StyledBorderlessTypeahead } from "../TypeaheadInput.styled";
import { filterOptions, floatRegex, getAggregationIntervalTypeOptions } from "../TypeaheadUtils";
import NodeWrapper from "./NodeWrapper";
import { PatternFocusedNode } from "./PatternFocusedNode";
import PatternNode from "./PatternNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternAggregationNodeProps = {
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  path: string[];
  node: AggregationExpression;
  disabled: boolean;
};

const checkIsNodeEmpty = (node: PartialDeep<AggregationExpression>) => {
  if (
    !node.intervalType &&
    !node.intervalDuration &&
    !(node as PercentileAggregationExpression).percentile &&
    isEmpty((node.values ?? [{}])[0])
  ) {
    return true;
  }
  return false;
};

export const PatternAggregationNode = ({ node, onChange, path, disabled }: PatternAggregationNodeProps) => {
  const { setMostRecentPath, mostRecentPath } = useContext(PatternFocusContext);

  const handleDelete = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Backspace" && !(e?.target as HTMLInputElement).value && checkIsNodeEmpty(node)) {
      onChange({}, path);
    }
  };

  const areAggregationValuesInvalid = (valueIndex?: number) => {
    const isMostRecentPathInFamily =
      isEqual(mostRecentPath, [...path, "values", "0"]) ||
      isEqual(mostRecentPath, [...path, "percentile"]) ||
      isEqual(mostRecentPath, [...path, "duration"]) ||
      isEqual(mostRecentPath, [...path, "type"]) ||
      isEqual(mostRecentPath, path);
    if (isMostRecentPathInFamily) {
      return;
    } else if (valueIndex !== undefined) {
      return isEmpty(node.values[valueIndex]) ? "error" : undefined;
    } else {
      return isEmpty(node.intervalType) ? "error" : undefined;
    }
  };
  return (
    <PatternFocusedNode node={node} path={path} onChange={onChange} disabled={disabled}>
      <PatternNodeContainer>
        <div className="inline-node-section">
          <span className="fn-name-label">{`${node.aggregationType?.toUpperCase()} `}</span>
          <NodeWrapper>
            {node.aggregationType === AggregationType.Percentile && (
              <>
                <AggregationInput
                  node={node}
                  placeholder="Type percentile"
                  type={"percentile"}
                  onChange={onChange}
                  path={path}
                  value={node?.percentile}
                  autoFocus={isEqual(mostRecentPath, [...path, "percentile"])}
                  disabled={disabled}
                  setMostRecentPath={setMostRecentPath}
                />
                <div className="centered-container">
                  <span className="operator-symbol">
                    <PercentageIcon />
                  </span>
                  <span className="fn-name-label">{"OF"}</span>
                </div>
              </>
            )}
            <PatternNode
              onNodeChange={onChange}
              path={[...path, "values", "0"]}
              autoFocus={isEqual([...path, "values", "0"], mostRecentPath)}
              node={node.values[0]}
              deleteFunction={handleDelete}
              disabled={disabled}
              status={areAggregationValuesInvalid(0)}
            />
            <span style={{ marginLeft: "10px" }} className="fn-name-label">
              {"OVER"}
            </span>
            <AggregationInput
              node={node}
              placeholder="Type interval duration"
              type={"duration"}
              onChange={onChange}
              path={path}
              value={node?.intervalDuration}
              autoFocus={isEqual(mostRecentPath, [...path, "duration"])}
              disabled={disabled}
              setMostRecentPath={setMostRecentPath}
            />
            <AggregationDropDown
              node={node}
              placeholder="Type interval type"
              type={"type"}
              onChange={onChange}
              path={path}
              value={node.intervalType}
              setMostRecentPath={setMostRecentPath}
              autoFocus={isEqual(mostRecentPath, [...path, "type"])}
              disabled={disabled}
              status={areAggregationValuesInvalid()}
              mostRecentPath={mostRecentPath}
            />
          </NodeWrapper>
        </div>
      </PatternNodeContainer>
    </PatternFocusedNode>
  );
};

type AggregationTypeHeadProps = {
  node: PartialDeep<AggregationExpression>;
  type: "percentile" | "duration" | "type";
  placeholder: string;
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  path: string[];
  value?: string | number;
  disabled: boolean;
  setMostRecentPath: (s: string[] | undefined) => void;
  autoFocus: boolean;
  status?: "warning" | "error";
  mostRecentPath?: string[];
};

const AggregationInput = ({
  node,
  placeholder,
  value,
  onChange,
  path,
  type,
  autoFocus,
  disabled,
  setMostRecentPath,
}: AggregationTypeHeadProps) => {
  const inputRef = useRef<any>();
  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  const handleChange = (value: string | number) => {
    if (typeof value === "string" && floatRegex.test(`${value}`)) {
      const newNode = { ...node };
      if (type === "duration") newNode.intervalDuration = Number(value);
      if (type === "percentile") (newNode as PercentileAggregationExpression).percentile = Number(value);
      onChange(newNode, path);
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return true;
    if (e.code === "Backspace" && !value && checkIsNodeEmpty(node)) {
      onChange({}, path);
    }
  };

  return (
    <StyledBorderlessInput
      className="aggregation-input"
      ref={inputRef}
      value={value ?? 0}
      placeholder={placeholder}
      onChange={(e) => handleChange(e.target.value)}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      onFocus={(e) => {
        e.stopPropagation();
        setMostRecentPath([...path, type]);
      }}
      autoFocus={autoFocus}
    />
  );
};

const AggregationDropDown = ({
  node,
  value,
  onChange,
  path,
  setMostRecentPath,
  autoFocus,
  disabled,
  status,
}: AggregationTypeHeadProps) => {
  const inputRef = useRef<any>();
  const [options, setOptions] = useState(getAggregationIntervalTypeOptions());

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [autoFocus]);

  const onSearch = (searchTerm: string) => {
    if (value) return;
    const filteredOptions = filterOptions(getAggregationIntervalTypeOptions(), searchTerm);
    setOptions(filteredOptions);
  };

  const handleSelect = (optionSelected: BaseOptionType | DefaultOptionType) => {
    onChange({ ...node, intervalType: optionSelected.value }, path);
    setMostRecentPath([...path]);
  };

  const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (e.code === "Backspace" && node.intervalType) {
      const newNode = { ...node };
      delete newNode["intervalType"];
      onChange({ ...newNode }, path);
    } else if (e.code === "Backspace" && checkIsNodeEmpty(node)) {
      onChange({}, path);
    }
  };

  const handleInputMouseDown = (e: MouseEvent, inputEl: any | undefined) => {
    e.stopPropagation();
    e.preventDefault();
    inputEl?.focus();
  };

  return (
    <StyledBorderlessTypeahead
      className="aggregation-dropdown"
      ref={inputRef}
      options={options}
      placeholder="Select..."
      onSearch={onSearch}
      onKeyDown={handleOnKeyDown}
      value={value}
      onSelect={(_valueSelected, optionSelected) => handleSelect(optionSelected)}
      optionRender={(opt) => <p className="body-small">{opt.label}</p>}
      dropdownStyle={{ width: "350px" }}
      disabled={disabled}
      onMouseDown={(e) => {
        handleInputMouseDown(e, inputRef.current);
      }}
      onFocus={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setMostRecentPath([...path, "type"]);
      }}
      onBlur={() => {
        setMostRecentPath(undefined);
      }}
      autoFocus={autoFocus}
      status={status}
    />
  );
};
