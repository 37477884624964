import EditIcon from "@amayaIcons/icon-edit.svg?react";
import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { StyledButton } from "@/components/ui/StyledButton";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { parseDateToStr } from "@/utils/dateUtils";

import {
  ClusterViewHeaderRight,
  ClusterViewHeaderWrapper,
  ClusterViewTitleWrapper,
  FieldDiv,
} from "./ClusterView.styled";

type ClusterViewHeaderHeader = {
  cluster: {
    name: string;
    description?: string | null;
    created?: string | null;
    creator?: { name: string; isMe: boolean } | null;
  };
  handleCopyCluster: () => void;
  handleEditCluster: () => void;
  isLoading: boolean;
};

export const ClusterViewHeader = ({
  cluster,
  handleCopyCluster,
  handleEditCluster,
  isLoading,
}: ClusterViewHeaderHeader) => {
  const className = isLoading ? "cluster-header-loading" : "";
  return (
    <ClusterViewHeaderWrapper className={className}>
      <BasicWidget
        style={{ borderBottom: "0px" }}
        title={
          <ClusterViewTitleWrapper>
            <h1 className="heading-normal header-event-label">
              <span>{cluster.name}</span>
            </h1>
            <ClusterViewHeaderRight>
              <FieldDiv>
                <p className="body-small stat-label">Author</p>
                <div className="body-medium dark">{cluster?.creator?.name || "-"}</div>
              </FieldDiv>
              {cluster.created ? (
                <FieldDiv>
                  <p className="body-small stat-label">Created</p>
                  <div className="body-medium dark">{parseDateToStr(new Date(cluster.created), false, true)}</div>
                </FieldDiv>
              ) : (
                <></>
              )}
              <FieldDiv>
                <Button icon={<CopyOutlined />} onClick={() => handleCopyCluster()}>
                  Copy Cluster
                </Button>
              </FieldDiv>
              <FieldDiv>
                <StyledButton
                  icon={<EditIcon />}
                  onClick={() => handleEditCluster()}
                  disabled={!cluster.creator?.isMe}
                  title={!cluster.creator?.isMe ? "You cannot edit this cluster" : undefined}
                >
                  Edit Cluster
                </StyledButton>
              </FieldDiv>
            </ClusterViewHeaderRight>
          </ClusterViewTitleWrapper>
        }
      >
        {cluster.description && <div style={{ padding: "1rem" }}>{cluster.description}</div>}
      </BasicWidget>
    </ClusterViewHeaderWrapper>
  );
};
