import i18next from "i18next";

import { DataMatrixSource, HdmTableAggregateValue, TimeSeriesIndependentVar } from "@/api/customerApi";
import { defaultTheme } from "@/styles/Global.styled";
import { parseDateToStr } from "@/utils/dateUtils";
import { getDataMatrixHash, SelectedDataMatrix } from "@/utils/historicDataMatrix/hdmUtils";
import { formatNumber } from "@/utils/numberUtils";
import { DataMatrixDefinition } from "@/utils/types/commonDataDictionary";

import { xAxisLabelFormatter } from "../chartElementLibrary/ChartLibraryBuildUtils";

export type DataMatrixHistoryLegend = {
  label: string;
  iconClassName?: string;
};

export const getDataMatrixHistoryLegends = (
  arr: HdmTableAggregateValue[],
  baseLegend: string,
  hasSelections: boolean
): DataMatrixHistoryLegend[] => {
  const baseLegends = Array.from(new Set(arr.map(({ source }) => source))).map((s) => ({
    label: `${baseLegend}${s === DataMatrixSource.OverTheAir ? " (OTA)" : ""}`,
    iconClassName: s === DataMatrixSource.OverTheAir ? "ota" : "",
  }));

  if (hasSelections) {
    return [
      {
        label: "Selected",
        iconClassName: "selected",
      },
      ...baseLegends,
    ];
  }
  return baseLegends;
};

export const getTableLegend = (def: DataMatrixDefinition | undefined) => {
  if (!def) return;
  const splitId = def.name.split(":");
  return splitId[1];
};

const getScatterSeriesColor = (s: DataMatrixSource): string => {
  if (s === DataMatrixSource.OverTheAir) {
    return defaultTheme.colors.navyBlue;
  }
  return defaultTheme.colors.cyanBlue;
};

type HdmHistoryConfigArgs = {
  xAxis: TimeSeriesIndependentVar;
  matrices: HdmTableAggregateValue[];
  zoom:
    | {
        min: number;
        max: number;
      }
    | undefined;
  selectedTableDefinition?: DataMatrixDefinition;
  selectedDataMatrix?: SelectedDataMatrix;
  showConnectingLine?: boolean;
};

const buildEChartsScatterSeriesData = (
  hdmTable: HdmTableAggregateValue,
  xAxis: TimeSeriesIndependentVar,
  selectedMatricesIds: string[]
) => {
  const matrixIsSelected = selectedMatricesIds.includes(hdmTable.id);
  const elementColor = matrixIsSelected ? defaultTheme.colors.success : getScatterSeriesColor(hdmTable.source);
  const isMultiSelection = selectedMatricesIds.length > 1;

  return {
    value: [
      xAxis === TimeSeriesIndependentVar.Time ? new Date(hdmTable.timestamp).getTime() : hdmTable.odometer,
      hdmTable.value,
      {
        id: hdmTable.id,
        label: `${i18next.t("columnTitle.DATAMATRIX_READING")}: ${parseDateToStr(new Date(hdmTable.timestamp), true)}`,
        diagnosticCheckId: hdmTable.diagnosticCheckId,
        tableHash: getDataMatrixHash(hdmTable),
        source: hdmTable.source,
      },
      hdmTable.timestamp,
      hdmTable.odometer,
    ],
    symbolSize: matrixIsSelected ? (isMultiSelection ? 12 : 15) : 10,
    itemStyle: {
      color: elementColor,
      opacity: 1,
      borderWidth: matrixIsSelected && !isMultiSelection ? 2 : 0,
      borderColor: defaultTheme.colors.defaultWhite,
    },
    // Necessary to filter selected elements from the rest
    amayaIsSelected: matrixIsSelected,
  };
};

const onTooltip = (param: Object, tableUnit: string, defaultAggregation: string) => {
  const currentParam = param as unknown as { data: { value: any[] } };

  const [_, y, dataMatrixElement, timestamp, odometer] = currentParam.data.value; // eslint-disable-line @typescript-eslint/no-unused-vars

  const odometerLabel = odometer ? `${formatNumber(odometer)} km` : "-";

  const localizedDiagnosticCheckLabel = i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID");
  const localizedOTALabel = i18next.t("columnTitle.OTA");

  const dmSource = dataMatrixElement.source satisfies DataMatrixSource;

  const dcSection =
    dmSource === DataMatrixSource.DiagnosticCheck
      ? `
  <div class="flex-container horizontal">
    <span>${localizedDiagnosticCheckLabel}: </span>
    <span style="max-width: 300px; display: inline-block; white-space: pre-wrap; word-wrap: break-word;">${dataMatrixElement.diagnosticCheckId}</span>
  </div>`
      : `
  <div class="flex-container horizontal">
    <span>${localizedOTALabel} (OTA)</span>
  </div>`;

  return `
    <div class="tooltip-body">
        <div class="flex-container vertical">
        <span className="title">
          <h3 className="heading-x-small">
            <b>Table ${defaultAggregation}: </b>
            <span>
              ${formatNumber(y)} ${tableUnit}
            </span>
          </h3>
        </span>
        <div style="border-bottom: 1px solid black;"></div>
        <div class="flex-container horizontal">
          <span>Timestamp: </span>
          <span>${parseDateToStr(new Date(timestamp), true)}</span>
        </div>
        <div class="flex-container horizontal">
            <span>Odometer: </span>
            <span>${odometerLabel}</span>
        </div>
        ${dcSection}
       </div>
    </div>
  `;
};
const getXVal = (val: string | number): number => {
  if (Number(val)) {
    return Number(val);
  }
  return new Date(val).getTime();
};

const getYMinAndMax = (
  data: HdmTableAggregateValue[],
  zoom: HdmHistoryConfigArgs["zoom"],
  xAxis: TimeSeriesIndependentVar
) => {
  let min: number | undefined = undefined;
  let max: number | undefined = undefined;
  data
    .filter((e) => !!e.odometer)
    .forEach((d) => {
      const key = xAxis === TimeSeriesIndependentVar.Time ? "timestamp" : "odometer";
      // Zero should not happen due to the filtering before the forEach
      const xValue = d[key] ? getXVal(d[key]) : 0;
      if (zoom && xValue >= zoom.min && xValue <= zoom.max) {
        if (!min || d.value <= min) min = d.value;
        if (!max || d.value >= max) max = d.value;
      }
    });
  return {
    yMin: min ? Math.floor(min * 0.9) : Math.floor(Math.min(0, ...data.map((v) => v.value)) * 0.9),
    yMax: max ? Math.ceil(max * 1.1) : Math.ceil(Math.max(...data.map((v) => v.value)) * 1.1),
  };
};

// takes the rough interval of yMax - yMin and computes a nice looking interval
// 1, 2, 5, 10, 100, 1000, etc...
function getNiceInterval(value: number) {
  const scale = Math.pow(10, Math.floor(Math.log10(value)));
  const fraction = value / scale;
  let roundedFraction;

  if (fraction <= 1.5) {
    roundedFraction = 1;
  } else if (fraction <= 3) {
    roundedFraction = 2;
  } else if (fraction <= 7) {
    roundedFraction = 5;
  } else {
    roundedFraction = 10;
  }
  return roundedFraction * scale;
}

export const getHDMHistoryChartConfig = ({
  xAxis,
  matrices,
  zoom,
  selectedDataMatrix,
  selectedTableDefinition: selectedTableDefintion,
  showConnectingLine,
}: HdmHistoryConfigArgs) => {
  const { yMin, yMax } = getYMinAndMax(matrices ?? [], zoom, xAxis);
  const selectedMatricesIds = selectedDataMatrix ? [selectedDataMatrix.id] : [];

  const elementsWithSelectionAndColors =
    matrices?.map((m) => buildEChartsScatterSeriesData(m, xAxis, selectedMatricesIds)) ?? [];

  return {
    animation: false,
    tooltip: {
      trigger: "item",
      className: "hdm-history-tooltip-container",
      formatter: (params: any) =>
        onTooltip(
          params,
          selectedTableDefintion?.value_unit ?? "",
          selectedTableDefintion?.default_table_aggregation ?? ""
        ),
    },
    // zoom tool is there but it's not visible
    toolbox: {
      orient: "vertical",
      itemSize: 20,
      top: -275,
      itemGap: 15,
      feature: {
        dataZoom: { iconStyle: { opacity: 0 } },
        restore: {},
      },
      show: true,
    },
    dataZoom: [
      {
        type: "inside",
        zoomOnMouseWheel: "shift",
        preventDefaultMouseMove: false,
        disabled: false,
        xAxisIndex: [0, 1],
        show: false,
      },
    ],
    grid: { containLabel: true, left: 10, right: 50, bottom: 0 },
    xAxis: {
      type: xAxis === TimeSeriesIndependentVar.Time ? "time" : "value",
      data: matrices?.map((hdmTable) =>
        xAxis === TimeSeriesIndependentVar.Time ? new Date(hdmTable.timestamp).getTime() : hdmTable.odometer
      ),
      min: zoom?.min,
      max: zoom?.max,
      axisLabel: {
        formatter: (value: any) =>
          xAxisLabelFormatter(
            value,
            xAxis === TimeSeriesIndependentVar.Time ? TimeSeriesIndependentVar.Time : TimeSeriesIndependentVar.Mileage,
            undefined
          ),
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      name: `${selectedTableDefintion?.value_unit ?? ""}`,
      min: function () {
        const range = yMax - yMin;
        const roughInterval = Math.ceil(range / 5);
        const niceInterval = getNiceInterval(roughInterval);
        if (niceInterval === 0) return yMin;
        return Math.floor(yMin / niceInterval) * niceInterval;
      },
      max: function () {
        const range = yMax - yMin;
        const roughInterval = Math.ceil(range / 5);
        const niceInterval = getNiceInterval(roughInterval);
        if (niceInterval === 0) return yMax;
        return Math.ceil(yMax / niceInterval) * niceInterval;
      },
      show: true,
      splitNumber: 5,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      // Selected elements series
      {
        type: "scatter",
        z: 5,
        data: elementsWithSelectionAndColors.filter((e) => e.amayaIsSelected),
        symbol: "roundRect",
      },
      // NOT selected elements series
      {
        type: "scatter",
        z: 3,
        data: elementsWithSelectionAndColors.filter((e) => !e.amayaIsSelected),
        emphasis: {
          itemStyle: {
            color: defaultTheme.colors.success,
          },
        },
        symbol: "roundRect",
      },
      // simple line conecting the scatter elements
      showConnectingLine
        ? {
            type: "line",
            z: 2,
            data: elementsWithSelectionAndColors.map((e) => e.value),
            lineStyle: {
              color: defaultTheme.colors.cyanBlue,
            },
            emphasis: {
              disabled: true,
            },
            symbol: "none",
          }
        : undefined,
    ].filter((s) => !!s),
  };
};
