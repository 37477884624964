import { Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { TimeSeriesFieldFamily, TimeSeriesIndependentVar } from "@/api";
import { AggregateType } from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import { StyledInfoCircleOutlined } from "@/components/form/input/FormItem.styled";
import { CohortComparisonPlotOption, selectIndependentVariable } from "@/contexts/ChartLibraryStore";
import { toArray } from "@/utils/arrayUtils";

import { describeAggregateType } from "./ChartLibraryBuildUtilsTooltips";
import { PlottedSignalData } from "./ChartLibraryTabs";
import {
  emptyStringAsUndef,
  getAggregateOptionValue,
  getAvailableComparisonOptions,
  getComparisonOptionValue,
  plotterOptionsDraft,
} from "./FieldPlotterHelper";
import { FieldPlotterContainer } from "./LibraryFieldPlotter.styled";
import useVehicleComparisonEpochData from "./useVehicleComparisonEpochData";

const cohortComparisontooltipMsg = (
  <>
    When &#39;Fleet Comparison&#39; is enabled the X-Axis will show a calendar date or odometer value. However for
    certain signals, a normalized calculation for days since delivery will be determined for more accurate comparison.
    <br />
    <br />
    Data for fleet comparison can also be downsampled in cases where the X-Axis range is too wide; however, for smaller
    intervals data will not be downsampled.
  </>
);
const availableSingleComparisons = getAvailableComparisonOptions();
const availableDualComparisons = getAvailableComparisonOptions(true);
const availableSingleComparisonValues = availableSingleComparisons.map((sc) => sc.value);
const availableDualComparisonValues = availableDualComparisons.map((dc) => dc.value);

interface LibraryFieldPlotterProps {
  fieldId: string;
  aggregateTypes: AggregateType[];
  plottedData?: PlottedSignalData;
  family?: TimeSeriesFieldFamily;
  unit?: string;
  comparisonGroup?: string;
  onPlotOptionsChange: (signalsData: PlottedSignalData[]) => void;
}

const LibraryFieldPlotter = ({
  fieldId,
  aggregateTypes,
  plottedData,
  family,
  unit,
  comparisonGroup,
  onPlotOptionsChange,
}: LibraryFieldPlotterProps) => {
  // This section will conditionally render fleet comparison selectors
  const { vehicleHasValidComparisonEpoch } = useVehicleComparisonEpochData();
  const { independentVariable: currentVariable } = useSelector(selectIndependentVariable);

  const shouldShowComparisonSelectors = !(
    currentVariable === TimeSeriesIndependentVar.Time && !vehicleHasValidComparisonEpoch
  );

  // state to re-render component after optionsDraft is updated
  const [, setDraftLastUpdated] = useState(new Date().getTime());

  const isPlotted = !!plottedData;

  // Simple storage for options draft
  const optionsDraft = plotterOptionsDraft.get(fieldId);

  // Aggregation is Last by default
  const selectedAggregation =
    plottedData?.fieldInput?.aggregateType || optionsDraft?.fieldInput?.aggregateType || aggregateTypes.find((a) => a);

  const selectedComparisons = plottedData?.comparison
    ? toArray(plottedData.comparison)
    : optionsDraft && optionsDraft?.comparison
    ? toArray(optionsDraft?.comparison)
    : undefined;

  // single comparison can be None (""), undefined does not allow to use the None option
  const selectedSingleComparison = selectedComparisons
    ? selectedComparisons.find((comparison) => availableSingleComparisonValues.includes(comparison)) ?? ""
    : "";
  // dual comparison is CohortComparisonPlotOption | undefined
  const selectedDualComparison = selectedComparisons
    ? selectedComparisons.find((comparison) => availableDualComparisonValues.includes(comparison))
    : undefined;

  const onAggregationSelect = (aggOption: AggregateType) => {
    if (!plottedData) {
      plotterOptionsDraft.set(fieldId, {
        id: fieldId,
        fieldInput: {
          id: fieldId,
          family: family ?? TimeSeriesFieldFamily.Signal,
          aggregateType: aggOption,
          unit,
          comparisonGroup,
        },
        comparison: emptyStringAsUndef([selectedSingleComparison, selectedDualComparison]),
      });
      return setDraftLastUpdated(new Date().getTime());
    }
    onPlotOptionsChange([
      {
        id: fieldId,
        fieldInput: {
          id: fieldId,
          family: plottedData.fieldInput?.family ?? TimeSeriesFieldFamily.Signal,
          aggregateType: aggOption,
          unit,
          comparisonGroup,
        },
        comparison: plottedData?.comparison,
        isPlotted,
      },
    ]);
  };

  const onSingleComparisonSelect = (singleComparisonOption?: CohortComparisonPlotOption) => {
    if (!plottedData) {
      plotterOptionsDraft.set(fieldId, {
        id: fieldId,
        fieldInput: {
          id: fieldId,
          family: family ?? TimeSeriesFieldFamily.Signal,
          aggregateType: selectedAggregation ?? AggregateType.Last,
          unit,
          comparisonGroup,
        },
        comparison: emptyStringAsUndef([singleComparisonOption, selectedDualComparison ?? "top-bottom-quartile"]),
      });
      return setDraftLastUpdated(new Date().getTime());
    }
    if (singleComparisonOption) {
      return onPlotOptionsChange([
        {
          id: fieldId,
          fieldInput: plottedData?.fieldInput,
          comparison: [singleComparisonOption, selectedDualComparison ?? "top-bottom-quartile"],
          isPlotted,
        },
      ]);
    }
    return onPlotOptionsChange([
      {
        id: fieldId,
        fieldInput: plottedData?.fieldInput,
        isPlotted,
      },
    ]);
  };

  const onAreaComparisonSelect = (areaComparisonOption?: CohortComparisonPlotOption) => {
    if (!plottedData) {
      plotterOptionsDraft.set(fieldId, {
        id: fieldId,
        fieldInput: {
          id: fieldId,
          family: family ?? TimeSeriesFieldFamily.Signal,
          aggregateType: selectedAggregation ?? AggregateType.Last,
          unit,
          comparisonGroup,
        },
        comparison: emptyStringAsUndef([selectedSingleComparison, areaComparisonOption ?? "top-bottom-quartile"]),
      });
      return setDraftLastUpdated(new Date().getTime());
    }
    if (selectedSingleComparison && areaComparisonOption) {
      onPlotOptionsChange([
        {
          id: fieldId,
          fieldInput: plottedData?.fieldInput,
          comparison: [selectedSingleComparison, areaComparisonOption],
          isPlotted,
        },
      ]);
    }
  };
  return (
    <FieldPlotterContainer>
      <div className="field-control">
        <p className="body-small">Aggregation</p>
        <StyledSelect
          placeholder="Select aggregation"
          options={aggregateTypes.map((a) => ({ value: a, label: describeAggregateType(a) }))}
          value={selectedAggregation}
          onSelect={(value) => onAggregationSelect(getAggregateOptionValue(value as string)!)}
        />
      </div>
      {shouldShowComparisonSelectors && (
        <div className="field-control">
          <p className="body-small">
            Fleet Comparison
            <Tooltip placement="top" title={cohortComparisontooltipMsg}>
              <StyledInfoCircleOutlined />
            </Tooltip>
          </p>
          <StyledSelect
            placeholder="Select single comparison"
            options={availableSingleComparisons}
            value={selectedSingleComparison}
            onSelect={(value) => onSingleComparisonSelect(getComparisonOptionValue(value as string))}
          />
        </div>
      )}
      {selectedSingleComparison ? (
        <div className="field-control">
          <div />
          <StyledSelect
            placeholder="Select area comparison"
            options={availableDualComparisons}
            value={selectedDualComparison}
            onSelect={(value) => onAreaComparisonSelect(getComparisonOptionValue(value as string))}
          />
        </div>
      ) : undefined}
      {/* TODO Plot related signals */}
      {family === TimeSeriesFieldFamily.Expression && <div className="field-control"></div>}
    </FieldPlotterContainer>
  );
};

export default LibraryFieldPlotter;
