import "./TimeSeriesChart.less";

import { CustomSeriesRenderItemAPI, SeriesOption } from "echarts";
import EChartsReact, { EChartsReactProps } from "echarts-for-react";
import _ from "lodash";
import { RefObject, useMemo, useRef, useState } from "react";

import { ErrorType, TimeSeriesData } from "@/api/customerApi";
import { UI_SETTINGS } from "@/constants";
import { toAxisGroupId } from "@/contexts/ChartLibrarySlice";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import { yAxisLabelFormatter } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";
import { chartLibraryEventsTooltipFormatter } from "@/features/chartElementLibrary/ChartLibraryBuildUtilsTooltips";
import unitLabel from "@/utils/timeSeriesUnits";
import { DataZoomFields } from "@/utils/types/EChartsDefinitions";

import { BaseEChart } from "../ui/BaseEChart";

export interface ChartField {
  fieldId: string;
  displayName?: string;
  color?: string;
}

export interface TimeSeriesChartProps {
  selectedSignals: AnalysisSignal[];
  fields: ChartField[];
  getSummaryData: (i: number) => [number?, number?, number?];
  onZoom: (zoom?: { min: number; max: number }) => void;
  data?: TimeSeriesData;
  isLoading?: boolean;
  errorType?: ErrorType;
  windowSize: number;
}

export default function TimeSeriesChart({
  selectedSignals,
  fields,
  getSummaryData,
  onZoom,
  data,
  isLoading,
  windowSize,
}: TimeSeriesChartProps) {
  const zoomRef = useRef(false);
  const buf = 0.2;
  const bounds: { min?: number; max?: number; offset?: number }[] = useMemo(
    () =>
      fields.map((_, i) => {
        if (!data || Math.max(data?.low?.length ?? 0, data?.high?.length ?? 0) !== fields.length) {
          return {};
        }
        const min = data?.low?.[i]?.filter((a) => a != null).reduce((a, b) => Math.min(a!, b!), Infinity);
        const max = data?.high?.[i]?.filter((a) => a != null).reduce((a, b) => Math.max(a!, b!), -Infinity);
        if (min == undefined || max == undefined) {
          return {};
        }

        function x(min: number, max: number): number {
          return Math.pow(10, Math.floor(Math.log10(max - min)));
        }
        // If needed, add a quantized offset to allow stacking of series that have negative values. We subtract the
        // offset back off in the axis formatter.
        const offset = min < 0 ? x(0, -min) : 0;
        // Quantize to the nearest tick mark
        return {
          min: Math.floor((min - (max - min) * buf) / x(min, max)) * x(min, max) + offset,
          max: Math.ceil((max + (max - min) * buf) / x(min, max)) * x(min, max) + offset,
          offset,
        };
      }),
    [fields, data]
  );

  // The series index currently "selected" by hovering the mouse
  const [hoverSeriesIndex, setHoverSeriesIndex] = useState<number | undefined>(undefined);
  const echartRef = useRef<EChartsReact>();

  // Sets zoom mode to active
  const libraryChartReadyHandler = () => {
    const chartInstance = echartRef.current?.getEchartsInstance();
    if (chartInstance) {
      chartInstance.dispatchAction({
        type: "takeGlobalCursor",
        key: "dataZoomSelect",
        dataZoomSelectActive: true,
      });
    }
  };

  const restoreZoomHandler = () => {
    onZoom();
  };

  const zoomEventHandler = _.debounce(() => {
    const chartOptions = echartRef.current?.getEchartsInstance().getOption();
    if (chartOptions) {
      const dataZoom = chartOptions.dataZoom as DataZoomFields[];
      if (dataZoom.length > 0) {
        const zoomStartIndex = dataZoom[0].startValue;
        const zoomEndIndex = dataZoom[0].endValue;
        if (zoomStartIndex && zoomEndIndex && zoomEndIndex > zoomStartIndex && data && data.x) {
          onZoom({ min: Math.floor(zoomStartIndex), max: Math.ceil(zoomEndIndex) });
        }
      }
    }
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  interface AxisGroup {
    id: string;
    unit?: string;
    min: number;
    max: number;
  }

  const axisGroups = selectedSignals
    .map((signal, i) => ({
      id: toAxisGroupId(signal.fieldInput),
      unit: signal.unit,
      min: Math.floor(Math.min(...((data?.y?.[i] as number[]) ?? [], (data?.low?.[i] as number[]) ?? [])) * 0.9),
      max: Math.ceil(Math.max(...((data?.y?.[i] as number[]) ?? [], (data?.high?.[i] as number[]) ?? [])) * 1.1),
    }))
    .reduce((axisGroups, group) => {
      const existingGroup = axisGroups.find((g) => g.id == group.id);
      if (existingGroup) {
        existingGroup.min = Math.min(existingGroup.min, group.min);
        existingGroup.max = Math.max(existingGroup.max, group.max);
      } else {
        axisGroups.push(group);
      }
      return axisGroups;
    }, [] as AxisGroup[]);

  const buildComparionsSeries = (field: ChartField, i: number): SeriesOption => {
    if (!data?.high?.[i] || !data?.low?.[i]) return {};
    return {
      type: "custom",
      renderItem: (params, api: CustomSeriesRenderItemAPI) => {
        if (params.context.rendered) {
          return;
        }
        params.context.rendered = true;
        const polygonData = [
          ...(data?.high?.[i].map((d, j) => [data.x[j], d!]) ?? []),
          ...(data?.low?.[i].map((d, j) => [data.x[j], d!]) ?? []).reverse(),
        ];
        let points: number[][] = [];
        for (let j = 0; j < polygonData.length; j++) {
          points.push(api.coord(polygonData[j]));
        }

        return {
          type: "polygon",
          transition: "shape",
          shape: {
            points: points,
          },
          style: {
            fill: field.color,
            fillOpacity: 0.1,
          },
        };
      },
      yAxisIndex: axisGroups.findIndex((group) => group.id == toAxisGroupId(selectedSignals[i].fieldInput)),
      clip: true,
      data: new Array(data.x.length),
    };
  };

  const chartConfig: EChartsReactProps = useMemo(
    () =>
      data?.count?.length != fields.length
        ? { option: {} }
        : {
            option: {
              backgroundColor: "#f9fafe",
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  animation: false,
                },
                formatter: (
                  params: { data?: { field?: ChartField; value: [number, number] }; name: string; dataIndex: number }[]
                ) => chartLibraryEventsTooltipFormatter(params, selectedSignals, getSummaryData, data, windowSize),
              },
              grid: {
                left: 75,
                right: (axisGroups.length - 1) * 75,
              },
              // zoom tool is there but it's not visible
              toolbox: {
                orient: "vertical",
                itemSize: 20,
                top: -275,
                itemGap: 15,
                feature: {
                  dataZoom: { iconStyle: { opacity: 0 } },
                  restore: {},
                  zoom: false,
                },
              },
              dataZoom: [
                {
                  type: "inside",
                  zoomOnMouseWheel: "shift",
                  preventDefaultMouseMove: false,
                  xAxisIndex: [0, 1],
                },
              ],
              xAxis: {
                type: "value",
                min: data?.x?.[0],
                max: data?.x?.at(-1),
                nameLocation: "center",
                nameGap: 50,
                name: "km",
                boundaryGap: false,
                axisTick: {
                  interval: 99,
                },
                axisLabel: {
                  interval: 99,
                },
                position: "bottom",
              },
              yAxis: axisGroups.map((group, i) => {
                return {
                  type: "value",
                  name: unitLabel(group.unit, ""),
                  nameTruncate: {
                    maxWidth: 50,
                  },
                  nameTextStyle: {
                    fontWeight: "bold",
                  },
                  position: i > 0 ? "right" : "left",
                  offset: i > 0 ? (i - 1) * 75 : 0,
                  scale: true,
                  alignTicks: true,
                  min: group.min,
                  max: group.max,
                  splitLine: {
                    show: false,
                  },
                  axisLine: {
                    show: false,
                    onZero: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  axisLabel: {
                    formatter: (value: any) => yAxisLabelFormatter(value),
                    margin: 10,
                  },
                  gridIndex: 0,
                };
              }),
              series: [
                ...fields.flatMap((field, i) => {
                  return [
                    {
                      type: "line",
                      symbol: "none",
                      connectNulls: false,
                      showSymbol: false,
                      yAxisIndex: axisGroups.findIndex(
                        (group) => group.id == toAxisGroupId(selectedSignals[i].fieldInput)
                      ),
                      axisLine: {
                        onZoom: false,
                      },
                      data: data?.y?.[i]?.map((y, j) => {
                        return {
                          value: [data?.x[j], y],
                          field: field,
                        };
                      }),
                      lineStyle: {
                        color: field.color,
                      },
                      triggerLineEvent: true,
                      markLine:
                        hoverSeriesIndex == i
                          ? {
                              symbol: "none",
                              label: {
                                show: false,
                              },
                              lineStyle: {
                                color: field.color,
                                type: "dashed",
                              },
                              data: getSummaryData(i).map((y) => ({
                                yAxis: y && y + (bounds[i].offset ?? 0),
                              })),
                            }
                          : undefined,
                    },
                  ];
                }),
                ...fields.flatMap((field, i) => [buildComparionsSeries(field, i)]),
                {
                  type: "line",
                  itemStyle: {
                    color: "#FF0000",
                  },
                  markLine: {
                    silent: true,
                    data: [{ name: "line", xAxis: 0 }],
                    symbol: ["none", "none"],
                    lineStyle: {
                      color: "black",
                      type: "solid",
                      width: 1,
                    },
                    label: {
                      show: false,
                    },
                    emphasis: {
                      disabled: true,
                    },
                  },
                },
              ],
            },
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, data, getSummaryData, hoverSeriesIndex, isLoading]
  );
  // TODO: Support for a number of fields other than 2 (e.g. 1 or more than 2)
  return (
    <div
      className="time-series-chart"
      onMouseDown={() => (zoomRef.current = true)}
      onMouseUp={() => (zoomRef.current = false)}
      onMouseLeave={() => (zoomRef.current = false)}
    >
      <BaseEChart
        style={{ minHeight: "500px", height: "100%" }}
        className="echarts"
        {...chartConfig}
        ref={echartRef as RefObject<EChartsReact>}
        showLoading={isLoading}
        opts={{ renderer: "svg" }}
        onChartReady={libraryChartReadyHandler}
        onEvents={{
          mouseover: (params: any) => {
            if (params.componentSubType == "line" && typeof params.seriesIndex == "number" && !zoomRef.current) {
              setHoverSeriesIndex(params.seriesIndex as number);
            }
          },
          mouseout: () => {
            if (hoverSeriesIndex != undefined) {
              setHoverSeriesIndex(undefined);
            }
          },
          datazoom: () => {
            zoomRef.current = false;
            zoomEventHandler();
          },
          restore: () => {
            zoomEventHandler();
            libraryChartReadyHandler();
            restoreZoomHandler();
          },
        }}
      />
    </div>
  );
}
