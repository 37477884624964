import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Button, notification } from "antd";
import { ColumnType } from "antd/es/table";
import { NotificationInstance } from "antd/lib/notification/interface";
import { useContext, useState } from "react";

import { Cluster, Pagination, useListClustersQuery } from "@/api";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import StatefulTable from "@/components/tables/StatefulTable";
import TableActions from "@/components/tables/TableActions";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { defaultDiagnosticTableState, defaultFaultTableState } from "@/routes/cluster.$clusterId";
import { Route } from "@/routes/cluster.index";
import { getCurrentCustomerId } from "@/utils/customers";
import { parseDateToStr } from "@/utils/dateUtils";

import { ClusterModal } from "../clusterModal/ClusterModal";
import { DeleteClusterModal } from "./DeleteClusterModal";

type ClusterRow = Omit<Cluster, "faults" | "pvins" | "missingPvins">;

type ClusterActionsProps = {
  row: ClusterRow;
  onSuccess: () => void;
  api: NotificationInstance;
};

const ClusterActions = ({ row, onSuccess, api }: ClusterActionsProps) => {
  return (
    <TableActions
      menu={
        row.creator?.isMe
          ? [
              {
                key: "delete-cluster",
                label: (
                  <DeleteClusterModal
                    onMutationSuccess={onSuccess}
                    api={api}
                    clusterId={row.id}
                    clusterName={row.name}
                  />
                ),
              },
            ]
          : []
      }
    />
  );
};

export const ClusterHome = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const { globalFilter } = useContext(GlobalFilterContext);

  const clusterNavigate = Route.useNavigate();
  const tableState = Route.useSearch({ select: (search) => search.clusterTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useListClustersQuery({
    filter: globalFilter,
    customerId: getCurrentCustomerId() ?? "",
    pagination: currentPagination,
    sorting: currentSorting,
  });

  const totalClusters = data?.clusters?.clusters.pagination.totalCount;

  const columns: ColumnType<ClusterRow>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, row: ClusterRow) => {
        return (
          <Link
            to="/cluster/$clusterId"
            params={{ clusterId: row.id }}
            search={() => ({
              clusterDiagnosticChecksTable: defaultDiagnosticTableState,
              clusterFaultEventsTable: defaultFaultTableState,
            })}
          >
            {row.name}
          </Link>
        );
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      sorter: true,
      render: (_: string, row: ClusterRow) => row.description || "-",
    },
    {
      key: "creatorName",
      title: "Author",
      dataIndex: "creator.name",
      sorter: true,
      render: (_: string, row: ClusterRow) => {
        return row.creator?.name ?? "-";
      },
    },
    {
      key: "created",
      title: "Date Created",
      dataIndex: "created",
      width: 175,
      sorter: true,
      render: (_: string, row: ClusterRow) => {
        return row.created ? parseDateToStr(new Date(row.created), true) : "-";
      },
    },
    { key: "vehicleCount", title: "Vehicle Count", dataIndex: "vehicleCount", width: 150 },
    {
      key: "actions",
      dataIndex: "",
      width: 75,
      render: (_: string, row: ClusterRow) => <ClusterActions row={row} onSuccess={onSuccessClusterAction} api={api} />,
    },
  ];

  const onSuccessClusterAction = async () => {
    setIsModalOpen(false);
    clusterNavigate({
      search: (prev) => ({
        ...prev,
        clusterTable: {
          ...tableState,
          pagination: { ...DEFAULT_PAGINATION, currentPage: 1 },
        },
      }),
    });

    queryClient.refetchQueries({
      queryKey: useListClustersQuery.getKey({ customerId: getCurrentCustomerId() ?? "", filter: globalFilter }),
    });
  };

  return (
    <>
      <TopHeadingWithButtons
        title="Clusters"
        description="Explore and manage clusters of vehicle diagnostic checks, making it easier to identify key patterns and trends in parts of your fleet."
        buttons={
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Create New Cluster
          </Button>
        }
      />
      <BasicWidget>
        {context}
        <StatefulTable
          searchKey="clusterTable"
          pagination={{
            total: totalClusters,
            pageSize: currentPagination.pageSize,
            currentPage: currentPagination.currentPage,
          }}
          sorting={currentSorting}
          columns={columns}
          dataSource={data?.clusters?.clusters.data}
          rowKey={"id"}
          loading={isLoading || isFetching}
        />
        <ClusterModal
          handleSuccess={onSuccessClusterAction}
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          api={api}
          initialView={"newCluster"}
        />
      </BasicWidget>
    </>
  );
};
