import { Crc32 } from "@aws-crypto/crc32";

export const crcHashString = (string: string) => {
  const crc = new Crc32();
  crc.update(new TextEncoder().encode(string));
  return crc.digest();
};

export const snakeCaseToTitleCase = (string: string) => {
  return string.replace(/(^|_)([a-z])/g, (_, __, letter) => " " + letter.toUpperCase()).trim();
};

export const camelCaseToTitleCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (first) => first.toUpperCase())
    .trim();
};

export const displayPositiveSign = (value: number | string, condition?: boolean) => {
  if ((condition ?? true) && Number(value) > 0) {
    return "+";
  }
  return "";
};
