// ClusterMatchesTable.tsx
import { Link } from "@tanstack/react-router";
import { ColumnType } from "antd/es/table";

import { FeatureId } from "@/api";
import { RepairEventClusterMatch } from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import i18next from "@/i18n";
import { defaultDiagnosticTableState, defaultFaultTableState } from "@/routes/cluster.$clusterId";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";

type ClusterMatchesTableProps = {
  clusters: RepairEventClusterMatch[];
};

// NOTE: These thresholds are a work in progress and should be updated accordingly after testing
const getMatchStrength = (frequency: number) => {
  if (frequency > 85) return "Strong match";
  if (frequency >= 55) return "Moderate match";
  return "Weak match";
};

const getRowClassName = (frequency: number) => {
  if (frequency > 85) return "strong-match";
  if (frequency >= 55) return "moderate-match";
  return "weak-match";
};

export const ClusterMatchesTable = ({ clusters }: ClusterMatchesTableProps) => {
  const { isFeatureEnabled } = useFeatureFlags();

  const columns: ColumnType<RepairEventClusterMatch>[] = [
    {
      key: "recommendedCluster",
      title: "Recommended Cluster",
      dataIndex: "recommendedCluster",
      render: (_v, row) => (
        <Link
          to="/cluster/$clusterId"
          params={{ clusterId: row.recommendedClusterId }}
          search={{
            clusterDiagnosticChecksTable: defaultDiagnosticTableState,
            clusterFaultEventsTable: defaultFaultTableState,
          }}
        >
          {row.recommendedCluster}
        </Link>
      ),
      className: "status-cell",
    },
    { key: "recommendedClusterId", title: "Recommended Cluster Id", dataIndex: "recommendedClusterId" },
    { key: "faultCode", title: "Fault Code", dataIndex: "faultCode" },
    {
      key: "matchComponentLocationId",
      title: `${i18next.t("columnTitle.COMPONENT_LOCATION_ID")}`,
      dataIndex: "componentLocationId",
      width: 150,
    },
    {
      key: "frequency",
      title: `${i18next.t("columnTitle.CLUSTER_RECOMMENDATON_FREQ")}`,
      dataIndex: "frequency",
      align: "left",
      render: (_v, { frequency }) => <div>{`${formatNumber(frequency)}% (${getMatchStrength(frequency)})`}</div>,
    },
  ];

  // Define a rowKey function
  const rowKey = (record: RepairEventClusterMatch) =>
    `${record.recommendedClusterId}-${record.faultCode}-${record.componentLocationId}`;

  return (
    <BasicWidget hideHeader={true}>
      <BasicTable
        columns={columns.filter((c) => {
          if (isFeatureEnabled(FeatureId.AssignedRepairEvents)) {
            return true;
          }
          return c.key !== "recommendedClusterId";
        })}
        dataSource={clusters}
        pagination={false}
        rowKey={rowKey}
        rowClassName={(record) => `cluster-recommendation-row ${getRowClassName(record.frequency)}`}
        tableLayout="auto"
      />
    </BasicWidget>
  );
};
