import IconDropdown from "@amayaIcons/icon-drop-down.svg?react";
import { Select } from "antd";
import dayjs, { Dayjs } from "dayjs";

import { TimeFrameInputContainer } from "./TimeFrameInput.styled";

export enum TimeFrame {
  TODAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_60_DAYS,
  LAST_90_DAYS,
  ALL_TIME,
  YTD,
}

const TimeFrameOptions = [
  { value: TimeFrame.TODAY, label: "Today" },
  { value: TimeFrame.LAST_7_DAYS, label: "Last 7 days" },
  { value: TimeFrame.LAST_30_DAYS, label: "Last 30 days" },
  { value: TimeFrame.LAST_60_DAYS, label: "Last 60 days" },
  { value: TimeFrame.LAST_90_DAYS, label: "Last 90 days" },
  { value: TimeFrame.YTD, label: "This year" },
  { value: TimeFrame.ALL_TIME, label: "All Time" },
];

type TimeFrameInputProps = {
  value?: TimeFrame;
  placeholder?: string;
  onChange?: (value: TimeFrame) => void;
  label?: string;
};

// TODO reuse select (?)
const TimeFrameInput = (props: TimeFrameInputProps) => {
  const { value, onChange, label, placeholder } = props;
  return (
    <TimeFrameInputContainer>
      {label && <span className="frame-input-label body-medium ">{label}</span>}
      <Select
        options={TimeFrameOptions}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        suffixIcon={<IconDropdown />}
        listItemHeight={48}
      />
    </TimeFrameInputContainer>
  );
};

export const dateFromTimeframe = (timeFrame: TimeFrame): Dayjs => {
  switch (timeFrame) {
    case TimeFrame.TODAY:
      return dayjs().startOf("day");
    case TimeFrame.YTD:
      return dayjs().startOf("year");
    case TimeFrame.LAST_7_DAYS:
      return dayjs().subtract(7, "day").startOf("day");
    case TimeFrame.LAST_30_DAYS:
      return dayjs().subtract(30, "day").startOf("day");
    case TimeFrame.LAST_60_DAYS:
      return dayjs().subtract(60, "day").startOf("day");
    case TimeFrame.LAST_90_DAYS:
      return dayjs().subtract(90, "day").startOf("day");
    case TimeFrame.ALL_TIME:
      return dayjs(0);
    default:
      throw new Error("Timeframe not supported.");
  }
};

export default TimeFrameInput;
