import { useContext, useState } from "react";

import { useVehicleCountForFleetIntelQuery } from "@/api/customerApi";
import FleetMap, { FleetMapVehicleCount } from "@/components/fleetMap/FleetMap";
import { CustomerContext } from "@/contexts/CustomerProvider";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

import TimeFrameInput, { dateFromTimeframe, TimeFrame } from "../form/input/TimeFrameInput";
import FleetCountryTable from "../tables/FleetCountryTable";
import { BasicWidget } from "../widget/BasicWidget";
import { FleetIntelContainer, FleetIntelHeaderContainer } from "./FleetIntel.styled";

export const FleetIntel = () => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { currentCustomer } = useContext(CustomerContext);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [timeframe, setTimeframe] = useState(TimeFrame.ALL_TIME);

  const startDate = dateFromTimeframe(timeframe);

  const fleetIntelQuery = useVehicleCountForFleetIntelQuery({
    filter: globalFilter,
    startDate: startDate?.toISOString(),
  });

  const intelHeader = (
    <FleetIntelHeaderContainer>
      <h2 className="heading-x-small dark">{currentCustomer?.name.concat(" - ")}Fleet Intel</h2>
      <TimeFrameInput label="Filter by repairs" value={timeframe} onChange={setTimeframe}></TimeFrameInput>
    </FleetIntelHeaderContainer>
  );

  const fleetMapData: FleetMapVehicleCount[] =
    fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.vehiclesByCountry.map((v) => ({
      country: v.country ? v.country : undefined,
      vehicles: v.vehicles ?? 0,
    })) ?? [];

  return (
    <BasicWidget title={intelHeader} noHeaderStyling>
      <FleetIntelContainer>
        <FleetMap
          data={fleetMapData}
          isLoading={fleetIntelQuery.isLoading}
          selectedCountry={selectedCountry}
          onCountrySelected={setSelectedCountry}
        />
        <FleetCountryTable
          total={fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.totalVehicles}
          data={fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.vehiclesByCountry}
          isLoading={fleetIntelQuery.isLoading}
          selectedCountry={selectedCountry}
          onCountrySelected={setSelectedCountry}
        />
      </FleetIntelContainer>
    </BasicWidget>
  );
};
