import { InfoCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import _ from "lodash";
import { useContext, useId, useState } from "react";

import { FeatureId, FilterType, GroupFilterCriteria, Pagination } from "@/api";
import {
  Fault,
  FaultHistorySortableColum,
  useFaultHistoryColumnsFilterQuery,
  useVehicleFaultHistoryQuery,
} from "@/api/customerApi";
import StatefulTable, { ColumnTypeWithFiltering } from "@/components/tables/StatefulTable";
import StyledTooltip from "@/components/ui/StyledTooltip";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { Route } from "@/routes/vehicle.$pvin";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";

import { EmptyComponentSCV } from "./EmptyComponentSCV";
import FaultDetailsModal from "./FaultDetailsModal";
import FaultHistoryActions from "./FaultHistoryActions";

export const INFERRED_DATE_JSX_MSG = (
  <span>
    Fault timestamp unavailable <br /> FAP timestamp is used
  </span>
);

type FilterColumnKey = "fault_code" | "trouble_code" | "ecu";
type SelectedFilterOptions = {
  [key in FilterColumnKey]: string[];
};

const buildFaultStartLabel = (fault: Fault) => {
  const { startTime } = fault;
  if (!startTime) return "N/A";
  return new Date(startTime).toLocaleString();
};

const FaultHistoryByVehicle = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { pvin } = useContext(SCVContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const [selectedFaultId, setSelectedFaultId] = useState("");

  const tableId = useId();

  const tableState = Route.useSearch({ select: (search) => search.faultHistoryTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const currentFiltering = tableState?.filters;

  const { data, isFetching } = useVehicleFaultHistoryQuery(
    {
      id: pvin,
      pagination: currentPagination,
      sort: currentSorting,
      columnFilters: currentFiltering,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const total = data?.vehicleFaultHistory.pagination?.totalCount;

  const { data: faultFilterData, isFetching: isFetchingFaultFilterData } = useFaultHistoryColumnsFilterQuery(
    {
      pvin,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const faultFilterOptions = faultFilterData?.faultHistoryByUserInput ?? [];

  const selectedFilterOptions: SelectedFilterOptions = {
    fault_code: currentFiltering?.find((filter) => filter.key === "fault_code")?.group ?? [],
    trouble_code: currentFiltering?.find((filter) => filter.key === "trouble_code")?.group ?? [],
    ecu: currentFiltering?.find((filter) => filter.key === "ecu")?.group ?? [],
  };

  const filterByFilterKey = (key: FilterColumnKey, option: string): boolean => {
    const selectedOptions = selectedFilterOptions[key];
    if (_.isEmpty(selectedOptions)) return true;
    return selectedOptions.includes(option);
  };

  const filterEcu = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("fault_code", options.faultCode!))
        .filter((options) => filterByFilterKey("trouble_code", options.troubleCode!))
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .map(({ ecu }) => ecu) as string[]
    ) ?? [];

  const filterFaultCodes = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .filter((options) => filterByFilterKey("trouble_code", options.troubleCode!))
        .filter((options) => filterByFilterKey("fault_code", options.faultCode!))
        .map(({ faultCode }) => faultCode) as string[]
    ) ?? [];

  const filterTroubleCodes = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .filter((options) => filterByFilterKey("fault_code", options.faultCode!))
        .filter((options) => filterByFilterKey("trouble_code", options.troubleCode!))
        .map(({ troubleCode }) => troubleCode) as string[]
    ) ?? [];

  const faultData = data?.vehicleFaultHistory.data ?? [];

  const columns: ColumnTypeWithFiltering<Fault>[] = [
    {
      key: "isDateInferred",
      title: " ",
      dataIndex: "isDateInferred",
      render: (_: any, row: Fault) => {
        if (row.isDateInferred)
          return (
            <StyledTooltip title={INFERRED_DATE_JSX_MSG} showArrow={false}>
              <span className="fault-start-date error">
                <InfoCircleOutlined />
              </span>
            </StyledTooltip>
          );
      },
      width: 48,
    },
    {
      key: FaultHistorySortableColum.StartTime,
      title: "Fault Start",
      dataIndex: "startTime",
      render: (_: any, row: Fault) => <span>{buildFaultStartLabel(row)}</span>,
      // Ellipsis dots should be red if isDateInferred
      onCell: (row: Fault) => ({ className: row.isDateInferred ? "error" : "" }),
      tooltipMessage: (_: any, row: Fault) => buildFaultStartLabel(row),
      sorter: true,
      defaultSortOrder: "descend",
      align: "start",
    },

    {
      key: FaultHistorySortableColum.Odometer,
      title: "Odometer (km)",
      dataIndex: "odometer",
      render: (_: any, row: Fault) => {
        if (row.odometer === null || row.odometer === undefined)
          return (
            <StyledTooltip title={"Odometer not available"} showArrow={false}>
              <span>-</span>
            </StyledTooltip>
          );
        return formatNumber(row.odometer);
      },
      sorter: true,
    },
    {
      key: FaultHistorySortableColum.FaultCode,
      title: "Fault Code",
      dataIndex: "faultCode",
      sorter: true,
      render: (_: any, row: Fault) => row.code,
      tooltipMessage(_value, record) {
        return getFaultDescription(customerIdentifier, record.code, record.source, record.troubleCode)?.name ?? "";
      },
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Fault Code",
        isLoading: isFetchingFaultFilterData,
        columnKey: "fault_code" satisfies FilterColumnKey,
        options: filterFaultCodes().map((fc) => ({
          value: fc,
          label: fc,
        })),
        onSearchChange: () => {},
      },
    },
    {
      key: FaultHistorySortableColum.TroubleCode,
      title: "Trouble Code",
      dataIndex: "troubleCode",
      sorter: true,
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "Trouble Code",
        isLoading: isFetchingFaultFilterData,
        columnKey: "trouble_code" satisfies FilterColumnKey,
        options: filterTroubleCodes().map((tc) => ({
          value: tc,
          label: tc,
        })),
        onSearchChange: () => {},
      },
    },
    {
      key: FaultHistorySortableColum.Source,
      title: "ECU",
      dataIndex: "source",
      sorter: true,
      filterProps: {
        includeNulls: true,
        criteria: GroupFilterCriteria.Incl,
        type: FilterType.Group,
        label: "ECU",
        isLoading: isFetchingFaultFilterData,
        columnKey: "ecu" satisfies FilterColumnKey,
        options: filterEcu().map((ecu) => ({
          value: ecu,
          label: ecu,
        })),
        onSearchChange: () => {},
      },
    },
    {
      key: FaultHistorySortableColum.TroubleCode,
      title: "Trouble Code Description",
      dataIndex: "troubleCodeDesc",
      tooltipMessage: (_: any, row: Fault) => {
        const description = getFaultDescription(customerIdentifier, row.code, row.source, row.troubleCode ?? "");
        return description?.name ?? "N/A";
      },
      render: (_: any, row: Fault) => {
        const description = getFaultDescription(customerIdentifier, row.code, row.source, row.troubleCode ?? "");
        return description?.name ?? "N/A";
      },
    },
    {
      key: FaultHistorySortableColum.SoftwareVersion,
      title: "Software Version",
      dataIndex: "softwareVersion",
      sorter: true,
    },
    {
      key: FaultHistorySortableColum.HardwareVersion,
      title: "Hardware Version",
      dataIndex: "hardwareVersion",
      sorter: true,
    },
    {
      key: FaultHistorySortableColum.SoftwarePartNumber,
      title: "Software Part Number",
      dataIndex: "softwarePartNumber",
      sorter: true,
    },
    {
      key: FaultHistorySortableColum.HardwarePartNumber,
      title: "Hardware Part ",
      dataIndex: "hardwarePartNumber",
      sorter: true,
      ellipsis: true,
    },
    {
      key: "toEventAnalysis",
      render: (_: any, row: Fault) => <FaultHistoryActions fault={row} />,
      width: 72,
    },
  ];

  const selectedFault = data?.vehicleFaultHistory.data.find((f) => f.id === selectedFaultId) satisfies
    | Fault
    | undefined as Fault | undefined;

  const className = `rendering-order-${isFeatureEnabled(FeatureId.Telemetry) ? "fith" : "second"}`;

  return (
    <BasicWidget className={className} title="Fault History">
      <StatefulTable<Fault>
        searchKey="faultHistoryTable"
        id={tableId}
        columns={columns}
        rowKey={(v) => v.id}
        dataSource={faultData}
        loading={isFetching}
        pagination={{
          total,
          pageSize: currentPagination.pageSize,
          currentPage: currentPagination.currentPage,
        }}
        sorting={currentSorting}
        filters={currentFiltering}
        style={{ minHeight: 400 }}
        rowClassName={(fault) => `cta-row${fault.id === selectedFaultId ? " highlighted" : ""}`}
        onRow={(fault) => {
          return {
            onClick: () => setSelectedFaultId(fault.id),
          };
        }}
        customEmptyComponent={
          !currentFiltering?.length ? (
            <EmptyComponentSCV description="No Fault Data available for this vehicle" />
          ) : undefined
        }
      />
      {selectedFault ? <FaultDetailsModal fault={selectedFault} onClose={() => setSelectedFaultId("")} /> : undefined}
    </BasicWidget>
  );
};

export default FaultHistoryByVehicle;
