import { useLocation } from "@tanstack/react-router";
import posthog from "posthog-js";
import { useEffect, useRef, useState } from "react";

import { getCurrentCustomerId } from "@/utils/customers";

import { abortRequests, ROUTE_CHANGE_ABORT } from "./AbortController";

export const RouteChageDetector = () => {
  const [previousLocation, setPreviousLocation] = useState<string>();
  const previousCustomerIdRef = useRef<string | undefined>();
  const location = useLocation();
  const posthogEnabled = import.meta.env.VITE_POSTHOG_ENABLED === "true";

  useEffect(() => {
    const currentCustomerId = getCurrentCustomerId();

    // Only update PostHog properties if the customer ID has changed
    if (posthogEnabled && currentCustomerId !== previousCustomerIdRef.current) {
      // Register the customer ID and app version with PostHog to attach them to all events
      posthog.register({
        customer_id: currentCustomerId || "unknown",
        app_version: import.meta.env.VITE_APP_VERSION || "unknown",
      });

      // Update the ref to the current customer ID
      previousCustomerIdRef.current = currentCustomerId;
    }

    if (previousLocation && previousLocation !== location.pathname) {
      if (posthogEnabled) {
        // Track page leave for the previous location
        posthog.capture("$pageleave");
        // Track page view for the new location
        posthog.capture("$pageview");
      }

      setPreviousLocation(location.pathname);
      abortRequests(ROUTE_CHANGE_ABORT);
    } else if (!previousLocation) {
      if (posthogEnabled) {
        posthog.capture("$pageview");
      }
      setPreviousLocation(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <></>;
};
