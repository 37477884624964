import i18next from "i18next";

import { AllowedDataMatrixTables, FeatureId } from "@/api";
import { TimeSeriesIndependentVar } from "@/api";
import { HdmClusterComparisonInput, HdmCustomComparisonInput, HdmTableAggregateValue } from "@/api/customerApi";
import { parseDateToStr } from "@/utils/dateUtils";

import { snakeCaseToTitleCase } from "../stringUtils";
import { CommonDataDictionary, DataMatrixDefinition, DataMatrixNormalizations } from "../types/commonDataDictionary";

export type SelectedDataMatrix = { id: string; label: string; tableHash: string };

export type ComparisonDefinition = {
  customComparison?: HdmCustomComparisonInput;
  clusterComparison?: HdmClusterComparisonInput;
};

export const sortHistoryDataByVariable = (v: TimeSeriesIndependentVar, data: HdmTableAggregateValue[]) => {
  if (v === TimeSeriesIndependentVar.Time) {
    return data;
  }
  // zero should not happen due to the filtering
  return data.filter((e) => !!e.odometer).toSorted((a, b) => (a.odometer ?? 0) - (b.odometer ?? 0));
};

export const getDataMatrixLabel = (dataMatrixTable?: HdmTableAggregateValue) => {
  return `${parseDateToStr(new Date(dataMatrixTable?.timestamp ?? ""), true)}`;
};

export const getDataMatrixHash = (dataMatrixTable?: HdmTableAggregateValue) => {
  return `${new Date(dataMatrixTable?.timestamp ?? "").getTime()}_${
    dataMatrixTable?.odometer ? dataMatrixTable.odometer : ""
  }_${dataMatrixTable?.diagnosticCheckId ? dataMatrixTable.diagnosticCheckId : ""}`;
};

export const getHDMTableDefinitions = (
  customerIdentifier: CommonDataDictionary,
  getFeatureConfig: <T>(id: FeatureId) => T,
  tableId?: string
) => {
  const allowedTables = getFeatureConfig<AllowedDataMatrixTables>(FeatureId.HdmTables)?.tableIds ?? [];
  return customerIdentifier.dataMatrixTables?.filter(
    (t) => allowedTables.includes(t.table_id) && (!tableId || t.table_id === tableId)
  );
};

export const getHDMTableNormalizations = (table?: DataMatrixDefinition) => {
  return [DataMatrixNormalizations.RAW, ...(table?.normalizations ?? [])];
};

export const getHDMComparionsAttributesDisplayLabel = (key: string) => {
  const hdmAttributeDict = {
    model: "Model",
    modelYear: "Model Year",
    deliveryCountry: "Delivery Country",
    batterySize: "Battery Size",
    aggregation: "Aggregation",
    repairType: "Workshop visit",
    DiagnosticCheckType: "FAP",
    clusterId: "Cluster",
  } as const;
  return hdmAttributeDict[key as keyof typeof hdmAttributeDict];
};

export const getNormalizationLabel = (normalization: string) => {
  if (normalization === "scaled_by_constant") {
    return i18next.t("hdmNormalizations.SCALED_BY_CONSTANT");
  }
  return snakeCaseToTitleCase(normalization);
};
