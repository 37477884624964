import { Link } from "@tanstack/react-router";
import { Skeleton } from "antd";
import React from "react";

import { Cluster } from "@/api";
import { EventFilter, FaultEvent } from "@/api/customerApi";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { defaultDiagnosticTableState, defaultFaultTableState } from "@/routes/cluster.$clusterId";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

export const EventHeaderFault: React.FC<{
  eventFilter: EventFilter;
  cluster?: Cluster;
  clusterLoading?: boolean;
}> = ({ eventFilter, cluster, clusterLoading }) => {
  const { customerIdentifier } = useCustomerIdentifier();
  const {
    faultCode,
    ecu,
    softwareVersion,
    softwarePartNumber,
    hardwarePartNumber,
    hardwareVersion,
    troubleCode,
    clusterId,
  } = eventFilter.fault as FaultEvent;

  const description = getFaultDescription(
    customerIdentifier,
    eventFilter.fault?.faultCode ?? "",
    eventFilter.fault?.ecu ?? "",
    eventFilter.fault?.troubleCode ?? ""
  );

  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Fault Event</span>
        <span>{`${faultCode.toUpperCase()}${description?.name ? ` - ${description.name}` : ""}`}</span>
      </h1>
      <div className="header-event-data">
        <div className="event-info-container">
          {ecu && (
            <div className="header-data-section">
              <p className="body-small">ECU</p>
              <p className="body-medium dark">{ecu}</p>
            </div>
          )}
          {softwareVersion && (
            <div className="header-data-section">
              <p className="body-small">Software Version</p>
              <p className="body-medium dark">{softwareVersion}</p>
            </div>
          )}
          {hardwareVersion && (
            <div className="header-data-section">
              <p className="body-small">Hardware Version</p>
              <p className="body-medium dark">{hardwareVersion}</p>
            </div>
          )}
          {softwarePartNumber && (
            <div className="header-data-section">
              <p className="body-small">Software Part Number</p>
              <p className="body-medium dark">{softwarePartNumber}</p>
            </div>
          )}
          {hardwarePartNumber && (
            <div className="header-data-section">
              <p className="body-small">Hardware Part Number</p>
              <p className="body-medium dark">{hardwarePartNumber}</p>
            </div>
          )}
          {troubleCode && (
            <div className="header-data-section">
              <p className="body-small">Trouble Code</p>
              <p className="body-medium dark">{troubleCode}</p>
            </div>
          )}
          {clusterId && (
            <div className="header-data-section">
              <p className="body-small">Cluster</p>
              <div className="body-medium dark">
                {clusterLoading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <Link
                    to="/cluster/$clusterId"
                    params={{ clusterId: cluster?.id ?? clusterId ?? "" }}
                    search={{
                      clusterDiagnosticChecksTable: defaultDiagnosticTableState,
                      clusterFaultEventsTable: defaultFaultTableState,
                    }}
                  >
                    {cluster?.name ?? clusterId}
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
