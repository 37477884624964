import { isNotUndefined } from "@lib/src/isNotUndefined";

import { SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { ById } from "@/hooks/useTimeSeriesFields";

export const displayableInputFields = (anomaly: SingleVehicleAnomalyDetector, byId: ById) => {
  return anomaly.detector.inputIds
    .map(byId)
    .filter(isNotUndefined)
    .map((field) => {
      const label = field.displayName || field.id;
      return {
        id: field.id,
        label,
        friendlyName: label,
        family: field.family,
      };
    });
};
