import TableNoData from "@amayaIcons/table_no_data.svg?react";
import { Empty, Typography } from "antd";
import { EmptyProps } from "antd/lib";
import React from "react";

type Props = EmptyProps & {
  isLoading?: boolean;
};

export const EmptyComponent: React.FC<Props> = ({
  isLoading,
  description = "No Data",
  image = <TableNoData />,
  imageStyle = {},
  ...args
}) => {
  return (
    <Empty
      image={!isLoading && image}
      imageStyle={{
        height: 56,
        width: 56,
        ...imageStyle,
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      description={
        !isLoading && (
          <Typography.Title level={5} type="secondary">
            {description}
          </Typography.Title>
        )
      }
      {...args}
    />
  );
};
