import { PlottedSignalIcon } from "@/features/chartElementLibrary/LibraryChartToolbox";
import { ChartSerieslegendContainer } from "@/features/chartElementLibrary/LibraryChartToolbox.styled";

import { BaseEChart } from "../ui/BaseEChart";
import { AmayaBarChartProps, buildBarChartConfig, getSeriesLegend, SeriesLegend } from "./ChartConfigHelper";

type BarChartLegendPros = {
  legendData: SeriesLegend[];
};

const BarChartLegend = ({ legendData }: BarChartLegendPros) => {
  return (
    <ChartSerieslegendContainer>
      {legendData.map((l) => {
        const hashKey = JSON.stringify(l);
        return (
          <div key={hashKey} className="signal-element">
            <PlottedSignalIcon color={l.color} />
            <p className="signal-name body-x-small dark">{l.label}</p>
          </div>
        );
      })}
    </ChartSerieslegendContainer>
  );
};

const AmayaBarChart = (props: AmayaBarChartProps) => {
  const chartLegend = props.showLegend ? props.seriesData.map((_s, i) => getSeriesLegend(i, props.seriesLabels)) : [];
  const chartOptions = buildBarChartConfig(props);

  return (
    <>
      {props.showLegend ? <BarChartLegend legendData={chartLegend} /> : undefined}
      <BaseEChart {...chartOptions} showLoading={props.isLoading} notMerge={true} />
    </>
  );
};

export default AmayaBarChart;
