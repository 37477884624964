import { useState } from "react";

import { TimeSeriesField, TimeSeriesFieldFamily } from "@/api";
import { AggregateType, ErrorType } from "@/api/customerApi";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import { buildFieldChartLegend } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";
import { LibraryItem } from "@/features/chartElementLibrary/LibraryItem";
import LibraryListWithSearch from "@/features/chartElementLibrary/LibraryListWithSearch";
import { searchLabelsMap } from "@/features/chartElementLibrary/LibrarySignalListSelector";
import { TimeSeriesFieldWithFamilyAndErrorType } from "@/features/chartElementLibrary/types";
import useTimeSeriesOptions from "@/hooks/useTimeSeriesOptionsForCharting";
import { isFieldTypeSupported } from "@/utils/types/fieldTypeSupported";

import BlockingLoading from "../ui/BlockLoading";
import { EventFieldPlotter } from "./EventFieldPlotter";
import { PlotMode } from "./EventTimeSeriesTabs";

interface EventSignalListSelectorProps {
  signalFamily?: TimeSeriesFieldFamily;
  onlyPlotted?: boolean;
  allowSearch?: boolean;
  plottedSignals: AnalysisSignal[];
  onPlotOptionsChange: (mode: PlotMode, signalsData: AnalysisSignal) => void;
}
const eventPlotterOptionsDraft = new Map<string, AnalysisSignal>();

const EventSignalListSelector = ({
  signalFamily,
  onlyPlotted,
  allowSearch,
  plottedSignals,
  onPlotOptionsChange,
}: EventSignalListSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const searchPlaceholder = signalFamily ? searchLabelsMap.get(signalFamily) ?? "Search" : "Search";

  const { timeSeriesOptions: data, isLoading } = useTimeSeriesOptions({ typeFilter: signalFamily });

  const plottedIds = plottedSignals.map((s) => s.id);

  const availableFields = ((data || []) as TimeSeriesField[]).filter((field) =>
    signalFamily ? field.family === signalFamily : true
  );

  const plottableFields: TimeSeriesFieldWithFamilyAndErrorType[] = availableFields
    .map((field) => ({
      ...field,
      errorType: ErrorType.Quartile,
    }))
    .filter(isFieldTypeSupported)
    // onlyPlotted filtering
    .filter((f) => (onlyPlotted ? plottedIds.includes(f.id) : true))
    .filter((f) => f.aggregationTypes?.length)
    // searchTerm filtering
    .filter((f) => f.displayName?.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  // TODO make toggle on use LibraryFieldPlotter options
  const handleFieldToggle = (_: boolean, field: TimeSeriesFieldWithFamilyAndErrorType) => {
    if (field.id) {
      onPlotOptionsChange(PlotMode.TOGGLE, {
        name: field.displayName ?? "-",
        id: field.id ?? "",
        fieldInput: {
          id: field.id,
          aggregateType: eventPlotterOptionsDraft.get(field.id)?.fieldInput.aggregateType || AggregateType.Median,
          errorType: eventPlotterOptionsDraft.get(field.id)?.fieldInput.errorType || field.errorType,
          family: field.family,
          entityId: field.entityId ?? undefined,
          comparisonGroup: field.comparisonGroup ?? undefined,
        },
        unit: field.unit ?? "",
      });
    }
  };

  if (isLoading) return <BlockingLoading className="line with-margin" />;
  return (
    <LibraryListWithSearch searchPlaceholder={searchPlaceholder} onSearch={allowSearch ? handleSearch : undefined}>
      {plottableFields.map((field) => {
        const fieldName = buildFieldChartLegend({
          fieldId: field.id,
          displayName: field.displayName ?? "",
        });

        const plottedIndex = plottedSignals.findIndex((s) => s.id === field.id);
        const plottedData = plottedIndex >= 0 ? plottedSignals[plottedIndex] : undefined;
        return (
          <LibraryItem
            label={fieldName}
            key={field.id}
            fieldId={field.id}
            onToggle={(active) => handleFieldToggle(active, field)}
            plottedIndex={plottedIndex}
            isSelected={!!plottedData}
          >
            <EventFieldPlotter
              name={field.displayName ?? "-"}
              id={field.id}
              fieldId={field.id ?? "-"}
              fieldDisplayName={fieldName}
              plottedData={plottedData}
              signalType={field.family}
              onPlotOptionsChange={(value) => onPlotOptionsChange(PlotMode.UPDATE, value)}
              eventPlotterOptionsDraft={eventPlotterOptionsDraft}
              unit={field.unit ?? "-"}
            />
          </LibraryItem>
        );
      })}
    </LibraryListWithSearch>
  );
};

export default EventSignalListSelector;
