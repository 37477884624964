import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Link, useSearch } from "@tanstack/react-router";
import { ColumnType } from "antd/lib/table";
import React, { useContext, useMemo } from "react";
import { ValuesType } from "utility-types";

import { FeatureId, Pagination } from "@/api";
import {
  EventDescriptor,
  EventFilter,
  FaultEventId,
  RepairEventType,
  useRelatedEventsQuery,
} from "@/api/generated/graphql.customer";
import { RepairEventNames } from "@/components/event/eventHeader/EventHeaderConstants";
import StatefulTable from "@/components/tables/StatefulTable";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { useFeatureFlags } from "@/utils/features";

interface RelatedEventTableProps {
  eventFilter: EventFilter;
}

type RecordType = {
  key: string;
  link: React.ReactNode;
  type: string;
  name: string;
  totalOccurrences: number;
  uniqueVehicles: number;
  faultEvent?: FaultEventId;
};

export default function RelatedEventTable({ eventFilter }: RelatedEventTableProps) {
  const { customerIdentifier } = useCustomerIdentifier();
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalFilter } = useContext(GlobalFilterContext);

  const tableState = useSearch({ strict: false, select: (search) => search.relatedEventsTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const { data, isFetching } = useRelatedEventsQuery(
    {
      filter: globalFilter,
      eventFilter,
      mileageWindowSize: 500,
      pagination: currentPagination,
      sort: currentSorting,
      telematicsless: !isFeatureEnabled(FeatureId.Telemetry),
    },
    {
      staleTime: QUERY_SETTINGS.LONG_STALE_TIME,
    }
  );

  function toType(ed: EventDescriptor): string {
    if (ed.repairEventType) {
      return "Repair";
    }
    if (ed.fault) {
      return "Fault";
    }
    if (ed.expressionEventId) {
      return "Pattern";
    }
    return "Unknown";
  }

  function toName(ed: EventDescriptor): string {
    if (ed.repairEventType) {
      return RepairEventNames[ed.repairEventType];
    } else if (ed.fault) {
      return `${ed.fault.faultCode} ${ed.fault.ecu} ${ed.fault.troubleCode}`;
    }
    return ed.name;
  }

  function toAnalysisLink(ed: EventDescriptor): React.ReactNode {
    const eventName = toName(ed);
    if (ed.repairEventType) {
      if (ed.repairEventType == RepairEventType.BatteryFullReplacement) {
        return <Link to="/repair/full">{eventName}</Link>;
      }
      if (ed.repairEventType == RepairEventType.BatteryModuleReplacement) {
        return <Link to="/repair/partial">{eventName}</Link>;
      }
    }
    if (ed.fault) {
      return (
        <Link
          to="/fault/$faultCodeId"
          params={{ faultCodeId: ed.fault?.faultCode ?? "" }}
          search={{
            eventFilter: {
              ecu: ed.fault?.ecu ?? "",
            },
          }}
        >
          {eventName}
        </Link>
      );
    }
    if (ed.expressionEventId) {
      return (
        <Link to="/pattern-event/$patternEventId" params={{ patternEventId: ed.expressionEventId ?? "" }}>
          {eventName}
        </Link>
      );
    }
    return <span>{eventName}</span>;
  }

  const displayData: RecordType[] = useMemo(
    () =>
      data?.filteredQuery.relatedEvents.events.map((event) => ({
        key: event.event.expressionEventId ?? `${event.event.fault?.faultCode}` ?? event.event.repairEventType ?? "",
        link: toAnalysisLink(event.event),
        type: toType(event.event),
        name: toName(event.event),
        totalOccurrences: event.totalOccurrences,
        uniqueVehicles: event.uniqueVehicles,
        faultEvent: event.event.fault ?? undefined,
      })) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const columns: ColumnType<ValuesType<typeof displayData>>[] = [
    {
      key: "key",
      title: "Event ID",
      render: (_, relatedEvent: RecordType) => relatedEvent.link,
      tooltipMessage: (_name, relatedEvent: RecordType) => {
        if (relatedEvent.faultEvent) {
          return (
            getFaultDescription(
              customerIdentifier,
              relatedEvent.faultEvent.faultCode,
              relatedEvent.faultEvent.ecu ?? "",
              relatedEvent.faultEvent.troubleCode ?? ""
            )?.name ?? ""
          );
        }
        return relatedEvent.name;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "totalOccurrences",
      title: "Occurrences",
      dataIndex: "totalOccurrences",
      sorter: true,
    },
    {
      key: "uniqueVehicles",
      title: "Vehicles",
      dataIndex: "uniqueVehicles",
      sorter: true,
      defaultSortOrder: "descend",
      width: 150,
    },
  ];

  const total = data?.filteredQuery.relatedEvents.pagination.totalCount;

  return (
    <StatefulTable
      searchKey="relatedEventsTable"
      loading={isFetching}
      columns={columns}
      dataSource={displayData}
      rowKey={(row) => row.name}
      pagination={{
        total,
        pageSize: currentPagination.pageSize,
        currentPage: currentPagination.currentPage,
      }}
      sorting={currentSorting}
    />
  );
}
