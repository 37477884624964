import { useParams } from "@tanstack/react-router";

const useDataMatrixRouteParams = () => {
  const { pvin, clusterId } = useParams({ strict: false });

  if (!pvin && !clusterId) console.warn("Invalid usage of DataMatrix features: no PVIN or ClusterID found.");

  return { pvin, clusterId };
};

export default useDataMatrixRouteParams;
