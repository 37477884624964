import { DownOutlined } from "@ant-design/icons";

import { CustomerMlDataVehicle, VehicleAnomalyState } from "@/api/customerApi";
import DropdownWidget from "@/components/ui/DropdownWidget";
import { formatNumber } from "@/utils/numberUtils";
import { EchartsTooltipFormatterFn } from "@/utils/types/EChartsDefinitions";

import { anomalyStateToColor, constrainScatterToolTip, ScatterParam, ScatterPoint } from "./ScatterChartUtils";

export type AxisOption = {
  key: string;
  label?: string;
};

type AxisSelectorProps = {
  currentVariable: string;
  options: AxisOption[];
  onChange: (variableKey: string) => void;
  label?: string;
};

export const AxisSelector = ({ currentVariable, options, onChange, label = "Axis:" }: AxisSelectorProps) => {
  const selectedVariableOption = options.find((e) => e.key === currentVariable);

  return (
    <DropdownWidget
      elements={options}
      onElementSelected={(key) => onChange(key)}
      label={`${label} ${selectedVariableOption?.label}`}
      buttonSize="middle"
      icon={<DownOutlined />}
      preselectedKey={selectedVariableOption?.key}
      triggerBtnClassName="multi-axis-selector"
    />
  );
};

export const buildScatterSeries = (
  xAxisIdx: number,
  yAxisIdx: number,
  vehiclesData: CustomerMlDataVehicle[]
): ScatterPoint[] => {
  return vehiclesData.map((v) => [
    v.values[xAxisIdx] ?? 0,
    v.values[yAxisIdx] ?? 0,
    {
      anomalyState: v.anomalyState,
      pvin: v.pvin,
    },
  ]);
};

type buildScatterChartConfigProps = {
  seriesData: ScatterPoint[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  onTooltip?: EchartsTooltipFormatterFn;
  tooltipSelector?: string;
};

export const buildGenericScatterChartConfig = ({
  seriesData,
  xAxisLabel,
  yAxisLabel,
  onTooltip,
  tooltipSelector,
}: buildScatterChartConfigProps) => {
  return {
    option: {
      grid: { left: 50, top: 30, right: 25, bottom: 50 },
      textStyle: {
        color: "#5F5F5F",
        fontFamily: "Hubot Sans",
      },
      xAxis: {
        name: xAxisLabel ?? "X",
        nameLocation: "middle",
        nameGap: 40,
        type: "value",
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 14,
          align: "center",
          verticalAlign: "middle",
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (value: any) => formatNumber(value, true),
          interval: 20,
          hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        name: yAxisLabel ?? "Y",
        nameLocation: "end",
        type: "value",
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: 14,
          align: "left",
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ebebeb",
          },
        },
        axisLine: {
          show: false,
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        enterable: onTooltip ? true : undefined,
        triggerOn: onTooltip ? "click" : undefined,
        padding: onTooltip ? 0 : undefined,
        className: tooltipSelector ? tooltipSelector.replaceAll(".", "") : "chart-library-tooltip-container",
        borderColor: "transparent",
        appendToBody: true,
        position: (
          point: [number, number],
          _params: any,
          _dom: any,
          _rect: any,
          size: { contentSize: [number, number]; viewSize: [number, number] }
        ) => {
          return constrainScatterToolTip(point, size);
        },
        formatter: onTooltip ?? undefined,
      },
      series: [
        {
          symbolSize: 10,
          data: seriesData,
          type: "scatter",
          itemStyle: {
            opacity: 0.8,
            color: (param: ScatterParam) => {
              const [, , e] = param.value;
              if (e && typeof e === "object" && "anomalyState" in e) {
                return anomalyStateToColor(e.anomalyState as VehicleAnomalyState);
              }
              return "#4290E1";
            },
          },
          emphasis: {
            scale: 1.25,
          },
        },
      ],
    },
  };
};
