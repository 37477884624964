import _ from "lodash";

import { Fault, IndependentVarRangeInput, TimeSeriesIndependentVar } from "@/api/customerApi";
import { FaultEventFilter } from "@/contexts/ChartLibraryStore";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { CommonDataDictionary } from "@/utils/types/commonDataDictionary";

import { getFaultYCategory } from "./ChartLibraryBuildUtils";

export const getAllFaultElementsInRange = (
  allFaultEvents: Fault[],
  selectedFaultFilters: FaultEventFilter[],
  independentVar: TimeSeriesIndependentVar,
  range: IndependentVarRangeInput | undefined
) => {
  const faultElements = allFaultEvents.filter((f) => {
    let isFaultInRange = true;

    // Early return for null odometer when using mileage
    if (independentVar === TimeSeriesIndependentVar.Mileage && f.odometer === null) {
      isFaultInRange = false;
    }
    // Check if we need to apply range filtering
    else if (range) {
      // Time-based filtering
      if (independentVar === TimeSeriesIndependentVar.Time && f.startTime) {
        isFaultInRange = _.inRange(new Date(f.startTime).getTime(), range.min, range.max);
      }
      // Odometer-based filtering
      else {
        isFaultInRange = _.inRange(f.odometer ?? 0, range.min, range.max);
      }
    }
    return (
      selectedFaultFilters.some(
        (selectedFilter) =>
          selectedFilter.code === f.code &&
          selectedFilter.troubleCode === f.troubleCode &&
          selectedFilter.source === f.source
      ) && isFaultInRange
    );
  });
  return faultElements;
};

export const getFaultElementsWithName = (faultElements: Fault[], customerIdentifier: CommonDataDictionary) => {
  return faultElements.map((f) => {
    const faultDescription = getFaultDescription(customerIdentifier, f.code, f.source, f.troubleCode ?? "");
    return { ...f, name: faultDescription?.name ?? "-", value: getFaultYCategory(f) };
  });
};

export const getUniqueFaultCodes = (faults: Fault[]) => {
  return _.uniq(faults.map((f) => `${f.code}${f.source}${f.troubleCode}`)).length;
};
