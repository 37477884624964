import { TabsProps } from "antd";
import { useDispatch } from "react-redux";

import { FeatureId, TimeSeriesFieldFamily } from "@/api";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import { CohortComparisonPlotOption, LibraryChart } from "@/contexts/ChartLibraryStore";
import { TimeSeriesFieldInputWithFamily } from "@/features/chartElementLibrary/types";
import { useFeatureFlags } from "@/utils/features";

import { chartSeriesSorter } from "./ChartLibraryBuildUtils";
import { PlottedSignalsCount } from "./ChartLibrarySettings";
import { StyledTabs } from "./ChartLibraryTabs.styled";
import { emptyStringAsUndef } from "./FieldPlotterHelper";
import LibraryFaultListSelector from "./LibraryFaultListSelector";
import LibrarySignalListSelector from "./LibrarySignalListSelector";

export type PlottedSignalData = {
  id: string;
  fieldInput?: TimeSeriesFieldInputWithFamily;
  comparison?: CohortComparisonPlotOption | CohortComparisonPlotOption[];
  isPlotted?: boolean;
  unit?: string;
};

interface ChartLibraryTabsProps {
  plottedCount: PlottedSignalsCount;
  activeChart?: LibraryChart;
}

const ChartLibraryTabs = ({ plottedCount, activeChart }: ChartLibraryTabsProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const totalSignalsPlotted = plottedCount.signal + plottedCount.expression + plottedCount.anomaly;

  const dispatch = useDispatch();
  const activeChartSeries = [...(activeChart?.series || [])].sort(chartSeriesSorter);

  const plottedSignals: PlottedSignalData[] =
    activeChartSeries.map((s) => {
      return {
        id: s.id,
        fieldInput: s.TimeSeriesFieldInput,
        comparison: s.comparisonType,
        isPlotted: true,
        unit: s.unit,
      };
    }) || [];

  const updatedPlottedSignal = (signalData: PlottedSignalData) => {
    dispatch(
      ChartLibraryActions.updatePlottedSignal({
        seriesId: signalData.id,
        newFieldInput: signalData.fieldInput,
        newComparison: signalData.comparison,
        removeComparison: !signalData.comparison,
      })
    );
  };

  const togglePlotSignal = (signalData: PlottedSignalData) => {
    dispatch(
      ChartLibraryActions.toggleSignal({
        selected: !!signalData.isPlotted,
        fieldId: signalData.id,
        fieldInput: signalData.fieldInput!,
        chart: activeChart?.chartId ?? "",
        comparison: emptyStringAsUndef(signalData.comparison ?? []),
        unit: signalData.unit,
      })
    );
  };

  const handlePlotOptionsChange = (signalsData: PlottedSignalData[]) => {
    signalsData.forEach((signalData) => {
      const plottedData = plottedSignals.find((signal) => signal.id === signalData.id);
      if (signalData.isPlotted && plottedData) {
        updatedPlottedSignal(signalData);
      } else {
        togglePlotSignal(signalData);
      }
    });
  };

  const filterTelemetryTabs = (tabs: TabsProps["items"]) => {
    return tabs?.filter((t) => {
      if (isFeatureEnabled(FeatureId.Telemetry)) {
        return true;
      } else if (t.key === "faults") {
        return true;
      }
    });
  };

  const libraryTabs: TabsProps["items"] = [
    {
      key: "active",
      label: (
        <div className="body-medium library-tab-label">
          Active {totalSignalsPlotted ? <div className="signal-counter">{totalSignalsPlotted}</div> : undefined}
        </div>
      ),
      children: (
        <LibrarySignalListSelector
          plottedSignals={plottedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          onlyPlotted
        />
      ),
    },
    {
      key: "signals",
      label: (
        <div className="body-medium library-tab-label">
          Signals {plottedCount.signal ? <div className="signal-counter">{plottedCount.signal}</div> : undefined}
        </div>
      ),
      children: (
        <LibrarySignalListSelector
          plottedSignals={plottedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.Signal}
          allowSearch
        />
      ),
    },
    {
      key: "expressions",
      label: (
        <div className="body-medium library-tab-label">
          Patterns{" "}
          {plottedCount.expression ? <div className="signal-counter">{plottedCount.expression}</div> : undefined}
        </div>
      ),
      children: (
        <LibrarySignalListSelector
          plottedSignals={plottedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.Expression}
          allowSearch
        />
      ),
    },
    {
      key: "anomalies",
      label: (
        <div className="body-medium library-tab-label">
          Anomalies {plottedCount.anomaly ? <div className="signal-counter">{plottedCount.anomaly}</div> : undefined}
        </div>
      ),
      children: (
        <LibrarySignalListSelector
          plottedSignals={plottedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.AnomalyScore}
          allowSearch
        />
      ),
    },
    {
      key: "faults",
      label: (
        <div className="body-medium library-tab-label">
          Faults {plottedCount.fault ? <div className="signal-counter">{plottedCount.fault}</div> : undefined}
        </div>
      ),
      children: <LibraryFaultListSelector />,
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey={totalSignalsPlotted ? "active" : "signals"}
      items={filterTelemetryTabs(libraryTabs)}
      className="library-tabs"
    />
  );
};

export default ChartLibraryTabs;
