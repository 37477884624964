import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import dayjs from "dayjs";
import { EChartsReactProps } from "echarts-for-react";
import { Dispatch, SetStateAction } from "react";

import { AnomalyDetector, TimeSeriesData } from "@/api/customerApi";
import { Maybe } from "@/api/generated/graphql";
import { humanFormatNumber } from "@/utils/numberUtils";

import { Coord } from "../card/expanded/inputs/Coord";
import { ANOMALY_CAUTION_COLOR, ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "./anomalyColors";
import { anomalyScoreToColor } from "./anomalyScoreToColor";
import { buildAnomalyMarkLines } from "./buildAnomalyMarkLines";

export function getAnomalyMultiplier(anomalyDetector: AnomalyDetector): number {
  return anomalyDetector.isCustomerMLModel ? 1 : 100;
}

export function buildVisualMapPiecesFromAnomalyDetector(
  anomalyDetector: AnomalyDetector,
  cautionThreshold: Maybe<number> | undefined,
  warningThreshold: Maybe<number> | undefined,
  minYValue: number,
  maxYValue: number
) {
  return cautionThreshold && warningThreshold
    ? [
        {
          min: cautionThreshold * getAnomalyMultiplier(anomalyDetector),
          max: warningThreshold * getAnomalyMultiplier(anomalyDetector),
          color: ANOMALY_CAUTION_COLOR,
        },
        {
          min: warningThreshold * getAnomalyMultiplier(anomalyDetector),
          color: ANOMALY_WARNING_COLOR,
        },
      ]
    : warningThreshold
    ? [
        {
          min: warningThreshold * getAnomalyMultiplier(anomalyDetector),
          max: Number.MAX_SAFE_INTEGER,
          color: ANOMALY_WARNING_COLOR,
        },
      ]
    : [
        {
          min: minYValue, // All safe because we don't have any thresholds
          max: maxYValue,
          color: ANOMALY_SAFE_COLOR,
        },
      ];
}

export const buildAnomalyChartConfig = (
  simpleSeries: TimeSeriesData,
  anomalyDetector: AnomalyDetector,
  isPreview: boolean,
  setHoverCoord?: Dispatch<SetStateAction<Coord | undefined>> | undefined
): EChartsReactProps => {
  const warningThreshold = anomalyDetector.warningThreshold;
  const cautionThreshold = anomalyDetector.cautionThreshold;
  const markAreas = simpleSeries.x.flatMap((x, i) => {
    const halfwayLine = (simpleSeries.x[i + 1] - x) / 2 + x;
    return [
      [{ name: i, xAxis: x }, { xAxis: halfwayLine }],
      [{ name: i + 1, xAxis: halfwayLine }, { xAxis: simpleSeries.x[i + 1] }],
    ];
  });
  const yValues = simpleSeries.x
    .map((x, i) => {
      const y = simpleSeries.y[0][i];
      return isNotNullOrUndefined(y) ? y * getAnomalyMultiplier(anomalyDetector) : y;
    })
    .filter((v) => typeof v === "number");
  const maxYValue = Math.max(...yValues);
  const minYValue = Math.min(...yValues);
  return {
    option: {
      animation: false,
      backgroundColor: "#f9fafe",
      grid: {
        top: isPreview ? 5 : 15,
        right: isPreview ? 5 : 40,
        bottom: isPreview ? 5 : 50,
        left: isPreview ? 5 : 40,
        show: false,
      },
      textStyle: {
        color: "#333",
        fontFamily: "Inter",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          axis: "x",
          lineStyle: {
            color: "#0094ff",
          },
          label: {
            show: false,
          },
        },
        appendToBody: true,
        formatter: (params: any) => {
          const rawScore = Number(params[0].value[1]);
          const xAxisValue = +params[0].value[0];
          const xCoordIndex =
            simpleSeries.x.map((v, i) => ({ v, i })).find(({ v }) => v == xAxisValue)?.i ?? simpleSeries.x.length - 1;
          const hoverCoord = { x: xCoordIndex, y: rawScore };
          setHoverCoord?.(hoverCoord);
          const yDisplay = rawScore === undefined ? "unknown" : humanFormatNumber(rawScore);
          const xDisplay = xAxisValue === undefined ? "unknown" : dayjs(xAxisValue).format("MMM DD[th] YYYY");
          const dotColor = anomalyScoreToColor(
            rawScore,
            warningThreshold ? warningThreshold * getAnomalyMultiplier(anomalyDetector) : undefined,
            cautionThreshold ? cautionThreshold * getAnomalyMultiplier(anomalyDetector) : undefined
          );
          const coloredDot = `<span style='height: 9px; width: 9px; background: ${dotColor}; border-radius: 50%; display: inline-block; margin-right: 12px'></span>`;
          return `${coloredDot}${xDisplay} ${yDisplay}`;
        },
      },
      xAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: (v: number) => {
              const xAxisValue = Number(v);
              const xDisplay = xAxisValue === undefined ? "unknown" : dayjs(xAxisValue).format("MM/DD/YY");
              return xDisplay;
            },
            show: !isPreview,
            rotate: -45,
            align: "left",
          },
          // The min is either the min of the x values or 90 days ago from the most recent x value
          // If there are no x values, set the min to 90 days ago from today
          min:
            simpleSeries.x.length > 0
              ? Math.min(
                  dayjs(simpleSeries.x[simpleSeries.x.length - 1])
                    .subtract(90, "days")
                    .valueOf(),
                  simpleSeries.x[0]
                )
              : dayjs().subtract(90, "days").valueOf(),
          // If there are no x values, set the max to today
          max: simpleSeries.x.length > 0 ? simpleSeries.x[simpleSeries.x.length - 1] : dayjs().valueOf(),
          data: simpleSeries.x,
          show: !isPreview,
        },
      ],
      yAxis: [
        {
          type: "value",
          min: 0,
          // customer detector models might not be normalized, allow graph to scale with data, possibly beyond 100
          max: anomalyDetector.isCustomerMLModel ? undefined : 100,
          show: !isPreview,
          axisLabel: {
            formatter: humanFormatNumber,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      visualMap: {
        show: false,
        pieces: buildVisualMapPiecesFromAnomalyDetector(
          anomalyDetector,
          cautionThreshold,
          warningThreshold,
          minYValue,
          maxYValue
        ),
        outOfRange: {
          color: ANOMALY_SAFE_COLOR,
        },
      },
      series: [
        {
          type: "line",
          symbol: "none",
          data: simpleSeries.x.map((x, i) => {
            const y = simpleSeries.y[0][i];
            return [x, isNotNullOrUndefined(y) ? y * getAnomalyMultiplier(anomalyDetector) : y];
          }),
          lineStyle: {
            width: 2,
          },
          markLine: buildAnomalyMarkLines(
            cautionThreshold ? cautionThreshold * getAnomalyMultiplier(anomalyDetector) : undefined,
            warningThreshold ? warningThreshold * getAnomalyMultiplier(anomalyDetector) : undefined,
            isPreview
          ),
          markArea: {
            data: [...markAreas],
            label: {
              show: false,
            },
            itemStyle: {
              opacity: 0,
            },
          },
        },
      ],
    },
  };
};
