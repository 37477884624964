import styled from "styled-components";

export const EditorContentWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  gap: 0.25rem;
  overflow: hidden;
  height: 80vh;

  .active-chart-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    overflow-y: auto;
  }

  .amaya-inline-drawer {
    height: 100%;
    .ant-tabs {
      .ant-tabs-content-holder {
        overflow-y: auto;
      }
    }
  }

  .library-chart-wrapper-with-padding {
    padding-right: 8px;
    flex: 1;
    min-height: 0;
  }

  .library-chart-container {
    flex: 1;
    min-height: 0;
  }
`;
