import { Link } from "@tanstack/react-router";
import { Skeleton } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import capitalize from "lodash/capitalize";
import React from "react";

import { EventType, RepairEventType, useClusterNameQuery, useRecentEventsQuery, useUserEventsQuery } from "@/api";
import { RepairEventNames } from "@/components/event/eventHeader/EventHeaderConstants";
import ExpandableTable from "@/components/tables/ExpandableTable";
import {
  DetailGroup,
  DetailLabel,
  DetailRow,
  DetailsPanel,
  DetailValue,
} from "@/components/tables/ExpandableTable.styles";
import { compareStrTimestamps, compareText } from "@/components/tables/sorting";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { QUERY_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { defaultDiagnosticTableState } from "@/routes/cluster.$clusterId";
import { defaultFaultTableState } from "@/routes/cluster.$clusterId";
import { getCurrentCustomerId, requireCurrentCustomerId } from "@/utils/customers";
import { parseDateToStr } from "@/utils/dateUtils";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { humanFriendlyStatuses } from "@/utils/patterns";

export const RecentlyViewedEventsTable = () => {
  const { customerIdentifier } = useCustomerIdentifier();
  const { data, isLoading } = useRecentEventsQuery({});
  const recentEvents =
    data?.userSettings?.customerSettings?.find((customer) => customer.customerId === getCurrentCustomerId())
      ?.recentEvents ?? [];

  // Getting user event info and cluster to hydrate recently viewed events
  const { data: userEvents } = useUserEventsQuery({
    customerId: getCurrentCustomerId() ?? "",
  });
  const clusterIds = recentEvents.map((event) => event.eventFilter?.fault?.clusterId).filter(Boolean) as string[];
  const { data: dataCluster, isLoading: isLoadingCluster } = useClusterNameQuery(
    { customerId: requireCurrentCustomerId() ?? "", ids: clusterIds ?? [] },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
      enabled: !!clusterIds,
    }
  );

  const events = recentEvents.map((event) => {
    const userEvent = userEvents?.userEvents?.expressionEvents.find(
      (e) => e.id === event.eventFilter?.expressionEventId
    );
    const cluster = dataCluster?.clusters?.clusters?.data?.find((c) => c.id === event.eventFilter?.fault?.clusterId);
    return { ...event, userEvent, cluster };
  });

  type RecentlyViewedEvent = (typeof events)[number];

  const getEventType = (event: RecentlyViewedEvent): EventType => {
    if (event.eventFilter?.expressionEventId) return EventType.Expression;
    if (event.eventFilter?.repairEventType) return EventType.Repair;
    // default to fault type if no other type is found
    return EventType.Fault;
  };

  const getEventName = (event: RecentlyViewedEvent) => {
    if (event.eventFilter?.expressionEventId) return event.userEvent?.name;
    else if (event.eventFilter?.repairEventType) return RepairEventNames[event.eventFilter?.repairEventType!];
    else if (event.eventFilter?.fault?.faultCode) {
      return (
        getFaultDescription(
          customerIdentifier,
          event.eventFilter?.fault?.faultCode ?? "",
          event.eventFilter?.fault?.ecu ?? "",
          event.eventFilter?.fault?.troubleCode ?? ""
        )?.name ?? event.eventFilter?.fault?.faultCode
      );
    } else {
      return "";
    }
  };

  const getEventTypeDisplay = (event: RecentlyViewedEvent): string => {
    const type = getEventType(event);
    return type === EventType.Expression ? "Pattern" : capitalize(type);
  };

  const buildEventLink = (event: RecentlyViewedEvent): React.ReactNode => {
    const type = getEventType(event);
    const eventName = getEventName(event);
    switch (type) {
      case EventType.Fault:
        return (
          <Link
            to="/fault/$faultCodeId"
            params={{ faultCodeId: event.eventFilter?.fault?.faultCode ?? "" }}
            search={(() => {
              if (!event.eventFilter) return undefined;

              const filter = {
                ecu: event.eventFilter.fault?.ecu || undefined,
                troubleCode: event.eventFilter.fault?.troubleCode || undefined,
                softwareVersion: event.eventFilter.fault?.softwareVersion || undefined,
                hardwareVersion: event.eventFilter.fault?.hardwareVersion || undefined,
                softwarePartNumber: event.eventFilter.fault?.softwarePartNumber || undefined,
                hardwarePartNumber: event.eventFilter.fault?.hardwarePartNumber || undefined,
                clusterId: event.eventFilter.fault?.clusterId?.toString() || undefined,
              };

              // Check if all filter values are undefined
              const hasValue = Object.values(filter).some((value) => value !== undefined);
              return hasValue ? { eventFilter: filter } : undefined;
            })()}
          >
            {eventName}
          </Link>
        );
      case EventType.Expression:
        return (
          <Link
            to="/pattern-event/$patternEventId"
            params={{ patternEventId: event.eventFilter?.expressionEventId ?? "" }}
          >
            {eventName}
          </Link>
        );
      case EventType.Repair:
        if (event.eventFilter?.repairEventType === RepairEventType.BatteryFullReplacement) {
          return <Link to="/repair/full">{eventName}</Link>;
        }
        return <Link to="/repair/partial">{eventName}</Link>;
      default:
        return <Link to="/error">{eventName}</Link>;
    }
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(getEventName(a), getEventName(b)),
      render: (_: string, row: RecentlyViewedEvent) => {
        return buildEventLink(row);
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      width: 120,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(getEventType(a), getEventType(b)),
      render: (_: string, row: RecentlyViewedEvent) => {
        return getEventTypeDisplay(row);
      },
    },
    {
      key: "lastViewed",
      title: "Last Viewed",
      dataIndex: "timestamp",
      width: 180,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareStrTimestamps(a.timestamp, b.timestamp),
      render: (_: string, row: RecentlyViewedEvent) => {
        return <FromNowFormatter value={new Date(row.timestamp)} />;
      },
    },
  ];

  // Renders details based on event type
  const renderEventDetails = (record: RecentlyViewedEvent, _index: number) => {
    const eventType = getEventType(record);

    switch (eventType) {
      case EventType.Fault:
        return (
          <DetailsPanel>
            <DetailRow>
              {record.eventFilter?.fault?.ecu && (
                <DetailGroup>
                  <DetailLabel>ECU</DetailLabel>
                  <DetailValue>{record.eventFilter?.fault?.ecu ?? "-"}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.troubleCode && (
                <DetailGroup>
                  <DetailLabel>Trouble Code</DetailLabel>
                  <DetailValue>{record.eventFilter.fault.troubleCode}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.softwareVersion && (
                <DetailGroup>
                  <DetailLabel>Software Version</DetailLabel>
                  <DetailValue>{record.eventFilter.fault.softwareVersion}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.softwarePartNumber && (
                <DetailGroup>
                  <DetailLabel>Software Part Number</DetailLabel>
                  <DetailValue>{record.eventFilter?.fault?.softwarePartNumber}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.hardwareVersion && (
                <DetailGroup>
                  <DetailLabel>Hardware Version</DetailLabel>
                  <DetailValue>{record.eventFilter?.fault?.hardwareVersion}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.hardwarePartNumber && (
                <DetailGroup>
                  <DetailLabel>Hardware Part Number</DetailLabel>
                  <DetailValue>{record.eventFilter?.fault?.hardwarePartNumber}</DetailValue>
                </DetailGroup>
              )}
              {record.eventFilter?.fault?.clusterId && (
                <DetailGroup>
                  <DetailLabel>Cluster</DetailLabel>
                  <DetailValue>
                    {isLoadingCluster ? (
                      <Skeleton.Input active />
                    ) : (
                      <Link
                        to="/cluster/$clusterId"
                        params={{ clusterId: record.cluster?.id ?? "" }}
                        search={{
                          clusterDiagnosticChecksTable: defaultDiagnosticTableState,
                          clusterFaultEventsTable: defaultFaultTableState,
                        }}
                      >
                        {record.cluster?.name}
                      </Link>
                    )}
                  </DetailValue>
                </DetailGroup>
              )}
            </DetailRow>
          </DetailsPanel>
        );
      case EventType.Expression:
        return (
          <DetailsPanel>
            <DetailRow>
              <DetailGroup>
                <DetailLabel>Status</DetailLabel>
                <DetailValue>{humanFriendlyStatuses(record.userEvent?.status)}</DetailValue>
              </DetailGroup>
              <DetailGroup>
                <DetailLabel>Pattern</DetailLabel>
                <DetailValue>
                  <Link
                    to="/pattern-event/$patternEventId"
                    params={{ patternEventId: record.userEvent?.expression?.id ?? "" }}
                  >
                    {record.userEvent?.expression?.name}
                  </Link>
                </DetailValue>
              </DetailGroup>
              <DetailGroup>
                <DetailLabel>Last Modified</DetailLabel>
                <DetailValue>
                  <FromNowFormatter value={new Date(record.userEvent?.lastModified ?? "")} />
                </DetailValue>
              </DetailGroup>
              <DetailGroup>
                <DetailLabel>Created</DetailLabel>
                <DetailValue>{parseDateToStr(new Date(record.userEvent?.created ?? ""), true)}</DetailValue>
              </DetailGroup>
            </DetailRow>
          </DetailsPanel>
        );
      default:
        return null;
    }
  };

  // Determine if a row should be expandable based on available content
  const isRowExpandable = (record: RecentlyViewedEvent) => {
    const eventType = getEventType(record);

    // Check if there's content to display based on event type
    switch (eventType) {
      case EventType.Fault:
        // Row is expandable if there's ECU, troubleCode, or version information
        return !!(
          record.eventFilter?.fault?.ecu ||
          record.eventFilter?.fault?.troubleCode ||
          record.eventFilter?.fault?.softwareVersion ||
          record.eventFilter?.fault?.hardwareVersion ||
          record.eventFilter?.fault?.softwarePartNumber ||
          record.eventFilter?.fault?.hardwarePartNumber ||
          record.eventFilter?.fault?.clusterId
        );
      case EventType.Expression:
        // Row is expandable if there's hydrated event info
        return !!record.userEvent;
      default:
        return false;
    }
  };

  return (
    <ExpandableTable
      dataSource={events}
      loading={isLoading}
      columns={columns}
      rowKey="timestamp"
      pagination={false as any}
      expandedRowRender={renderEventDetails}
      rowExpandable={isRowExpandable}
    />
  );
};
