import { configureStore, createSelector } from "@reduxjs/toolkit";
import { flatMap } from "lodash";

import {
  IndependentVarRangeInput,
  IntervalInput,
  TimeSeriesIndependentVar,
  VehicleCohortEpoch,
} from "@/api/customerApi";
import { TimeSeriesFieldInputWithFamily } from "@/features/chartElementLibrary/types";

import ChartLibrarySlice from "./ChartLibrarySlice";

export type FaultEventFilter = {
  code: string;
  troubleCode: string;
  source: string;
};

export interface ChartLibrarySliceState {
  chartLibrary: ChartLibraryState;
}

export interface ChartIndependentVariable {
  id: string;
  description: string;
  data: number[];
}

export interface ChartAxisGroup {
  id: string;
  name: string;
}

export interface CustomChartSeries {
  id: string;
  name: string;
  data: (number | null)[];
  yAxisId: string;
  // A plotted series is a signal field input
  TimeSeriesFieldInput: TimeSeriesFieldInputWithFamily;
  unit?: string;
  comparisonType?: CohortComparisonPlotOption | CohortComparisonPlotOption[];
  comparisonSeries?: "single" | "upper-boundary" | "lower-boundary";
  comparisonLabel?: string;
  anomalyThreshold?: {
    caution?: number | null | undefined;
    warning?: number | null | undefined;
  };
  xValues: number[];
  baseName: string;
}

export interface LibraryChart {
  chartId: string;
  series: CustomChartSeries[];
  axisData: ChartIndependentVariable;
  axisGroups: ChartAxisGroup[];
  isLoading: boolean;
  intervalInput?: IntervalInput;
  rangeInput?: IndependentVarRangeInput;
}

export interface ChartLibraryState {
  independentVariable: TimeSeriesIndependentVar;
  generalIntervalInput?: IntervalInput;
  generalRangeInput?: IndependentVarRangeInput;
  charts: LibraryChart[];
  faultFilters: FaultEventFilter[];
  activeChartId?: string;
  librarySettingsOpen?: boolean;
  coloringPoolRecord: Record<string, string>;
  timestampEpoch?: VehicleCohortEpoch;
}

export interface LightChartsState {
  chartId: string;
  seriesData: CustomChartSeries[];
  groups: ChartAxisGroup[];
}

export type CohortComparisonPlotOption = "mean" | "median" | "top-bottom-quartile" | "stddev" | "top-bottom-5perc";

export const cohortComparisonPlotOptions: {
  value: CohortComparisonPlotOption;
  label: string;
  isRangedComparison?: boolean;
}[] = [
  { value: "top-bottom-quartile", label: "Top & Bottom Quartiles", isRangedComparison: true },
  { value: "stddev", label: "+/- Std. Deviation", isRangedComparison: true },
  { value: "top-bottom-5perc", label: "Top & Bottom 5 percent", isRangedComparison: true },
  { value: "mean", label: "Mean" },
  { value: "median", label: "Median" },
];

const getLibraryIndependentVar = (state: ChartLibrarySliceState) => state.chartLibrary.independentVariable;
const getLibraryTimestampEpoch = (state: ChartLibrarySliceState) => state.chartLibrary.timestampEpoch;
const getLibraryCharts = (state: ChartLibrarySliceState) => state.chartLibrary.charts;
const getLibraryFaultFilters = (state: ChartLibrarySliceState) => state.chartLibrary.faultFilters;
const getLibraryActiveChartId = (state: ChartLibrarySliceState) => state.chartLibrary.activeChartId;
export const getColoringPoolRecord = (state: ChartLibrarySliceState) => state.chartLibrary.coloringPoolRecord;

export const selectEssentialLibraryState = createSelector(
  [getLibraryIndependentVar, getLibraryTimestampEpoch, getLibraryCharts, getLibraryFaultFilters, getColoringPoolRecord],
  (independentVariable, timestampEpoch, charts, faultFilters, coloringPoolRecord): ChartLibraryState => {
    return {
      independentVariable,
      timestampEpoch,
      charts,
      faultFilters,
      coloringPoolRecord,
    };
  }
);

export const selectIndependentVariable = createSelector(getLibraryIndependentVar, (variable) => {
  return { independentVariable: variable };
});

export const selectTimestampEpoch = createSelector(getLibraryTimestampEpoch, (epoch) => {
  return { timestampEpoch: epoch };
});

export const selectCharts = createSelector(getLibraryCharts, (charts) => {
  return { charts };
});

export const selectSeriesIds = createSelector(getLibraryCharts, (charts) => {
  return flatMap(charts, (c) => c.series.map((s) => s.id));
});

export const selectFaultFilters = createSelector(getLibraryFaultFilters, (faultFilters) => {
  return { faultFilters };
});

export const selectActive = createSelector(
  [getLibraryActiveChartId, (state: ChartLibrarySliceState) => state.chartLibrary.librarySettingsOpen],
  (activeChartId, librarySettingsOpen) => {
    return {
      activeChartId,
      isSettingsOpen: librarySettingsOpen,
    };
  }
);

export const selectActiveChartId = createSelector(getLibraryActiveChartId, (activeChartId) => {
  return {
    activeChartId,
  };
});

export const selectLibrarySpan = createSelector(
  [
    (state: ChartLibrarySliceState) => state.chartLibrary.generalIntervalInput,
    (state: ChartLibrarySliceState) => state.chartLibrary.generalRangeInput,
  ],
  (interval, range) => {
    return {
      intervalInput: interval,
      rangeInput: range,
    };
  }
);

const configureChartLibraryStore = (initialState?: ChartLibraryState) => {
  return configureStore({
    reducer: {
      chartLibrary: ChartLibrarySlice.reducer,
    },
    preloadedState: initialState ? { chartLibrary: initialState } : undefined,
  });
};

export default configureChartLibraryStore;
