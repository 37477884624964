import { ColumnType } from "antd/es/table";

import { DataSourceStatusInfo } from "@/api/customerApi";
import { parseDateToStr } from "@/utils/dateUtils";

import i18next from "../../i18n";
import { BasicTable } from "../tables/BasicTable";
interface DatabaseStatusProps {
  data?: DataSourceStatusInfo[];
  isLoading: boolean;
}

export default function DatabaseStatus({ data, isLoading }: DatabaseStatusProps) {
  const columns: ColumnType<DataSourceStatusInfo>[] = [
    {
      title: "Datasource Name",
      dataIndex: "dataSource",
      render: (dataSource) =>
        i18next.t(`dataSource.${dataSource}`) === `dataSource.${dataSource}`
          ? dataSource
          : i18next.t(`dataSource.${dataSource}`),
    },
    {
      title: "Data Most Recent Date",
      dataIndex: "mostRecentDataDate",
      render: (val) => parseDateToStr(new Date(val)),
    },
    {
      title: "Last Sync Date",
      dataIndex: "lastUpdatedDate",
      render: (val) => parseDateToStr(new Date(val)),
    },
  ];

  return (
    <BasicTable
      columns={columns}
      dataSource={data}
      rowKey={(ds) => ds.dataSource}
      pagination={false}
      loading={isLoading}
    />
  );
}
