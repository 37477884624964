import { Button } from "antd";
import { EChartsInstance } from "echarts-for-react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import {
  AffectedVehicleAttributes,
  FailureCountByDistance,
  OdometerBounds,
  useDistanceVersusFailureAndRepairCountPvinsQuery,
  useDistanceVersusFailureAndRepairCountQuery,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { PlottedSignalIcon } from "@/features/chartElementLibrary/LibraryChartToolbox";
import { ChartSerieslegendContainer } from "@/features/chartElementLibrary/LibraryChartToolbox.styled";
import { DefinedView } from "@/features/cluster/clusterModal/ClusterModal";
import { Route } from "@/routes/cluster.$clusterId";
import { formatNumber } from "@/utils/numberUtils";
import unitLabel from "@/utils/timeSeriesUnits";
import { toTitleCase } from "@/utils/toTitleCase";

import { ClusterViewGraphHeader } from "../../../features/cluster/clusterView/ClusterView.styled";
import { ClusterChartButtonsContainer } from "./ClusterChart.styled";
import { getClusterChartLayoutConfig } from "./clusterChartHelpers";

type ClusterDistanceVsFailureCountProps = {
  setAffectedPvins: Dispatch<React.SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  maintenaceLogs: boolean;
};

const FAULT_COLOR = "#5470c6";
const REPAIR_COLOR = "#91cc75";
const loadingPlaceHolder = {
  distanceStart: 0,
  distanceEnd: 0,
  faultCount: 0,
  faultPvinCount: 0,
  maintenanceCount: 0,
  maintenancePvinCount: 0,
};
const buildChartConfig = (dataArgs: FailureCountByDistance[], isLoading: boolean, maintenanceEvents: boolean) => {
  const { xNameGap, yNameGap, toolbox, dataZoom, grid, position } = getClusterChartLayoutConfig();
  const data = isLoading ? [loadingPlaceHolder] : dataArgs;
  return {
    animation: false,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      position,
      formatter: (params: { dataIndex: number; color: string }[]) => {
        const dataInfo = data[params[0].dataIndex];
        const circle = (color: string) =>
          `<div style='background-color: ${color}; height: 10px; width: 10px; margin-right: 5px; border-radius: 50%'></div>`;
        if (dataInfo) {
          return `<div>
          <div>
          From ${formatNumber(dataInfo.distanceStart)} km to ${formatNumber(dataInfo.distanceEnd)} km
          </div>
          <div style='border-bottom: 1px solid #dedede'></div>
        <br/>
        <div style='display: flex; align-items: center'>
           ${circle(params[0].color)} Fault Count: ${dataInfo.faultCount}
        </div>
       ${
         maintenanceEvents
           ? `<div style='display: flex; align-items: center'>
          ${circle(params[1].color)} Repair Count: ${dataInfo.maintenanceCount}
        </div>`
           : ""
       }
        </div>`;
        }
      },
    },
    toolbox,
    dataZoom,
    grid: {
      ...grid,
      bottom: 45,
      left: "10%",
      right: "10%",
    },
    xAxis: {
      show: true,
      nameGap: xNameGap,
      name: unitLabel("KM"),
      nameLocation: "middle",
      type: "category",
      data: data.map((d) => d.distanceStart),
      axisLabel: {
        show: true,
        margin: 5,
        formatter: (value: number) => formatNumber(value),
      },
    },
    yAxis: [
      {
        show: true,
        type: "value",
        name: "Fault Count",
        nameLocation: "middle",
        position: "left",
        alignTicks: true,
        nameGap: yNameGap,
        axisLabel: {
          show: true,
        },
      },
      {
        show: maintenanceEvents,
        type: "value",
        name: "Repair Count",
        nameLocation: "middle",
        position: "right",
        nameGap: yNameGap,
        axisLabel: {
          show: true,
        },
      },
    ],
    series: [
      {
        name: "Faults",
        type: "bar",
        data: data.map((d) => d.faultCount),
        yAxisIndex: 0,
        color: FAULT_COLOR,
      },
      ...(maintenanceEvents
        ? [
            {
              name: "Repair Events",
              type: "bar",
              data: data.map((d) => d.maintenanceCount),
              yAxisIndex: 1,
              color: REPAIR_COLOR,
            },
          ]
        : []),
    ],
  };
};

export const ClusterDistanceVsFailureCount = ({
  setAffectedPvins,
  setModalView,
  setLoading,
  maintenaceLogs,
}: ClusterDistanceVsFailureCountProps) => {
  const { clusterId } = Route.useParams();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [zoomRange, setZoomRange] = useState<{ min: number; max: number }>();
  const [clickedRange, setClickedRange] = useState<{ min: number | undefined; max: number | undefined }>();
  const { data, isLoading, isFetching } = useDistanceVersusFailureAndRepairCountQuery(
    {
      clusterId,
      filter: globalFilter,
      odometerBounds: zoomRange,
    },
    { enabled: !!clusterId }
  );

  const { data: pvins } = useDistanceVersusFailureAndRepairCountPvinsQuery(
    {
      clusterId,
      filter: globalFilter,
      odometerBounds: clickedRange?.max && clickedRange?.min ? (clickedRange as OdometerBounds) : undefined,
    },
    { enabled: !!clusterId && !!clickedRange }
  );
  useEffect(() => {
    if (pvins && !!clickedRange) {
      setClickedRange(undefined);
      setLoading(false);
      setAffectedPvins(pvins.filteredQuery.distanceVersusFailureAndRepairCountPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins, clickedRange]);

  const handleBackgroundClick = (params: any, c: EChartsInstance) => {
    let pointInPixel = [params.offsetX, params.offsetY];
    let pointInGrid = c.convertFromPixel("grid", pointInPixel);
    const clickedData = data?.filteredQuery.distanceVersusFailureAndRepairCount[pointInGrid[0]];
    if (clickedData) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setClickedRange({ min: clickedData?.distanceStart, max: clickedData?.distanceEnd });
    }
  };

  const getAllPvinsInGraph = () => {
    const dataArr = data?.filteredQuery.distanceVersusFailureAndRepairCount;
    if (dataArr) {
      setLoading(true);
      setModalView("vehiclesAffected");
      setClickedRange({ min: dataArr[0].distanceStart, max: dataArr[dataArr.length - 1].distanceEnd });
    }
  };

  return (
    <BasicWidget
      title={
        <ClusterViewGraphHeader>
          <div>{toTitleCase(`Distance vs Fault ${maintenaceLogs ? "And Repair" : ""} Count`)}</div>
          {zoomRange && (
            <ClusterChartButtonsContainer>
              <Button disabled={isLoading} onClick={() => getAllPvinsInGraph()}>
                View Vehicles
              </Button>
              <Button onClick={() => setZoomRange(undefined)}>Reset Graph</Button>
            </ClusterChartButtonsContainer>
          )}
        </ClusterViewGraphHeader>
      }
    >
      <ChartSerieslegendContainer style={{ marginLeft: "20px", marginTop: "10px" }}>
        <div key={"fault"} className="signal-element">
          <PlottedSignalIcon color={FAULT_COLOR} />
          <p className="signal-name body-x-small dark">{"Fault"}</p>
        </div>
        {maintenaceLogs && (
          <div key={"repair"} className="signal-element">
            <PlottedSignalIcon color={REPAIR_COLOR} />
            <p className="signal-name body-x-small dark">{"Repair"}</p>
          </div>
        )}
      </ChartSerieslegendContainer>
      <BaseEChart
        loadingOption={{ showLoading: false }}
        option={buildChartConfig(
          data?.filteredQuery.distanceVersusFailureAndRepairCount ?? [],
          isFetching,
          maintenaceLogs
        )}
        onChartReady={(c) => {
          c.getZr().on("click", (params: any) => handleBackgroundClick(params, c));
          c.dispatchAction({
            type: "takeGlobalCursor",
            key: "dataZoomSelect",
            dataZoomSelectActive: true,
          });
        }}
        showLoading={isFetching || isLoading}
        showLoadingMask={false}
        onEvents={{
          datazoom: (zoomData: { batch: { endValue: number; startValue: number }[] }) => {
            const startValue = zoomData.batch[0]?.startValue;
            const endValue = zoomData.batch[0]?.endValue;
            const countData = data?.filteredQuery.distanceVersusFailureAndRepairCount;
            const min = countData?.[startValue].distanceStart;
            const max = countData?.[endValue].distanceEnd;
            if (min && max) {
              setZoomRange({ min, max });
            }
          },
        }}
      />
    </BasicWidget>
  );
};
