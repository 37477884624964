// Load polyfills before anything else
import "./utils/polyfills";
import "./integrations/sentry";

import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";

import App from "./App";
import FallbackPage from "./pages/FallbackPage";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary fallbackRender={(fallbackProps) => <FallbackPage {...fallbackProps} />}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
