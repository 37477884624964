import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";

import { EmptyComponent } from "@/components/ui/EmptyComponent";
import { defaultTheme } from "@/styles/Global.styled";

interface EmptyComponentProps {
  description?: string;
}

export const EmptyComponentSCV = ({ description }: EmptyComponentProps) => {
  return (
    <EmptyComponent
      image={<ExclamationCircleOutlined />}
      imageStyle={{
        fontSize: 56,
        backgroundColor: defaultTheme.colors.secondary,
        borderRadius: "50%",
        lineHeight: 1,
        color: defaultTheme.colors.smoothGray,
      }}
      description={description}
    />
  );
};
