import LeftBraceIcon from "@amayaIcons/operators/LeftBrace.svg?react";
import RightBraceIcon from "@amayaIcons/operators/RightBrace.svg?react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CaseExpression, Expression } from "@lib/src/expression/types";
import { isEmpty, isEqual, range } from "lodash";
import { KeyboardEvent, useContext, useState } from "react";
import { PartialDeep } from "type-fest";

import { PatternFocusContext } from "@/contexts/PatternFocusContext";

import { nodeTypeHasWrapping } from "../TypeaheadUtils";
import NodeWrapper from "./NodeWrapper";
import { PatternFocusedNode } from "./PatternFocusedNode";
import PatternNode from "./PatternNode";
import { PatternNodeContainer } from "./PatternNode.styled";

type PatternCaseNodeProps = {
  node: CaseExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
};

const handleDelete = (
  e: KeyboardEvent<HTMLDivElement>,
  node: CaseExpression,
  path: string[],
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void,
  setMostRecentPath: (s: string[] | undefined) => void,
  i?: number
) => {
  if (e.code === "Backspace" && (e.target as HTMLInputElement).value === "") {
    // Both when and then are empty in same index
    if (i !== undefined && isEmpty(node.when[i]) && isEmpty(node.then[i])) {
      // If there is only 1 when/then is avaliable delete the whole operation
      // or save the else path
      if (node.when.length === 1) {
        if (isEmpty(node.else)) {
          onChange({}, path);
        } else {
          onChange({ ...node.else }, path);
        }
      } else {
        // if there are more than 1 when/then then just remove the case at index i
        const newNode = { ...node };
        newNode.when = [...node.when.slice(0, i), ...node.when.slice(i + 1)];
        newNode.then = [...node.then.slice(0, i), ...node.then.slice(i + 1)];
        onChange(newNode, path);
        setMostRecentPath([...path, "when", `${newNode.when.length - 1}`]);
      }
    } else {
      // have to check if all when, then and else to see if only 1 value
      // remains. If only 1 that becomes the new node.
      const nonEmptyNodes = [...node.when, ...node.then, node.else].filter((n) => !isEmpty(n));
      if (nonEmptyNodes.length === 1) {
        onChange({ ...nonEmptyNodes[0] }, path);
      } else if (nonEmptyNodes.length === 0) {
        onChange({}, path);
      }
    }
  }
};

type WhenBaseCaseProps = {
  i: number;
  node: CaseExpression;
  path: string[];
  onChange: (newValue: PartialDeep<Expression>, path: string[]) => void;
  disabled: boolean;
  setMostRecentPath: (s: string[] | undefined) => void;
  areCaseValuesInvalid: (type: string, index: number) => "error" | undefined;
};

const WhenBaseCase = ({
  i,
  node,
  path,
  onChange,
  disabled,
  setMostRecentPath,
  areCaseValuesInvalid,
}: WhenBaseCaseProps) => {
  const [ifHover, setIfHover] = useState(false);

  const ifSymbolClassname = `bracket-symbol${ifHover ? " highlighted" : ""}`;

  return (
    <>
      <div className="inline-node-section">
        <span className="fn-name-label">{"WHEN"}</span>
        <NodeWrapper hidden={nodeTypeHasWrapping(node.when[i].type)}>
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "when", i.toString()]}
            node={node.when[i]}
            deleteFunction={(e) => handleDelete(e, node, path, onChange, setMostRecentPath, i)}
            disabled={disabled}
            status={areCaseValuesInvalid("when", i)}
          />
        </NodeWrapper>
        <span
          className={ifSymbolClassname}
          onMouseEnter={() => setIfHover(true)}
          onMouseLeave={() => setIfHover(false)}
        >
          <LeftBraceIcon />
        </span>
      </div>
      <div className="indented-node-section">
        {/* Then */}
        <PatternNode
          onNodeChange={onChange}
          path={[...path, "then", i.toString()]}
          node={node.then[i]}
          disabled={disabled}
          deleteFunction={(e) => handleDelete(e, node, path, onChange, setMostRecentPath, i)}
          status={areCaseValuesInvalid("then", i)}
        />
      </div>
      <span className={ifSymbolClassname} onMouseEnter={() => setIfHover(true)} onMouseLeave={() => setIfHover(false)}>
        <RightBraceIcon />
      </span>
    </>
  );
};

export const PatternCaseNode = ({ node, path, onChange, disabled }: PatternCaseNodeProps) => {
  const { mostRecentPath, setMostRecentPath } = useContext(PatternFocusContext);

  const [elseHover, setElseHover] = useState(false);

  const elseSymbolClassname = `bracket-symbol${elseHover ? " highlighted" : ""}`;

  const addCase = () => {
    const newNode = { ...node };
    newNode.when = [...node.when, {} as Expression];
    newNode.then = [...node.then, {} as Expression];
    onChange(newNode, path);
    setMostRecentPath([...path, "when", `${newNode.when.length - 1}`]);
  };

  const removeCase = () => {
    const newNode = { ...node };
    newNode.when = [...node.when.slice(0, -1)];
    newNode.then = [...node.then.slice(0, -1)];
    onChange(newNode, path);
    setMostRecentPath([...path, "when", `${newNode.when.length - 1}`]);
  };

  const handleBtnMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const areCaseValuesInvalid = (valueType: string, valueIndex: number) => {
    const isMostRecentPathInFamily = () => {
      if (isEqual(mostRecentPath, path)) return true;
      if (isEqual(mostRecentPath, [...path, "else"])) return true;
      for (let i = 0; i < node.when.length; i++) {
        if (isEqual([...path, "when", `${i}`], mostRecentPath) || isEqual([...path, "then", `${i}`], mostRecentPath)) {
          return true;
        }
      }

      return false;
    };
    if (isMostRecentPathInFamily()) {
      return;
    } else if (valueType !== "else") {
      return isEmpty(node[valueType as "when"]?.[valueIndex] ?? {}) ? "error" : undefined;
    } else {
      return isEmpty(node["else"]) ? "error" : undefined;
    }
  };

  return (
    <PatternFocusedNode node={node} onChange={onChange} path={path} disabled={disabled}>
      <PatternNodeContainer className="case-statement">
        {range(node.when.length).map((statement) => {
          return (
            <WhenBaseCase
              key={statement}
              i={statement}
              node={node}
              path={path}
              onChange={onChange}
              disabled={disabled}
              setMostRecentPath={setMostRecentPath}
              areCaseValuesInvalid={areCaseValuesInvalid}
            />
          );
        })}
        <div className="case-statement-controls">
          <PlusCircleOutlined onClick={addCase} onMouseDown={handleBtnMouseDown} />
          {node.when.length > 1 && <MinusCircleOutlined onClick={removeCase} onMouseDown={handleBtnMouseDown} />}
        </div>
        <div className="centered-container">
          <span className="fn-name-label">{"ELSE"}</span>
          <span
            className={elseSymbolClassname}
            onMouseEnter={() => setElseHover(true)}
            onMouseLeave={() => setElseHover(false)}
          >
            <LeftBraceIcon />
          </span>
        </div>
        <div className="indented-node-section">
          {/* Else */}
          <PatternNode
            onNodeChange={onChange}
            path={[...path, "else"]}
            node={node.else}
            disabled={disabled}
            deleteFunction={(e) => handleDelete(e, node, path, onChange, setMostRecentPath)}
            status={areCaseValuesInvalid("else", -1)}
          />
        </div>
        <span
          className={elseSymbolClassname}
          onMouseEnter={() => setElseHover(true)}
          onMouseLeave={() => setElseHover(false)}
        >
          <RightBraceIcon />
        </span>
      </PatternNodeContainer>
    </PatternFocusedNode>
  );
};
