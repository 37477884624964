import i18n from "@/i18n";

/**
 * Translates a time series unit string ("km/h", "Volt", etc.) to a user-readable label.
 */
export default function unitLabel(unit?: string, defaultLabel: string = "") {
  if (!unit) {
    return defaultLabel;
  }
  const key = `timeSeriesUnits.${unit.toUpperCase()}`;
  const ret = i18n.t(key);
  // If we don't have a translation, return the unit string itself
  return ret && ret != key ? ret : unit;
}
