import styled from "styled-components";

export const AnalysisTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .widget-title {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .axis-selectors {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const AnalysisChartContainer = styled.div`
  padding: 1.5rem;
`;
