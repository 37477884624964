import { useState } from "react";

import { AggregateType } from "@/api";
import { DiagnosticCheckType } from "@/api/customerApi";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";

import useDataMatrixRouteParams from "./useDataMatrixRouteParams";

const useHDMClusterSettings = () => {
  const { clusterId } = useDataMatrixRouteParams();
  const { customerIdentifier } = useCustomerIdentifier();
  const [dcType, setDcType] = useState(DiagnosticCheckType.First);
  const [batteryType, setBatteryType] = useState<number>(customerIdentifier.batteryModules?.default.modules[0] ?? 0);
  const [aggregateType, setAggregateType] = useState<AggregateType>(AggregateType.Mean);

  return {
    dcType: clusterId ? dcType : undefined,
    batteryType: clusterId ? batteryType : undefined,
    aggregateType: clusterId ? aggregateType : undefined,
    setDcType,
    setBatteryType,
    setAggregateType,
  };
};

export default useHDMClusterSettings;
