import { AggregateType } from "@/api";
import { DiagnosticCheckType } from "@/api/customerApi";
import { BasicWidget } from "@/components/widget/BasicWidget";

import { HDMComparison } from "./HDMComparison";
import { HistoricDataMatrixHistoryChart } from "./HistoricDataMatrixHistoryChart";
import useDataMatrixRouteParams from "./useDataMatrixRouteParams";
import useDataMatrixState from "./useDataMatrixState";
import useHDMClusterSettings from "./useHDMClusterSettings";

const HDMAllowedAggregateTypes = {
  Mean: AggregateType.Mean,
  Median: AggregateType.Median,
  Std: AggregateType.Std,
};

export const getHDMAllowedAggregateTypes = (pvin?: string | undefined) => {
  const allowedTypes: Partial<typeof HDMAllowedAggregateTypes> = { ...HDMAllowedAggregateTypes };
  if (pvin) delete allowedTypes["Std"];
  return allowedTypes;
};

export const aggregateTypeFromDefaultAggregation = (defualtAggregation: string) => {
  if (defualtAggregation === "Sum") {
    return AggregateType.Sum;
  }
  return AggregateType.Mean;
};

export const getAllowedDiagnosticChecks = (
  withMaintenanceHistory: boolean | undefined
): Partial<Record<keyof typeof DiagnosticCheckType, DiagnosticCheckType>> => {
  if (withMaintenanceHistory) {
    return { Last: DiagnosticCheckType.Last, First: DiagnosticCheckType.First };
  }
  return {
    First: DiagnosticCheckType.First,
  };
};

export type DataMatrixProps = {
  withMaintenanceHistory?: boolean;
};

export const HistoricDataMatrix = ({ withMaintenanceHistory }: DataMatrixProps) => {
  const { pvin } = useDataMatrixRouteParams();
  const { dms } = useDataMatrixState();
  const { dcType, setDcType, batteryType, setBatteryType } = useHDMClusterSettings();

  const titleEntity = pvin ? "vehicle" : "cluster";
  const showComparisonSection = pvin ? dms?.tableId && dms?.selectedMatrix : true;

  return (
    <div>
      <BasicWidget title={"HDM History"} subtitle={`Access HDM Values for the ${titleEntity}`}>
        <HistoricDataMatrixHistoryChart
          dcType={dcType}
          setDcType={setDcType}
          batteryType={batteryType}
          setBatteryType={setBatteryType}
          withMaintenanceHistory={withMaintenanceHistory}
        />
      </BasicWidget>
      {showComparisonSection ? <HDMComparison dcType={dcType} batteryType={batteryType} /> : undefined}
    </div>
  );
};
