import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { SortOrder } from "@/api";
import { FaultHistorySortableColum } from "@/api/customerApi";
import { TableState } from "@/components/tables/StatefulTable.types";
import { DataMatrixState } from "@/features/historicDataMatrix/useDataMatrixState";
import VehicleDetails from "@/features/vehicleDetails/VehicleDetails";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: FaultHistorySortableColum.StartTime, order: SortOrder.Descend },
};

export type AnomalyHomeSearch = {};

export type VehicleSearch = {
  chartLibrary?: string;
  faultHistoryTable?: TableState;
  dataMatrix?: DataMatrixState;
};

export const Route = createFileRoute("/vehicle/$pvin")({
  component: () => (
    <AppMainLayout>
      <VehicleDetails />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Vehicle Detail"),
  validateSearch: (search: Record<string, unknown>): VehicleSearch => ({
    chartLibrary: (search.chartLibrary as string) || undefined,
    faultHistoryTable: (search.faultHistoryTable as TableState) || defaultTableState,
    dataMatrix: (search.dataMatrix as DataMatrixState) || undefined,
  }),
});
