import { TimeSeriesFieldsQuery } from "@/api";
import { Fault, TimeSeriesIndependentVar, VehicleCohortEpoch } from "@/api/customerApi";
import { ChartLibraryState } from "@/contexts/ChartLibraryStore";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { removeFromLocalStorage } from "@/utils/localStorageUtils";
import { CommonDataDictionary } from "@/utils/types/commonDataDictionary";

import { getFaultYCategory } from "../chartElementLibrary/ChartLibraryBuildUtils";
import { TimeSeriesFieldInputWithFamily } from "../chartElementLibrary/types";

type TimeSeriesField = TimeSeriesFieldsQuery["timeSeriesFields"][number];

const chartLibraryStateKey = "chartLibrary";

export const validateTimeSeriesFieldAgainstFieldInput = (
  timeSeriesField: TimeSeriesField,
  fieldInput: TimeSeriesFieldInputWithFamily
) => {
  // id has to match to get to this function, so no need to check again.
  if (timeSeriesField.family !== fieldInput.family) return false;
  if (!timeSeriesField.aggregationTypes && fieldInput.aggregateType) return false;
  if (!timeSeriesField.aggregationTypes?.includes(fieldInput.aggregateType)) return false;
  return true;
};

export const validateState = (
  state: ChartLibraryState | undefined,
  data: TimeSeriesFieldsQuery | undefined,
  telematicsless: boolean,
  customerIdentifier: CommonDataDictionary,
  defaultTimestampEpoch?: VehicleCohortEpoch,
  faults?: Fault[]
) => {
  let validated = true;
  if (!data && !telematicsless) return;
  if (state) {
    const availableTimeSeriesFields: Record<string, TimeSeriesField> = {};
    data?.timeSeriesFields.forEach((timeSeries) => (availableTimeSeriesFields[timeSeries.id] = timeSeries));
    // Automatically adjust comparison mode to PVIN default
    if (state.independentVariable === TimeSeriesIndependentVar.Time) {
      if (defaultTimestampEpoch) {
        state = { ...state, timestampEpoch: defaultTimestampEpoch };
      } else {
        state = {
          ...state,
          timestampEpoch: undefined,
          charts: state.charts.map((chart) => {
            return {
              ...chart,
              series: chart.series.map((series) => {
                return {
                  ...series,
                  comparisonLabel: undefined,
                  comparisonType: undefined,
                  comparisonSeries: undefined,
                };
              }),
            };
          }),
        };
      }
    }
    for (const chart of state.charts) {
      if (!validated) return;
      for (const series of chart.series) {
        if (
          !availableTimeSeriesFields[series.id] ||
          !validateTimeSeriesFieldAgainstFieldInput(availableTimeSeriesFields[series.id], series.TimeSeriesFieldInput)
        ) {
          if (localStorage) {
            removeFromLocalStorage(chartLibraryStateKey);
          }
          validated = false;
          return;
        }
      }
    }
    for (const faultFilter of state.faultFilters ?? []) {
      if (!validated) return;
      if (!getFaultDescription(customerIdentifier, faultFilter.code, faultFilter.source, faultFilter.troubleCode)) {
        validated = false;
        if (localStorage) {
          removeFromLocalStorage(chartLibraryStateKey);
        }
        return;
      }
    }
    const existingFaults =
      state.faultFilters?.filter((v) => faults?.some((f) => getFaultYCategory(f) === getFaultYCategory(v))) || [];
    return {
      ...state,
      faultFilters: existingFaults,
    };
  }
  return undefined;
};
