import { QuestionCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Col, notification, Popconfirm, Popover, Row } from "antd";
import { useContext, useState } from "react";

import {
  ClusterUpdateAction,
  FeatureId,
  Pagination,
  useClusterDetailsQuery,
  useClusterNonVehicleDetailsQuery,
  useListClustersQuery,
  useUpdateClusterMutation,
} from "@/api";
import {
  AffectedVehicleAttributes,
  AttributeTypeEnum,
  ClusterMetadata,
  ClusterRedshiftCreateInput,
  useAttributeCountQuery,
  useDistanceVersusFailureAndRepairCountQuery,
  useFaultsByPvinsQuery,
  useVehicleMetadataQuery,
} from "@/api/customerApi";
import { ClusterAttributeCount } from "@/components/charts/cluster/ClusterAttributeCount";
import { ClusterDistanceVsFailureCount } from "@/components/charts/cluster/ClusterDistanceVsFailureAndRepairCount";
import Loading from "@/components/loading";
import MetricCard from "@/components/metricCard/MetricCard";
import MetricCardContainer from "@/components/metricCard/metricCardContainer";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { HistoricDataMatrix } from "@/features/historicDataMatrix/HistoricDataMatrix";
import { useVinLabel } from "@/hooks/useVinLabel";
import i18next from "@/i18n";
import Page404 from "@/pages/404";
import { Route } from "@/routes/cluster.$clusterId";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";

import { ClusterModal, DefinedView } from "../clusterModal/ClusterModal";
import { ClusterViewVehicleTable, ClusterViewWrapper, TableTitle, VehicleHelperButtons } from "./ClusterView.styled";
import { ClusterViewHeader } from "./ClusterviewHeader";
import { ClusterDiagnosticCheckTable } from "./DiagnosticCheckTable";
import { FaultEventsWidget } from "./FaultEventsWidget";
export type ClusterViewParams = {
  id: string;
};

export const ClusterView = () => {
  const { clusterId } = Route.useParams();
  const { isFeatureEnabled } = useFeatureFlags();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [isDeletingPvins, setIsDeletingPvins] = useState(false);
  const [modalView, setModalView] = useState<DefinedView>();
  const queryClient = useQueryClient();
  const updateCluster = useUpdateClusterMutation();
  const vinLabel = useVinLabel();
  const [api, context] = notification.useNotification();
  const [affectedPvins, setAffectedPvins] = useState<AffectedVehicleAttributes[]>();
  const [selectedPvins, setSelectedPvins] = useState<ClusterMetadata[]>([]);
  const [pvinsLoading, setPvinsLoading] = useState(false);

  const diagnosticCheckNavigate = Route.useNavigate();

  const pagination: Pagination = {
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const clusterQueryParams = {
    customerId: getCurrentCustomerId() ?? "",
    id: clusterId,
    filter: globalFilter,
  };

  const { data: nonVehicleData, isFetching: isFetchingClusterNonVehicleData } = useClusterNonVehicleDetailsQuery({
    id: clusterId,
    customerId: clusterQueryParams.customerId,
  });
  const clusterNonVehicleData = nonVehicleData?.clusters?.cluster;

  const { data: clusterData, isLoading, isFetching } = useClusterDetailsQuery(clusterQueryParams);
  const cluster = clusterData?.clusters?.clusters?.data[0];
  const invalidateQueriesAndResetPagination = async () => {
    await diagnosticCheckNavigate({
      search: (prev) => ({
        ...prev,
        clusterDiagnosticChecksTable: { pagination: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE } },
        clusterFaultEventsTable: { pagination: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE } },
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useClusterNonVehicleDetailsQuery.getKey({
        id: clusterId,
        customerId: getCurrentCustomerId() ?? "",
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useClusterDetailsQuery.getKey(clusterQueryParams),
    });
    Object.values(AttributeTypeEnum).forEach((attribute) => {
      queryClient.invalidateQueries({
        queryKey: useAttributeCountQuery.getKey({
          clusterId: clusterId ?? "",
          filter: globalFilter,
          attribute,
        }),
      });
    });
    queryClient.invalidateQueries({
      queryKey: useDistanceVersusFailureAndRepairCountQuery.getKey({
        clusterId: cluster?.id ?? "",
        filter: globalFilter,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useFaultsByPvinsQuery.getKey({
        clusterId: cluster?.id ?? "",
        filter: globalFilter,
        pagination,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useVehicleMetadataQuery.getKey({
        filter: globalFilter,
        clusterId: cluster?.id || "",
        pagination,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: useListClustersQuery.getKey({
        customerId: getCurrentCustomerId() ?? "",
        filter: globalFilter,
      }),
    });
    queryClient.invalidateQueries({
      queryKey: ["dataMatrixTableHistory"],
      exact: false,
    });
  };

  const handleDeleteActivePvins = () => {
    if (selectedPvins.length === cluster?.combinationCount) {
      api.warning({
        message: `If you want to delete all the ${vinLabel}s, delete the cluster`,
      });
      return;
    }

    const pvinsToDelete: ClusterRedshiftCreateInput[] = selectedPvins.map(({ pvin, startDate, endDate, vin }) => {
      return {
        pvin: isFeatureEnabled(FeatureId.Vins) ? vin! : pvin,
        startDate,
        endDate,
      };
    });

    handleDeletePvins(pvinsToDelete, false);
  };

  const handleDeleteMissingPvins = (pvinsToDelete: ClusterRedshiftCreateInput[]) => {
    handleDeletePvins(pvinsToDelete, true);
  };

  const handleDeletePvins = (pvinsToDelete: ClusterRedshiftCreateInput[], ignoreDateRanges: boolean) => {
    setIsDeletingPvins(true);
    updateCluster.mutate(
      {
        customerId: getCurrentCustomerId() ?? "",
        input: pvinsToDelete,
        clusterId,
        version: cluster?.version || 0,
        action: ClusterUpdateAction.RemovePvins,
        ignoreDateRanges,
      },
      {
        onSuccess: (data) => {
          const cluster = data.clusters?.updateClusterPvins;
          invalidateQueriesAndResetPagination();
          if (cluster) {
            api.success({ message: "Cluster updated successfully" });
          }
          setSelectedPvins([]);
          setModalView(undefined);
        },
        onSettled: () => {
          setIsDeletingPvins(false);
        },
      }
    );
  };

  const handleAddPvins = () => setModalView("addVehiclesToCluster");

  const handleCopyCluster = () => {
    setModalView("copyCluster");
  };

  const handleEditCluster = () => {
    setModalView("editCluster");
  };

  if (isLoading) {
    return <Loading />;
  }

  const isReadOnly = !!(cluster && !cluster.creator);

  const withMaintenanceHistory = !!clusterData?.clusters?.clusters.data[0].recordWithMaintenanceHistoryCount;
  const showHDMFeature = isFeatureEnabled(FeatureId.HdmTables);

  return (
    <>
      {context}
      {!isLoading && !cluster && <Page404 message="Cluster not found" />}
      {!isLoading && cluster && clusterNonVehicleData && (
        <ClusterViewWrapper>
          <ClusterViewHeader
            cluster={clusterNonVehicleData}
            handleCopyCluster={handleCopyCluster}
            handleEditCluster={handleEditCluster}
            isLoading={isFetchingClusterNonVehicleData}
          />
          <MetricCardContainer>
            <MetricCard title="Unique Vehicles" value={cluster.vehicleCount} isLoading={isFetching} />
            <MetricCard title="Total Cases" value={cluster.combinationCount} isLoading={isFetching} />
            {cluster.recordWithMaintenanceHistoryCount > 0 && (
              <MetricCard
                title={`Cases with ${i18next.t("tableTitle.MAINTENANCE_HISTORY")}`}
                value={cluster.recordWithMaintenanceHistoryCount}
                isLoading={isFetching}
              />
            )}
            <MetricCard
              title={`Cases with ${i18next.t("tableTitle.DIAGNOSTIC_CHECKS")}`}
              value={cluster.recordWithDiagnosticCheckCount}
              isLoading={isFetching}
            />
          </MetricCardContainer>
          <Row gutter={[24, 24]} style={{ marginTop: "24px", marginBottom: "24px" }}>
            <Col span={12}>
              <ClusterAttributeCount
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
              />
            </Col>
            <Col span={12}>
              <ClusterDistanceVsFailureCount
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
                maintenaceLogs={cluster.recordWithMaintenanceHistoryCount > 0}
              />
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: "24px", marginBottom: "24px" }}>
            <Col span={24}>
              <FaultEventsWidget
                cluster={cluster}
                setAffectedPvins={setAffectedPvins}
                setModalView={setModalView}
                setLoading={setPvinsLoading}
              />
            </Col>
            <Col span={24}>
              <BasicWidget
                title={
                  <ClusterViewVehicleTable>
                    <TableTitle>
                      <div className="TableTitle-Title">Cases</div>
                      <Popover
                        className="TableTitle-Info"
                        title={<h3>How Case Data Is Used</h3>}
                        content={
                          <div style={{ width: 300 }}>
                            <p>
                              Case data is displayed only if a corresponding {i18next.t("tableTitle.DIAGNOSTIC_CHECK")}{" "}
                              is available. Only faults associated with a {i18next.t("tableTitle.DIAGNOSTIC_CHECK")} are
                              used in the charts and tables on this page. Cases without a matching{" "}
                              {i18next.t("tableTitle.DIAGNOSTIC_CHECK")} are excluded from the analysis.
                            </p>
                          </div>
                        }
                      >
                        <QuestionCircleOutlined />
                      </Popover>
                    </TableTitle>
                    <VehicleHelperButtons>
                      {cluster.missingPvins.length > 0 && (
                        <Button
                          type={"text"}
                          onClick={() => {
                            setModalView("viewMissingPvins");
                            setAffectedPvins(
                              cluster.missingPvins.map((pvin) => ({
                                id: pvin,
                                vin: pvin,
                                model: "",
                                modelStyle: "",
                                modelYear: 0,
                              }))
                            );
                          }}
                        >
                          {cluster.missingPvins.length} Missing {vinLabel}(s)
                        </Button>
                      )}
                      <Button
                        loading={isDeletingPvins}
                        onClick={handleAddPvins}
                        disabled={isFetching || isReadOnly}
                        title={isFetching ? "Loading..." : isReadOnly ? "You cannot edit this cluster." : undefined}
                      >
                        Add Cases
                      </Button>
                      {!!selectedPvins.length && (
                        <Popconfirm
                          title={
                            selectedPvins.length > 1
                              ? `Are you sure you want to delete these cases from the cluster?`
                              : `Are you sure you want to delete this case from the cluster?`
                          }
                          okText="Yes"
                          cancelText="No"
                          onConfirm={handleDeleteActivePvins}
                          disabled={isDeletingPvins}
                        >
                          <Button loading={isDeletingPvins} disabled={isReadOnly}>
                            Remove Cases ({selectedPvins.length})
                          </Button>
                        </Popconfirm>
                      )}
                    </VehicleHelperButtons>
                  </ClusterViewVehicleTable>
                }
              >
                <ClusterDiagnosticCheckTable
                  clusterId={clusterId}
                  cluster={cluster}
                  setSelectedPvins={setSelectedPvins}
                  isReadOnly={isReadOnly}
                />
              </BasicWidget>
            </Col>
          </Row>

          <ClusterModal
            isOpen={
              (!!affectedPvins && modalView === "vehiclesAffected") ||
              modalView === "addVehiclesToCluster" ||
              modalView === "viewMissingPvins" ||
              (pvinsLoading && modalView === "vehiclesAffected") ||
              modalView === "copyCluster" ||
              modalView === "editCluster"
            }
            onCancel={() => {
              setAffectedPvins(undefined);
              setModalView("vehiclesAffected");
              setPvinsLoading(false);
            }}
            api={api}
            initialView={modalView}
            pvins={affectedPvins}
            clusterId={clusterNonVehicleData.id}
            clusterVersion={clusterNonVehicleData.version}
            clusterName={clusterNonVehicleData.name}
            clusterDescription={clusterNonVehicleData.description}
            handleSuccess={invalidateQueriesAndResetPagination}
            handleDelete={handleDeleteMissingPvins}
            isLoading={pvinsLoading}
            isReadOnly={isReadOnly}
          />
          {showHDMFeature ? <HistoricDataMatrix withMaintenanceHistory={withMaintenanceHistory} /> : undefined}
        </ClusterViewWrapper>
      )}
    </>
  );
};
