import { Select } from "antd";
import styled from "styled-components";

export const CustomerPickerStyled = styled(Select)`
  color: ${({ theme }) => theme.colors.defaultWhite};
  width: 170px !important;
  height: 100% !important;
  .ant-select-selector {
    background-color: ${({ theme }) => theme.colors.background} !important;
    border-color: ${({ theme }) => theme.colors.defaultWhite};
    border: 1px solid;
    border-radius: 0.25rem !important;
    height: 100% !important;
    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.defaultWhite} !important;
    }
  }
  span {
    color: ${({ theme }) => theme.colors.defaultWhite};
    font-size: 15px;
    line-height: 19px;
  }
`;
