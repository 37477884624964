import { Button, Select } from "antd";
import EChartsReact from "echarts-for-react";
import i18next from "i18next";
import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";

import { DiagnosticCheckType, TimeSeriesIndependentVar, useDataMatrixTableHistoryQuery } from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import TimeSeriesVariableSelector from "@/components/timeSeries/TimeSeriesVariableSelector";
import { BaseEChart, setChartCursor } from "@/components/ui/BaseEChart";
import HelperTooltipWithIcon from "@/components/ui/HelperTooltipWithIcon";
import { QUERY_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import {
  getDataMatrixHash,
  getDataMatrixLabel,
  getHDMTableDefinitions,
  SelectedDataMatrix,
  sortHistoryDataByVariable,
} from "@/utils/historicDataMatrix/hdmUtils";
import { AxisFields, DataZoomFields } from "@/utils/types/EChartsDefinitions";

import { initChartZoomMode } from "../chartElementLibrary/ChartLibraryBuildUtils";
import { HDMChartHeader, HDMHistoryChartWrapper, HDMHistoryHeader, HDMSelectWrapper } from "./HDM.styled";
import { getDataMatrixHistoryLegends, getHDMHistoryChartConfig, getTableLegend } from "./HDMHistoryChartUtils";
import { aggregateTypeFromDefaultAggregation, getAllowedDiagnosticChecks } from "./HistoricDataMatrix";
import HistoryChartLegendSection from "./HistoryChartLegendSection";
import useDataMatrixRouteParams from "./useDataMatrixRouteParams";
import useDataMatrixState from "./useDataMatrixState";

type HistoricDataMatrixHistoryChartProps = {
  // Cluster only settings
  dcType: DiagnosticCheckType | undefined;
  setDcType: Dispatch<SetStateAction<DiagnosticCheckType>>;
  batteryType: number | undefined;
  setBatteryType: Dispatch<SetStateAction<number>>;
  withMaintenanceHistory?: boolean;
};

export const HistoricDataMatrixHistoryChart = ({
  dcType,
  setDcType,
  batteryType,
  setBatteryType,
  withMaintenanceHistory,
}: HistoricDataMatrixHistoryChartProps) => {
  const { getFeatureConfig } = useFeatureFlags();
  const { customerIdentifier } = useCustomerIdentifier();

  const { pvin, clusterId } = useDataMatrixRouteParams();
  const { dms, availableCustomerTables, selectedTableDefinition, setSelectedMatrix, setSelectedTableId } =
    useDataMatrixState();

  const chartRef = useRef<EChartsReact>();

  const [xAxis, setXAxis] = useState<{ label: string; key: TimeSeriesIndependentVar }>(
    pvin
      ? {
          key: TimeSeriesIndependentVar.Time,
          label: "Time",
        }
      : { key: TimeSeriesIndependentVar.Mileage, label: "Odometer" }
  );

  const [zoom, setZoom] = useState<{ min: number; max: number }>();

  const tableQueryEnabled = !!dms?.tableId && (!!pvin || !!clusterId);

  const tableAggregateType = aggregateTypeFromDefaultAggregation(
    getHDMTableDefinitions(customerIdentifier, getFeatureConfig, dms?.tableId)?.[0].default_table_aggregation ?? ""
  );

  const { data, isLoading, isFetching } = useDataMatrixTableHistoryQuery(
    {
      pvin: pvin,
      clusterId: clusterId,
      diagnosticCheckType: dcType,
      aggregation: tableAggregateType,
      tableId: dms?.tableId ?? "",
    },
    { enabled: tableQueryEnabled, staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const isClusterView = !!clusterId;
  const availableMatricesIds = data?.aggregateHDMTableValues.map((v) => v.id) ?? [];

  // "Remembers" last matrix selected in SCV
  useEffect(() => {
    if (!isLoading && !isClusterView) {
      const availableMatrices = data?.aggregateHDMTableValues ?? [];
      const currentSelectedMatrix = dms?.selectedMatrix;
      if (currentSelectedMatrix && availableMatrices.length) {
        const matchingPoint = availableMatrices.find(
          (am) => currentSelectedMatrix.id !== am.id && currentSelectedMatrix.tableHash === getDataMatrixHash(am)
        );
        if (matchingPoint) {
          const matchingDataMatrix = {
            id: matchingPoint.id,
            label: getDataMatrixLabel(matchingPoint),
            tableHash: getDataMatrixHash(matchingPoint),
          } satisfies SelectedDataMatrix;

          setSelectedMatrix(matchingDataMatrix, true);
        }
      }
    }
  }, [availableMatricesIds]);

  const handleXAxisChange = (key: TimeSeriesIndependentVar) => {
    if (key === TimeSeriesIndependentVar.Time) {
      setXAxis({ label: "Time", key });
    } else {
      setXAxis({ label: "Odometer", key });
    }
    setZoom(undefined);
  };

  const onChartClick = (params: { componentType: string; data: { value: [number, number, SelectedDataMatrix] } }) => {
    if (isClusterView) return;
    if (params.componentType === "series" && params.data.value[2]) {
      setSelectedMatrix(params.data.value[2]);
    }
  };

  const handleZoomReset = () => {
    setZoom(undefined);
  };

  const zoomEventHandler = (chart?: EChartsReact) => {
    const chartOptions = chart?.getEchartsInstance().getOption();
    if (chartOptions) {
      const dataZoom = chartOptions.dataZoom as DataZoomFields[];
      const xAxis = chartOptions.xAxis as AxisFields[];
      if (dataZoom.length > 0 && xAxis.length > 0) {
        const zoomStart = Math.floor(dataZoom[0].startValue);
        const zoomEnd = Math.round(dataZoom[0].endValue);
        if (zoomStart && zoomEnd && zoomEnd >= zoomStart) {
          setZoom({ min: zoomStart, max: zoomEnd });
        }
      }
    }
  };

  const handleDcTypeChange = (value: DiagnosticCheckType) => {
    setDcType(value);
  };

  const handleBatteryTypeChange = (value: number) => {
    setBatteryType(value);
  };

  const historyWidgetLabel = `${isClusterView ? "Cluster" : "Vehicle"} History`;
  const baseLegend = `${selectedTableDefinition?.default_table_aggregation} ${getTableLegend(selectedTableDefinition)}`;

  const chartLegends = getDataMatrixHistoryLegends(
    data?.aggregateHDMTableValues ?? [],
    baseLegend,
    isClusterView ? false : !!dms?.selectedMatrix
  );

  const chartOptions = getHDMHistoryChartConfig({
    xAxis: xAxis.key,
    matrices: sortHistoryDataByVariable(xAxis.key, data?.aggregateHDMTableValues ?? []),
    zoom,
    selectedDataMatrix: isClusterView ? undefined : dms?.selectedMatrix,
    selectedTableDefinition,
    showConnectingLine: !!pvin,
  });

  return (
    <HDMHistoryChartWrapper>
      <HDMHistoryHeader>
        <HDMSelectWrapper>
          <h4>HDM Table</h4>
          <Select
            value={dms?.tableId}
            options={availableCustomerTables?.map((t) => ({ label: t.name, value: t.table_id }))}
            defaultValue={availableCustomerTables?.[0].table_id}
            onChange={(tableId) => {
              setSelectedTableId(tableId);
            }}
          />
        </HDMSelectWrapper>
        {!isClusterView ? (
          <HDMSelectWrapper>
            <h4>{i18next.t("columnTitle.DATAMATRIX_READING")}</h4>
            <Select
              className="select-reading"
              options={data?.aggregateHDMTableValues.map((table) => ({
                label: getDataMatrixLabel(table),
                value: table.id,
              }))}
              value={dms?.selectedMatrix?.id}
              onChange={(id) => {
                const currentTable = data?.aggregateHDMTableValues.find((table) => table.id === id);
                const resultDataMatrix = {
                  label: getDataMatrixLabel(currentTable),
                  tableHash: getDataMatrixHash(currentTable),
                  id: currentTable?.id ?? "",
                };
                setSelectedMatrix(resultDataMatrix);
              }}
              placeholder={`Select ${i18next.t("columnTitle.DATAMATRIX_READING")}`}
              loading={isLoading || isFetching}
              disabled={isLoading || isFetching}
            />
          </HDMSelectWrapper>
        ) : undefined}
        {isClusterView ? (
          <HDMSelectWrapper>
            <h4>{i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}</h4>
            <StyledSelect
              options={Object.entries(getAllowedDiagnosticChecks(withMaintenanceHistory)).map(([k, v]) => ({
                value: v,
                label: k,
              }))}
              value={dcType}
              onChange={(v) => handleDcTypeChange(v as DiagnosticCheckType)}
              placeholder="Select Diagnostic Check"
            />
          </HDMSelectWrapper>
        ) : undefined}
        {isClusterView ? (
          <HDMSelectWrapper>
            <h4>Battery Size</h4>
            <StyledSelect
              options={customerIdentifier.batteryModules?.default.modules.map((bt) => ({ value: bt }))}
              value={batteryType}
              onChange={(v) => handleBatteryTypeChange(v as number)}
              placeholder="Select Battery Size"
            />
          </HDMSelectWrapper>
        ) : undefined}
      </HDMHistoryHeader>
      <div className="hdm-chart">
        <HDMChartHeader>
          <div className="inline-title">
            <h3 className="heading-x-small">{historyWidgetLabel}</h3>
            {xAxis.key === TimeSeriesIndependentVar.Mileage ? (
              <HelperTooltipWithIcon message="Over the Air (OTA) tables are not displayed when x-Axis is Odometer" />
            ) : undefined}
          </div>
          <div className="hdm-chart-header-buttons">
            <Button type="text" className="text-button" size="small" onClick={handleZoomReset} disabled={!zoom}>
              Reset Zoom
            </Button>
            <TimeSeriesVariableSelector currentVariable={xAxis.key} onChange={handleXAxisChange} />
          </div>
        </HDMChartHeader>
        <HistoryChartLegendSection legends={chartLegends} />
        <BaseEChart
          ref={chartRef as RefObject<EChartsReact>}
          option={{ ...chartOptions }}
          showLoading={isLoading || isFetching}
          onEvents={{
            click: onChartClick,
            datazoom: () => {
              zoomEventHandler(chartRef.current);
            },
            mousemove: () => {
              if (chartRef.current) {
                setChartCursor(chartRef.current, "pointer");
              }
            },
            mouseout: () => {
              if (chartRef.current) {
                setChartCursor(chartRef.current, "crosshair");
              }
            },
          }}
          onChartReady={(chart) => {
            initChartZoomMode(chartRef.current?.getEchartsInstance() || chart);
          }}
        />
      </div>
    </HDMHistoryChartWrapper>
  );
};
