import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Button, Col, Row } from "antd";
import { useState } from "react";

import { FeatureId, useUserEventsQuery } from "@/api";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { defaultTableState } from "@/routes/event-analysis";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";

import { ExpressionEventModal } from "../event/ExpressionEvent/ExpressionEventModal";
import { RecentlyViewedEventsTable } from "./Tables/RecentlyViewedEventsTable";
import { UserEventsTable } from "./Tables/UserEventsTable";

export const EventAnalysis = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate({ from: "/event-analysis" });

  const handleMutation = async () => {
    await navigate({ search: (prev) => ({ ...prev, patternEventsTable: defaultTableState }) });
    queryClient.invalidateQueries(useUserEventsQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }));
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const CreateButton = () => {
    return (
      <Button type="primary" onClick={() => setShowModal(true)}>
        Create New Event
      </Button>
    );
  };

  const colSapn = isFeatureEnabled(FeatureId.Patterns) ? 12 : 24;

  return (
    <>
      <TopHeadingWithButtons
        title="Event Analysis"
        description="Analyze historical and real-time events to uncover insights and find a root cause."
        buttons={isFeatureEnabled(FeatureId.Patterns) ? <CreateButton /> : undefined}
      />
      <Row gutter={16}>
        <Col span={colSapn}>
          <BasicWidget title="Recently Viewed Events">
            <RecentlyViewedEventsTable />
          </BasicWidget>
        </Col>
        {isFeatureEnabled(FeatureId.Telemetry) && (
          <Col span={colSapn}>
            <BasicWidget title="Pattern Events">
              <UserEventsTable />
            </BasicWidget>
          </Col>
        )}
      </Row>
      <ExpressionEventModal open={showModal} onCancel={handleCancel} onMutate={handleMutation} />
    </>
  );
};
