import { humanFormatNumber } from "@/utils/numberUtils";

export const buildAnomalyMarkLines = (
  cautionThreshold: number | undefined,
  warningThreshold: number | undefined,
  preview: boolean
) => ({
  symbol: ["none", "none"],
  data: [
    cautionThreshold
      ? {
          yAxis: cautionThreshold,
          lineStyle: {
            type: "dashed",
            color: "dedede",
            opacity: 0.3,
          },
        }
      : undefined,
    warningThreshold
      ? {
          yAxis: warningThreshold,
          lineStyle: {
            type: "dashed",
            color: "dedede",
            opacity: 0.3,
          },
        }
      : undefined,
  ].filter((e) => !!e),
  emphasis: {
    lineStyle: {
      opacity: 0.3,
      color: "dedede",
      width: 1,
    },
    label: {
      show: false,
    },
  },
  label: {
    show: !preview,
    formatter: (params: any) => humanFormatNumber(params?.data?.value),
  },
  silent: true,
});
