import { isFinite } from "lodash";

import { toArray } from "@/utils/arrayUtils";
import { formatCompactNumber, formatNumber } from "@/utils/numberUtils";
import { EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

// Cell value, Module value, heatmap cell value
export type HDMHeatMapCell = [number, number, number];

export const getComparisonModeValues = (tableValues: number[], comparisonValues: number[], comparisonMode: boolean) => {
  if (!comparisonMode || tableValues?.length !== comparisonValues?.length) return tableValues;
  return tableValues?.map((tableValue, i) => {
    if (!tableValue || isNaN(comparisonValues?.[i] as number)) {
      return undefined;
    }
    return tableValue - comparisonValues[i];
  });
};

export type DisplayValuesFn = (index: number, compact: boolean, raw?: boolean) => string | number;

export const heatMapTooltipFormater = (
  params: Object | Array<Object>,
  comparisonMode: boolean | undefined,
  getOriginalValue: DisplayValuesFn,
  valuesArePercentages: boolean,
  xAxisElement: string[],
  yAxisElement: string[],
  xAxisLabel: string,
  yAxisLabel: string
): string | HTMLElement | HTMLElement[] => {
  const paramsList = toArray(params) as EchartsTooltipFormatterParams<HDMHeatMapCell>[];

  return paramsList
    .map((p) => {
      const { data, marker, dataIndex, name } = p;
      const cellBased = Number.isFinite(+name);

      const xLabel = xAxisElement[data[0]];
      const yLabel = yAxisElement[data[1]];

      const cellLabel = valuesArePercentages ? `${formatNumber(data[2])} %` : formatNumber(data[2]);
      const comparisonLabel = comparisonMode
        ? valuesArePercentages
          ? `${getOriginalValue(dataIndex, false)} %`
          : getOriginalValue(dataIndex, false)
        : "";

      const pointLengend = cellBased
        ? `<div class="flex-container horizontal">
            <span class="body-medium">Cell <strong>${xLabel}</strong></span>
            <span class="body-medium">Module <strong>${yLabel}</strong></span>
          </div>`
        : `<div class="flex-container vertical">
            <span class="body-medium"><strong>${yAxisLabel}</strong> ${yLabel}</span>
            <span class="body-medium"><strong>${xAxisLabel}</strong> ${xLabel}</span>
          </div>`;

      return `<div class="tooltip-body hdm-heatmap-tooltip">
        <div class="flex-container vertical">        
          ${pointLengend}        
          <div class="flex-container">
            ${marker}
            <span class="body-medium"><strong>${cellLabel}</strong></span>
          </div>
          ${
            comparisonMode
              ? `<div class="flex-container">
            <span>Original Value</span>
            <span class="body-medium"><strong>${comparisonLabel}</strong></span>
          </div>`
              : ""
          }
        </div>
      </div>`;
    })
    .join("");
};

type buildHDMHeatMapConfigProps = {
  data: (number | null)[];
  xAxisElements: string[];
  yAxisElements: string[];
  xAxisLabel: string;
  yAxisLabel: string;
  getDisplayValues?: DisplayValuesFn;
  valuesArePercentages: boolean;
  comparisonMode?: boolean;
  hoveredCells?: { x: number; y: number };
};

const XAXIS_COMPACT_MAX = 12;

export const buildHDMHeatMapConfig = ({
  data,
  xAxisElements,
  yAxisElements,
  xAxisLabel,
  yAxisLabel,
  getDisplayValues,
  valuesArePercentages,
  comparisonMode,
}: buildHDMHeatMapConfigProps) => {
  const shouldCompact = xAxisElements.length > XAXIS_COMPACT_MAX;

  const values =
    comparisonMode && getDisplayValues
      ? data.map((d, i) => {
          if (!isFinite(d)) return "";
          return Number(getDisplayValues(i, shouldCompact, true));
        })
      : data;
  const filteredTableValues = values.filter((value) => isFinite(value)) as number[];

  const maxValue = filteredTableValues.length ? Math.max(...filteredTableValues) : 0;
  const minValue = filteredTableValues.length ? Math.min(...filteredTableValues) : 0;

  const seriesData = [];

  for (let i = 0; i < yAxisElements.length; i++) {
    for (let j = 0; j < xAxisElements.length; j++) {
      const index = j + xAxisElements.length * i;
      seriesData.push([j, i, values[index]]);
    }
  }

  const getOriginalValue = (index: number) => {
    const value = data[index] ?? "";
    return value ? formatNumber(value) : value;
  };

  const yAxisHasLongLabels = yAxisElements.some((e) => e.length > 4);

  return {
    option: {
      animation: false,
      visualMap: {
        max: maxValue,
        min: minValue,
        calculable: true,
        dimension: 2,
        orient: "vertical",
        left: "right",
        top: "center",
        align: "right",
        itemHeight: 650,
        inRange: {
          color: ["#fde725", "#c6427e", "#440154"],
        },
        formatter: (value: number) =>
          valuesArePercentages ? `${formatNumber(value, true)} %` : formatNumber(value, true),
      },
      tooltip: {
        trigger: "item",
        padding: 0,
        className: "chart-library-tooltip-container",
        borderColor: "transparent",
        formatter: (param: any) =>
          heatMapTooltipFormater(
            param,
            comparisonMode,
            getOriginalValue,
            valuesArePercentages,
            xAxisElements,
            yAxisElements,
            xAxisLabel,
            yAxisLabel
          ),
        position: function (_point: any, _params: any, _dom: any, rect: DOMRect, size: { contentSize: number[] }) {
          return [rect.width + rect.x + 10, rect.y + rect.height / 2 - size.contentSize[1] / 2];
        },
      },
      grid: { left: yAxisHasLongLabels ? 120 : 45, top: 45, right: 110, bottom: 20 },
      textStyle: {
        color: "#5F5F5F",
        fontFamily: "Hubot Sans",
      },
      xAxis: {
        type: "category",
        name: xAxisLabel,
        position: "top",
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: {
          fontWeight: "bold",
        },
        data: xAxisElements,
        splitArea: {
          show: true,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        name: yAxisLabel,
        nameLocation: "middle",
        nameGap: yAxisHasLongLabels ? 105 : 30,
        nameRotate: 90,
        nameTextStyle: {
          fontWeight: "bold",
        },
        data: yAxisElements,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        inverse: true,
      },
      series: [
        {
          type: "heatmap",
          // Allows the no data chart to be shown.
          data: data.length > 0 ? seriesData : [],
          label: {
            show: true,
            fontSize: shouldCompact ? 9.5 : undefined,
            formatter: ({ value }: { value: HDMHeatMapCell }) =>
              seriesFormatter(value, xAxisElements, valuesArePercentages, shouldCompact, getDisplayValues),
          },
        },
      ],
    },
  };
};

const seriesFormatter = (
  value: HDMHeatMapCell,
  xAxisElements: string[],
  valuesArePercentages: boolean,
  compact: boolean,
  getDisplayValues?: DisplayValuesFn
): string | number => {
  const baseValue = getDisplayValues
    ? getDisplayValues(value[0] + value[1] * xAxisElements.length, compact)
    : compact
    ? formatCompactNumber(value[2])
    : formatNumber(value[2]);

  return valuesArePercentages ? `${baseValue} %` : baseValue;
};
