export const resourceLabelKeyDelimiter = "||";

/**
 * A key-label pair that can be used anywhere we might need a resource bundle key for i18n.
 */
export interface ResourceLabelKey {
  key: string;
  label?: string;
}

export function isValid(resourceLabelKey: Partial<ResourceLabelKey>): boolean {
  return !!resourceLabelKey.key;
}

export function toString(resourceLabelKey: ResourceLabelKey) {
  return `${resourceLabelKey.key}${resourceLabelKeyDelimiter}${resourceLabelKey.label}`;
}

export function toResourceLabelKey(str: string): ResourceLabelKey {
  const sep = str.indexOf(resourceLabelKeyDelimiter);
  let key = str;
  let label = str;
  if (sep > 0) {
    key = str.substring(0, sep);
    label = str.substring(sep + resourceLabelKeyDelimiter.length);
  }
  return {
    key,
    label,
  };
}

export function pairToResourceLabelKey({ key, label }: ResourceLabelKey): ResourceLabelKey {
  // raw key-label pair to ResourceLabelKey
  return { key, label: label ?? key } as ResourceLabelKey;
}
