import { Link } from "@tanstack/react-router";
import { Button } from "antd";
import { useState } from "react";
import { createPortal } from "react-dom";

import { TimeSeriesIndependentVar } from "@/api";
import { useVehicleDetailsQuery, Vehicle } from "@/api/customerApi";
import BlockingLoading from "@/components/ui/BlockLoading";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import MISFormatter from "@/components/ui/MISFormatter";
import { QUERY_SETTINGS } from "@/constants";
import usePortalTargetObserver from "@/hooks/usePortalTargetObserver";
import { parseDateToMdStr } from "@/utils/dateUtils";
import { formatNumber } from "@/utils/numberUtils";

import { PortedElementContainer } from "./AnomalyTooltipVehicleDetail.styled";
import { initialTooltipDataElementId } from "./AnomalyVehicleAnalysis";
import { anomalyStateToColor, VehicleDetectionPoint } from "./ScatterChartUtils";

// This type is related to AnomalyRandomVehicle but the shape needs to be like this for usage inside the chart
export type VehiclePointBaseData = {
  x: number;
  y: number;
  dataPoint: VehicleDetectionPoint;
};

type AnomalyTooltipVehicleDetailProps = {
  tooltipSelector: string;
  portalTargetId: string;
  variable?: TimeSeriesIndependentVar;
  xAxisLabel?: string;
  yAxisLabel?: string;
};

const AnomalyTooltipVehicleDetail = ({
  tooltipSelector,
  portalTargetId,
  variable,
  xAxisLabel = "x",
  yAxisLabel = "y",
}: AnomalyTooltipVehicleDetailProps) => {
  // The target element to portal to can change so it is a state
  const [targetElement, setTargetElement] = useState<Element | null>(null);
  let detectedVehicleBaseData: VehiclePointBaseData = {
    dataPoint: {
      pvin: "",
    },
    x: 0,
    y: 0,
  };

  const initialTooltipData = usePortalTargetObserver({
    elementSelector: tooltipSelector,
    targetId: portalTargetId,
    initialDataElementSelector: `#${initialTooltipDataElementId}`,
    setElement: setTargetElement,
  });

  if (initialTooltipData && typeof initialTooltipData === "object" && "dataPoint" in initialTooltipData) {
    detectedVehicleBaseData = initialTooltipData as VehiclePointBaseData;
  }

  const { data, isLoading } = useVehicleDetailsQuery(
    { id: detectedVehicleBaseData.dataPoint.pvin },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !!detectedVehicleBaseData.dataPoint.pvin }
  );

  const vehicleDetails = (data?.vehicleDetails[0] ?? undefined) satisfies Vehicle | undefined;

  const scoreColor = anomalyStateToColor(detectedVehicleBaseData.dataPoint.anomalyState);

  if (!targetElement || !initialTooltipData || !detectedVehicleBaseData.dataPoint.pvin) return;

  const shouldRenderBothAxisData = !!detectedVehicleBaseData.dataPoint.multiAxis;

  return createPortal(
    <PortedElementContainer className="ported-element">
      <span className="title">{vehicleDetails?.vin ? vehicleDetails.vin : <BlockingLoading className="line" />}</span>
      {shouldRenderBothAxisData ? (
        <div className="tooltip-section">
          <div className="axis-name">{yAxisLabel}</div>
          <div>{formatNumber(detectedVehicleBaseData.y)}</div>
        </div>
      ) : (
        <div className="tooltip-section score">
          <div>Score</div>
          <div style={{ color: scoreColor }}>{formatNumber(detectedVehicleBaseData.y)}</div>
        </div>
      )}
      {shouldRenderBothAxisData ? (
        <div className="tooltip-section">
          <div className="axis-name">{xAxisLabel}</div>
          <div>{formatNumber(detectedVehicleBaseData.x)}</div>
        </div>
      ) : undefined}
      <div className="tooltip-section delivery-date">
        <span>Delivery Date</span>
        {isLoading ? <BlockingLoading className="line" /> : undefined}
        {!isLoading ? (
          vehicleDetails && vehicleDetails.deliveryDate ? (
            <div className="delivery-date-container">
              <FromNowFormatter value={new Date(Date.parse(vehicleDetails.deliveryDate))} />
              <span>{parseDateToMdStr(new Date(Date.parse(vehicleDetails.deliveryDate)))}</span>
            </div>
          ) : (
            "Unknown"
          )
        ) : undefined}
      </div>
      <div className="tooltip-section">
        <span>Country</span>
        {isLoading ? <BlockingLoading className="line" /> : undefined}
        {!isLoading ? (vehicleDetails && vehicleDetails.country ? vehicleDetails.country : "Unknown") : undefined}
      </div>
      <div className="tooltip-section">
        <span>Model Year</span>
        {isLoading ? <BlockingLoading className="line" /> : undefined}
        {!isLoading ? (vehicleDetails && vehicleDetails.year ? vehicleDetails.year : "Unknown") : undefined}
      </div>
      {variable ? (
        <div className="tooltip-section">
          <span>{variable === TimeSeriesIndependentVar.Mileage ? "Odometer" : "Timestamp"}</span>
          <span>
            {variable === TimeSeriesIndependentVar.Mileage
              ? `${formatNumber(detectedVehicleBaseData.x)} km`
              : parseDateToMdStr(new Date(detectedVehicleBaseData.x))}
          </span>
        </div>
      ) : undefined}
      <div className="tooltip-section">
        <span>MIS</span>
        {isLoading ? <BlockingLoading className="line" /> : undefined}
        {!isLoading ? (
          vehicleDetails && vehicleDetails.deliveryDate ? (
            <div className="delivery-date-container">
              <MISFormatter from={new Date(Date.parse(vehicleDetails.deliveryDate))} />
            </div>
          ) : (
            "Unknown"
          )
        ) : undefined}
      </div>
      <Link to="/vehicle/$pvin" params={{ pvin: detectedVehicleBaseData.dataPoint.pvin }}>
        <Button type="primary" block>
          View Vehicle
        </Button>
      </Link>
    </PortedElementContainer>,
    targetElement
  );
};

export default AnomalyTooltipVehicleDetail;
