import { Button } from "antd";
import i18next from "i18next";
import { debounce } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { AggregateType } from "@/api";
import { DiagnosticCheckType, HdmClusterComparisonInput, HdmComparisonOptions } from "@/api/customerApi";
import { StyledAutoComplete } from "@/components/form/input/BasicAutoComplete";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import Loading from "@/components/loading";
import { UI_SETTINGS } from "@/constants";
import { ComparisonDefinition } from "@/utils/historicDataMatrix/hdmUtils";
import { camelCaseToTitleCase } from "@/utils/stringUtils";

import { ComparisonFormContainer, SelectorContainer } from "./HDMComparisonForm.styled";
import { getHDMAllowedAggregateTypes } from "./HistoricDataMatrix";
import useDataMatrixRouteParams from "./useDataMatrixRouteParams";

type HDMComparisonFormProps = {
  onChange: (settings: ComparisonDefinition) => void;
  comparisonOptions?: HdmComparisonOptions;
  clusters?: { name: string; id: string }[];
  setClusterNameSearch: Dispatch<SetStateAction<string | undefined>>;
  clusterNameSearch: string | undefined;
  clustersLoading: boolean;
  batterySize?: number;
  comparisonDefinition: ComparisonDefinition;
};

const HDMClusterComparisonForm = ({
  onChange,
  comparisonOptions,
  clusters,
  setClusterNameSearch,
  clusterNameSearch,
  clustersLoading,
  batterySize,
}: HDMComparisonFormProps) => {
  const { pvin } = useDataMatrixRouteParams();

  const [localCluster, setLocalCluster] = useState<string>();
  const { handleSubmit, setValue, watch, getValues } = useForm<HdmClusterComparisonInput>({
    defaultValues: { batterySize: undefined },
  });

  const onSubmit: SubmitHandler<HdmClusterComparisonInput> = (settings) => {
    onChange({ clusterComparison: settings, customComparison: undefined });
  };

  useEffect(() => {
    setValue("batterySize", batterySize ?? comparisonOptions?.moduleCount[0] ?? 0);
    setValue("DiagnosticCheckType", DiagnosticCheckType.First);
    setValue("aggregation", AggregateType.Mean);
  }, [comparisonOptions]);

  useEffect(() => {
    handleClusterSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCluster]);

  const handleClusterSearch = debounce(() => {
    setClusterNameSearch(localCluster);
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  return (
    <>
      {!comparisonOptions && <Loading />}
      {comparisonOptions && (
        <ComparisonFormContainer onSubmit={handleSubmit(onSubmit)}>
          <SelectorContainer>
            <span className="selector-label body-medium">Cluster</span>
            <StyledAutoComplete
              value={localCluster}
              placeholder={`Type to Select Cluster`}
              options={[...(clusters?.map((cluster) => ({ label: cluster.name, value: cluster.id })) ?? [])]}
              onChange={(value) => setLocalCluster(value as string)}
              onSelect={(_, selection) => {
                setValue("clusterId", selection.value);
                setLocalCluster(selection.label);
              }}
              notFoundContent={
                clusterNameSearch === "" ? null : clustersLoading ? (
                  <Loading size="small" label="Searching..." />
                ) : (
                  <div>No results found</div>
                )
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Aggregation</span>
            <StyledSelect
              placeholder="Select Aggregation"
              options={Object.entries(getHDMAllowedAggregateTypes(pvin)).map(([key, value]) => ({
                value: value,
                label: key,
              }))}
              value={watch("aggregation")}
              onChange={(val) => setValue("aggregation", val as AggregateType)}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">{i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID")}</span>
            <StyledSelect
              options={Object.entries(DiagnosticCheckType).map(([k, v]) => ({
                value: v,
                label: camelCaseToTitleCase(k),
              }))}
              value={watch("DiagnosticCheckType")}
              onChange={(val) => setValue("DiagnosticCheckType", val as DiagnosticCheckType)}
              placeholder={"Select Diagnostic Check"}
            />
          </SelectorContainer>
          <SelectorContainer>
            <span className="selector-label body-medium">Battery Size</span>
            <StyledSelect
              options={
                batterySize
                  ? [{ value: batterySize }]
                  : comparisonOptions?.moduleCount.map((module) => ({ value: module }))
              }
              value={watch("batterySize")}
              onChange={(val) => setValue("batterySize", val as number)}
              placeholder={"Select Battery Size"}
            />
          </SelectorContainer>
          <Button className="submit-form-btn" type="primary" htmlType="submit" block disabled={!getValues("clusterId")}>
            Done
          </Button>
        </ComparisonFormContainer>
      )}
    </>
  );
};

export default HDMClusterComparisonForm;
