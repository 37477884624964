import styled from "styled-components";

export const PortedElementContainer = styled.div`
  font-family: "Inter";
  min-width: 250px;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  .tooltip-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .axis-name {
      max-width: 150px;
      white-space: normal;
      word-break: break-word;
    }
  }
  .title {
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  }
  .score {
    font-weight: bold;
  }
  .delivery-date {
    align-items: flex-start;
    .delivery-date-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
