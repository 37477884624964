import { isExpressionValid } from "@lib/src/expression/isExpressionValid";
import { Expression } from "@lib/src/expression/types";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";

import { useGetFilteredExpressionHistogramQuery } from "@/api/customerApi";
import Loading from "@/components/loading";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { getBucketsWithoutNulls, setDefaultThresholds } from "@/utils/anomaly";

import { IncompletePatternWarning } from "../IncompletePatternWarning";
import { AnomalyBarValues, AnomalyHistogramPreview } from "./AnomalyHistogramPreview/AnomalyHistogramPreview";

type PatternAnomalyDetectorPreviewProps = {
  definition: Expression | undefined;
  barValues: AnomalyBarValues | undefined;
  setBarValues: Dispatch<SetStateAction<AnomalyBarValues | undefined>>;
  setOutliersRemoved: Dispatch<SetStateAction<boolean>>;
  onExpressionChange?: (value: boolean) => void;
  anomaly?: {
    threshold_lower: number;
    threshold_caution: number;
    threshold_warning: number;
    threshold_upper: number;
  } | null;
};

export const PatternAnomalyDetectorPreview = ({
  definition,
  barValues,
  setBarValues,
  onExpressionChange,
  anomaly,
  setOutliersRemoved,
}: PatternAnomalyDetectorPreviewProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const bucketNumber = 25;
  const [bucketsWithoutNulls, setBucketsWithoutNulls] = useState<{ min: number[]; max: number[]; pct: number[] }>();
  const isExpressionValidMemoized = useMemo(() => isExpressionValid(definition as Partial<Expression>), [definition]);
  const shouldLoad = !!definition && isExpressionValidMemoized;
  const {
    data: histogram,
    isLoading: isHistogramLoading,
    isFetching,
  } = useGetFilteredExpressionHistogramQuery(
    {
      filter: globalFilter,
      expression: definition ?? {},
      buckets: bucketNumber,
    },
    { enabled: shouldLoad }
  );
  const isLoading = shouldLoad && isHistogramLoading;

  useEffect(() => {
    if (histogram?.filteredQuery.filteredSavedExpressionsHistogram) {
      const hist = histogram.filteredQuery.filteredSavedExpressionsHistogram;
      const { min, max, pct } = getBucketsWithoutNulls(hist.min, hist.max, hist.pct);
      if (!anomaly) {
        setBarValues(setDefaultThresholds(max, min));
      }

      if (anomaly) {
        // if the user selects a new pattern to base this anoamly off of and the values are different.
        // we can lose thresholds off screen. Checks to make sure the patters align first.
        if (anomaly.threshold_lower >= min[0] && anomaly.threshold_upper <= max[max.length - 1]) {
          setBarValues({
            lowerNormal: anomaly.threshold_lower,
            upperNormal: anomaly.threshold_upper,
            cautionBar: anomaly.threshold_caution,
            warningBar: anomaly.threshold_warning,
          });
        } else {
          setBarValues(setDefaultThresholds(max, min));
        }
      }
      if (histogram.filteredQuery.filteredSavedExpressionsHistogram.outliers) {
        setOutliersRemoved(true);
      }
      setBucketsWithoutNulls({ min, max, pct });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histogram, anomaly]);

  useEffect(() => {
    if (onExpressionChange) {
      onExpressionChange(isExpressionValidMemoized);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpressionValidMemoized]);

  if (isFetching) {
    return <Loading />;
  } else {
    if (bucketsWithoutNulls && barValues) {
      return (
        <AnomalyHistogramPreview loading={isLoading} data={bucketsWithoutNulls} {...{ barValues, setBarValues }} />
      );
    } else {
      return <IncompletePatternWarning />;
    }
  }
};
