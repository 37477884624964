import IconML from "@amayaIcons/icon-ml.svg?react";
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import { Tooltip } from "antd";

import {
  AnomalyDetectorType,
  SingleVehicleAnomalyDetector,
  SingleVehicleAnomalyDetectorHeaders,
} from "@/api/customerApi";
import { humanFormatNumber } from "@/utils/numberUtils";

import { AnomalyCardTitleWrapper } from "./AnomalyCardTitle.styled";
import { ANOMALY_UNKNOWN_COLOR } from "./anomalyColors";
import { anomalyScoreToColor } from "./anomalyScoreToColor";
import { anomalySeverityToColor } from "./anomalySeverityToColor";
import { getAnomalyMultiplier } from "./buildAnomalyChartConfig";

const anomalyTypeToIcon = (type: AnomalyDetectorType, label?: string) => {
  switch (type) {
    case AnomalyDetectorType.Ml:
      return <AnomalyDetectorIsMLIcon />;
    case AnomalyDetectorType.CustomerProvided:
      if (label)
        return (
          <span className="label-normal anomaly-detector-icon-container">
            <strong>{label}</strong>
          </span>
        );
      return;
    default:
      return;
  }
};

const AnomalyDetectorIsMLIcon = () => {
  return (
    <div className="anomaly-detector-icon-container">
      <Tooltip title={"ML-Powered"} style={{ marginLeft: "auto" }}>
        <IconML />
      </Tooltip>
    </div>
  );
};

type AnomalyCardTitleProps = {
  anomaly: SingleVehicleAnomalyDetector;
  onClose?: () => void;
};
//TODO: Use in expanded view also
export const AnomalyCardTitle = ({ anomaly, onClose }: AnomalyCardTitleProps) => {
  return (
    <AnomalyCardTitleWrapper>
      <div
        className="anomaly-title-color"
        style={{
          backgroundColor: anomalyScoreToColor(
            anomaly.vehicleData.score,
            anomaly.detector.warningThreshold ?? undefined,
            anomaly.detector.cautionThreshold ?? anomaly.detector.warningThreshold
          ),
        }}
      ></div>
      <div className="anomaly-title-title">
        <h2 className="heading-x-small">{anomaly.detector.name}</h2>
        <h3 className="heading-xx-small">
          Score{" "}
          {humanFormatNumber(
            isNotNullOrUndefined(anomaly.vehicleData?.score)
              ? anomaly.vehicleData?.score * getAnomalyMultiplier(anomaly.detector)
              : undefined
          )}
        </h3>
      </div>
      {onClose && (
        <div className="anomaly-title-button">
          <CloseOutlined height={46} onClick={() => onClose()} />
        </div>
      )}
    </AnomalyCardTitleWrapper>
  );
};

type SingleVehicleAnomalyDetectorHeadersProps = {
  anomaly: SingleVehicleAnomalyDetectorHeaders;
  onClose?: () => void;
  disabled: boolean;
};

//TODO: DRY w/ AnomalyCardTitle
export const AnomalyCardHeadersTitle = ({ anomaly, onClose, disabled }: SingleVehicleAnomalyDetectorHeadersProps) => {
  return (
    <AnomalyCardTitleWrapper>
      <div
        className="anomaly-title-color"
        style={{
          backgroundColor: disabled ? ANOMALY_UNKNOWN_COLOR : anomalySeverityToColor(anomaly.severity),
        }}
      ></div>
      <div className="anomaly-title-title">
        <h2 className="heading-x-small">{anomaly.name}</h2>
        {disabled ? (
          <Tooltip title="No telemetry data available for this vehicle">
            <WarningOutlined />
          </Tooltip>
        ) : (
          <h3 className="heading-xx-small">
            Score{" "}
            {humanFormatNumber(
              isNotNullOrUndefined(anomaly.score)
                ? anomaly.score * (anomaly.type === AnomalyDetectorType.CustomerProvided ? 1 : 100)
                : undefined
            )}
          </h3>
        )}
      </div>
      {anomalyTypeToIcon(anomaly.type, anomaly.source ?? "")}
      {onClose && (
        <div className="anomaly-title-button">
          <CloseOutlined height={46} onClick={() => onClose()} />
        </div>
      )}
    </AnomalyCardTitleWrapper>
  );
};
