import styled from "styled-components";

export const HeatMapContainer = styled.div`
  position: relative;
  .hdm-heatmap {
    min-height: 750px;
  }
`;

export const HeatmapResetContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: calc(50% - 0.5rem);
  .reset-button {
    .reset-icon {
      color: ${({ theme }) => theme.colors.blueGray};
    }
  }
`;

export const HDMHeatMapAndTitleContainer = styled.div`
  width: 50%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HDMHeatMapTitleContainer = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  height: 100%;
  padding: 0.25rem 0.5rem;
  h4 {
    font-weight: 900;
  }
  span {
    font-size: 12px;
    color: #5f5f5f;
  }
  .selected-table-title-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sub-title {
      margin-right: 0.5rem;
    }
  }
`;
